import * as AccountTypes from 'src/store/account/account.types';

const INITIAL_STATE = {
  loading: false,
  accountId: null,
  results: {},
  error: {}
};

export const accountReducer = (state = INITIAL_STATE, action: any) => {
  let updatedState: any = {};
  switch (action.type) {
    case AccountTypes.OBTAIN_OAUTH_REQUEST_TOKEN:
      return {
        ...state,
        loading: true,
        accountId: null,
        error: null
      };

    case AccountTypes.END_OAUTH_REQUEST_PROCESS:
      return { ...state, loading: false };

    case AccountTypes.ADD_NEW_ACCOUNT:
      return {
        ...state,
        loading: true,
        accountId: null,
        error: null
      };

    case AccountTypes.LOAD_USER_ACCOUNT_START:
      return { ...state, loading: false };

    // case AccountTypes.REMOVE_ACCOUNT:
    //   return {
    //     ...state,
    //     loading: false,
    //     accountId: null,
    //     error: null
    //   };

    case AccountTypes.SET_CURRENT_ACCOUNT:
      return {
        ...state,
        accountId: action.payload
      };

    case AccountTypes.FETCH_USER_ACCOUNT_START:
      return {
        ...state,
        loading: true
      };

    case AccountTypes.FETCH_USER_ACCOUNT_SUCCESS:
      updatedState = {
        ...state,
        loading: false,
        accountId: action.payload[0]?.twitterId,
        results: {}
      };
      action.payload.forEach((value: any) => {
        updatedState.results[value.twitterId] = value;
      });
      return updatedState;

    case AccountTypes.FETCH_USER_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

      case AccountTypes.REMOVE_ACCOUNT_START:
        return {
          ...state,
          loading: true
        };

    case AccountTypes.REMOVE_ACCOUNT_COMPLETE:
      return {
        ...state,
        loading: false
      };

    case AccountTypes.CLEAR_STATE:
      return {
        ...state,
        loading: false,
        accountId: null,
        results: {},
        error: {}
      };

    default:
      return state;
  }
}

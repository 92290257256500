import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import TodayIcon from "@material-ui/icons/Today";
import { format } from "date-fns";
import React from "react";

interface IProps {
  generateWeek: (date: Date) => void;
  generateMonth: (date: Date) => void;
  prevWeek: () => void;
  prevMonth: () => void;
  nextWeek: () => void;
  nextMonth: () => void;
  monthContainer: Date[][];
  weekContainer: Date[];
  weeklyView: boolean;
  setWeeklyView: any;
}
const useStyles = makeStyles((theme: any) =>
  createStyles({
    topSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "10px",
      color: theme.palette.text.primary,
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "10px",
      },
    },
    todayButton: {
      display: "flex",
      alignItems: "center",
      padding: "4px 18px",
      borderRadius: "8px",
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        padding: "2px 4px",
        lineHeight: "1.5",
      },
    },
    buttonContainer: {
      display: "flex",
      gap: "5px",
      alignItems: "center",
    },
    dateRange: {
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
    },
    iconButton: {
      padding: "5px",
    },
    calendarButtonContainer: {
      display: "flex",
      gap: "10px",
    },
    calendarButton: {
      padding: "4px 10px",
      borderRadius: "8px",
    },
  })
);

const ScheduleTopSection = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const {
    generateWeek,
    generateMonth,
    prevWeek,
    prevMonth,
    weekContainer,
    nextWeek,
    nextMonth,
    monthContainer,
    weeklyView,
    setWeeklyView,
  } = props;
  return (
    <div className={classes.topSection}>
      <Button
        variant="outlined"
        color="primary"
        size="medium"
        onClick={() =>
          weeklyView ? generateWeek(new Date()) : generateMonth(new Date())
        }
        className={classes.todayButton}
        startIcon={<TodayIcon fontSize={"small"} />}
      >
        {weeklyView ? "This week" : "This month"}
      </Button>

      <div className={classes.buttonContainer}>
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            weeklyView ? prevWeek() : prevMonth();
          }}
        >
          <NavigateBeforeIcon />
        </IconButton>
        {(weekContainer.length > 0 || monthContainer.length > 0) && (
          <div className={classes.dateRange}>
            {weeklyView
              ? `${format(weekContainer[0], "d MMM")} - ${format(
                  weekContainer[6],
                  "MMM d"
                )}`
              : format(monthContainer[1][6], "MMMM y")}
          </div>
        )}
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            weeklyView ? nextWeek() : nextMonth();
          }}
        >
          <NavigateNextIcon />
        </IconButton>
      </div>
      <div className={classes.calendarButtonContainer}>
        <Button
          variant={weeklyView ? "outlined" : "contained"}
          color="primary"
          onClick={() => setWeeklyView(false)}
          className={classes.calendarButton}
          style={{
            color:
              !weeklyView || theme.palette.type === "dark" ? "#fff" : "#000",
          }}
        >
          Month
        </Button>
        <Button
          variant={!weeklyView ? "outlined" : "contained"}
          style={{
            color:
              weeklyView || theme.palette.type === "dark" ? "#fff" : "#000",
          }}
          color="primary"
          onClick={() => setWeeklyView(true)}
          className={classes.calendarButton}
        >
          Week
        </Button>
      </div>
    </div>
  );
};

export default ScheduleTopSection;

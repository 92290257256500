export default Object.freeze({
  NONE: 'NONE',
  NewThread: 'new-thread',
  Draft: 'draft',
  Schedule: 'schedule',
  History: 'history',
  RSSFeed: 'rss-feed',
  Analytics: 'analytics',
  Upgrade: 'upgrade',
  SignUp: 'signup',
  AddAccount: 'addAccount'
});

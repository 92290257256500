import { RootState } from 'src/store';
import { connect } from 'react-redux';
import { showSnackbarNotification } from 'src/store/notification/notification.actions';
import { addNewAccountStart } from 'src/store/account/account.actions';
import {
  fetchHashtagStart,
  startPostTweet,
  startPostTweetDraft,
  startTweetDraftUpdate,
  startTweetScheduledPost,
  tweetSchedulePostUpdateStart
} from 'src/store/tweet/tweetAction';

const mapStateToProps = (state: RootState) => ({
  authState: state.auth,
  notificationState: state.notification,
  tweetskyState: state.tweetsky,
  rssState: state.rssFeed,
  accountState: state.account
});

const mapDispatchToProps = (dispatch: any) => ({
  onShowNotification: (status: string, message: string) =>
    dispatch(showSnackbarNotification(status, message)),
  onAddNewAccount: ({
    oauthToken,
    oauthVerifier
  }: {
    oauthToken: string;
    oauthVerifier: string;
  }) => dispatch(addNewAccountStart({ oauthToken, oauthVerifier })),
  onStartPostTweetDraft: (
    tweetArray: string[],
    tweetMedia: any[],
    history: any
  ) => dispatch(startPostTweetDraft(tweetArray, tweetMedia, history)),
  onStartPostTweet: (tweetArray: string[], tweetMedia: any[], history: any) =>
    dispatch(startPostTweet(tweetArray, tweetMedia, history)),
  onUpdateDraft: (
    postId: string,
    post: string[],
    tweetMedia: any[],
    history: any
  ) => dispatch(startTweetDraftUpdate(postId, post, tweetMedia, history)),
  onStartSchedulePost: ({
    tweetArray,
    tweetMedia,
    scheduleAt,
    filters,
    history
  }: {
    tweetArray: string[];
    tweetMedia: any[];
    scheduleAt: string;
    filters: string;
    history: any;
  }) =>
    dispatch(
      startTweetScheduledPost({
        tweetArray,
        tweetMedia,
        scheduleAt,
        filters,
        history
      })
    ),
  onUpdateSchedulePost: ({
    post,
    scheduleAt,
    postId,
    tweetMedia,
    history
  }: {
    post: string[];
    scheduleAt: string;
    postId: string;
    tweetMedia: any[];
    history: any;
  }) =>
    dispatch(
      tweetSchedulePostUpdateStart({
        post,
        scheduleAt,
        postId,
        tweetMedia,
        history
      })
    ),
  onFetchHashtagStart: ({ keyword }: { keyword: string }) =>
    dispatch(fetchHashtagStart({ keyword }))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;

import AWS from "aws-sdk";
import axios from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import SectionTab from "src/constant/SectionTab";
import { base_Url, hashtagyApiKey, tweetLambda } from "src/constant/twitterApi";
import store from "../index";
import { showSnackbarNotification } from "../notification/notification.actions";
import { setCurrentTab } from "../siteCoordinator/siteCoordinator.actions";
import * as TweetTypes from "../types/types";
import {
  fetchHashtagFail,
  fetchHashtagSuccess,
  startGetTweetDraftSuccess,
  startTweetScheduleGetSuccess,
  tweetDraftDeleteSuccess,
  tweetDraftPostSuccess,
  tweetDraftUpdateSuccess,
  tweetHistoryError,
  tweetHistoryGetSuccess,
  tweetHistoryPostDeleteSuccess,
  tweetPostDraftError,
  tweetPostError,
  tweetPostSuccess,
  tweetRetweetSuccess,
  tweetScheduledPostSuccess,
  tweetScheduledPostUpdateSuccess,
  tweetScheduleError,
  tweetSchedulePostDeleteSuccess,
  tweetTimelineFetchFail,
  tweetTimelineFetchSucess,
  tweetUnretweetSuccess,
} from "./tweetAction";

if (process.env.REACT_APP_Identity_Pool_Id) {
  AWS.config.region = "us-east-1";
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_Identity_Pool_Id,
  });
}

const formatTweetMedia = (tweetMedia: any) => {
  //map through each media and add mediaObj if empty
  const finalTweetMedia = tweetMedia.map((media: any, index: any) => {
    if (!media) {
      let mediaObj = {
        video: [],
        image: [],
        gif: [],
      };
      media = mediaObj;
    }
    return media;
  });
  return finalTweetMedia;
};

function* handlePostTweet(action: { type: string; payload: any }): any {
  try {
    // let { user } = yield select((state) => state.auth);
    let { accountId } = yield select((state) => state.account);
    const { tweetArray, tweetMedia, history } = action.payload;

    if (accountId) {
      const finalTweetMedia = formatTweetMedia(tweetMedia);
      let lamda = new AWS.Lambda({ region: "us-east-1" });
      let pullParams = {
        FunctionName: tweetLambda,
        Payload: JSON.stringify({
          // userId: user.id,
          accountId,
          status: tweetArray,
          medias: finalTweetMedia,
        }),
      };
      lamda.invoke(pullParams, function (error: any, response: any) {
        const { dispatch } = store;
        if (error) {
          console.log(error);
        } else {
          const result = JSON.parse(response.Payload);
          const responseData = JSON.parse(result.body);

          if (responseData.status && responseData.status === 200) {
            dispatch(tweetPostSuccess(responseData.data));

            dispatch(
              showSnackbarNotification("success", "Tweet posted successfully.")
            );

            history.push("/new-thread/?newThreadAfterQuickPost=true");
            localStorage.setItem("isPosted", "true");
          } else if (
            responseData.message ===
            "You are not allowed to create a Tweet with duplicate content."
          ) {
            dispatch(
              showSnackbarNotification(
                "error",
                "You are not allowed to create a Tweet with duplicate content."
              )
            );
            dispatch(tweetPostError("Something went wrong."));
          } else if (
            responseData.message === "Issue on media upload." ||
            responseData.status === 415
          ) {
            dispatch(showSnackbarNotification("error", "Issue on media."));
            dispatch(tweetPostError("Something went wrong."));
          } else {
            dispatch(showSnackbarNotification("error", "Something we wrong"));
            dispatch(tweetPostError("Something went wrong."));
          }
        }
      });
    } else {
      yield put(
        showSnackbarNotification(
          "error",
          "No twitter account selected or added."
        )
      );
      yield put(tweetPostError("No twitter account selected or added."));
    }
  } catch (error) {
    console.log(error);
    yield put(showSnackbarNotification("error", "Something went wrong."));
    yield put(tweetPostError("Something went wrong."));
  }
}

function* handlePostDraft(action: { type: string; payload: any }): any {
  // let { user } = yield select((state) => state.auth);
  let { accountId } = yield select((state) => state.account);
  let { tweetArray, tweetMedia, history } = action.payload;
  try {
    let media = tweetMedia;

    const finalTweetMedia = formatTweetMedia(media);

    const response = yield axios.post(`${base_Url}set/draft`, {
      post: tweetArray,
      medias: finalTweetMedia,
      userId: accountId,
    });

    if (response.data) {
      let { post, postId, updatedAt, createdAt, id, media_URLs } =
        response.data.data;

      yield put(
        tweetDraftPostSuccess({
          post,
          postId,
          updatedAt,
          createdAt,
          id,
          media_URLs,
        })
      );

      yield put(
        showSnackbarNotification("success", "Tweet draft saved successfully.")
      );

      history.push("/?newThreadAfterQuickPost=true");
      history.push(`/draft/${postId}`);
      localStorage.setItem("isPosted", "true");
    } else {
      yield put(showSnackbarNotification("error", "Something went wrong."));
      yield put(tweetPostDraftError());
    }
  } catch (error) {
    console.log(error);
    yield put(tweetPostDraftError());
    yield put(showSnackbarNotification("error", "Something went wrong."));
  }
}

function* handleFetchDrafts(action: { type: string; payload: any }): any {
  try {
    const { data, status, message } = yield axios.get(
      `${base_Url}list/draft/${action.payload}`
    );

    if (status && status === 200) {
      yield put(startGetTweetDraftSuccess(data.data));
    } else {
      yield put(showSnackbarNotification("error", message));
    }
  } catch (error) {
    console.log(error);
    yield put(showSnackbarNotification("error", "Something went wrong."));
  }
}

function* handleUpdateDrafts(action: { type: string; payload: any }): any {
  try {
    // let { user } = yield select((state) => state.auth);
    let { accountId } = yield select((state) => state.account);
    let { postId, post, tweetMedia, history } = action.payload;

    const { data, status, message } = yield axios.post(
      `${base_Url}update/draft`,
      {
        userId: accountId,
        postId,
        post,
        medias: tweetMedia,
      }
    );

    if (status && status === 200) {
      yield put(
        tweetDraftUpdateSuccess({
          post: data.data.post,
          updatedAt: data.data.updatedAt,
          postId,
        })
      );
      yield put(showSnackbarNotification("success", "Draft updated."));
      history.push("/?newThreadAfterQuickPost=true");
      history.push(`/draft/${postId}`);
      localStorage.setItem("isPosted", "true");
    } else {
      yield put(tweetPostDraftError());
      yield put(showSnackbarNotification("error", message));
    }
  } catch (error) {
    console.log(error);
    yield put(tweetPostDraftError());
    yield put(showSnackbarNotification("error", "Something went wrong."));
  }
}

function* handleDeleteDrafts(action: { type: string; payload: any }) {
  try {
    // let { user } = yield select((state) => state.auth);
    let { accountId } = yield select((state) => state.account);
    let { postId } = action.payload;

    const { status } = yield axios.post(`${base_Url}delete/draft`, {
      userId: accountId,
      postId,
    });
    if (status && status === 200) {
      yield put(tweetDraftDeleteSuccess({ postId, userId: accountId }));
    } else {
      yield put(tweetPostDraftError());
      yield put(showSnackbarNotification("error", "Something went wrong."));
    }
  } catch (error) {
    console.log(error);
    yield put(tweetPostDraftError());
    yield put(showSnackbarNotification("error", "Something went wrong."));
  }
}

function* handleScheduledPost(action: { type: string; payload: any }) {
  try {
    // let { user } = yield select((state) => state.auth);
    let { accountId } = yield select((state) => state.account);
    let { tweetArray, scheduleAt, history, tweetMedia, filters } =
      action.payload;
    const finalTweetMedia = formatTweetMedia(tweetMedia);
    const { data } = yield axios.post(`${base_Url}set/schedule`, {
      userId: accountId,
      post: tweetArray,
      scheduleAt,
      medias: finalTweetMedia,
      filters,
    });
    if (data.status && data.status === 200) {
      yield put(tweetScheduledPostSuccess(data.data));
      yield put(
        showSnackbarNotification("success", "Tweet scheduled successfully.")
      );

      yield put(setCurrentTab(SectionTab.Schedule));

      history.push("/?newThreadAfterQuickPost=true");
      localStorage.setItem("isPosted", "true");
      history.push("/Schedule");
    } else {
      yield put(tweetScheduleError());
      yield put(showSnackbarNotification("error", "Something went wrong."));
    }
  } catch (error) {
    console.log(error);
    yield put(tweetScheduleError());
    yield put(showSnackbarNotification("error", "Something went wrong."));
  }
}

function* handleGetScheduledPost(action: { type: string; payload: any }) {
  try {
    // let { user } = yield select((state) => state.auth);
    let { accountId } = yield select((state) => state.account);
    const { data, status, message } = yield axios.get(
      `${base_Url}list/schedule/${accountId}`
    );

    if (status && status === 200) {
      yield put(startTweetScheduleGetSuccess(data.data));
    } else {
      yield put(showSnackbarNotification("error", message));
    }
  } catch (error) {
    console.log(error);
    yield put(showSnackbarNotification("error", "Something went wrong."));
  }
}

function* handleUpdateSchedulePost(action: { type: string; payload: any }) {
  try {
    // let { user } = yield select((state) => state.auth);
    let { accountId } = yield select((state) => state.account);
    let { postId, post, scheduleAt, tweetMedia, history } = action.payload;
    const { data } = yield axios.post(`${base_Url}update/schedule`, {
      userId: accountId,
      postId,
      post,
      scheduleAt,
      medias: tweetMedia,
    });

    if (data.status && data.status === 200) {
      yield put(tweetScheduledPostUpdateSuccess(data.data));
      yield put(
        showSnackbarNotification("success", "Tweet updated successfully.")
      );
      history.push("/?newThreadAfterQuickPost=true");
      localStorage.setItem("isPosted", "true");
      history.push("/Schedule");
    } else {
      yield put(tweetScheduleError());
      yield put(showSnackbarNotification("error", data.message));
    }
  } catch (error) {
    console.log(error);
    yield put(tweetScheduleError());
    yield put(showSnackbarNotification("error", "Something went wrong."));
  }
}

function* handleDeleteScheduledHistoryPost(action: {
  type: string;
  payload: any;
}) {
  try {
    // let { user } = yield select((state) => state.auth);
    let { accountId } = yield select((state) => state.account);
    const { user } = yield select((state) => state.auth);
    let { sk, history } = action.payload;
    const { data } = yield axios.post(`${base_Url}remove/post`, {
      accountId: accountId,
      sk,
      userId: user?.id,
    });
    if (data.status === 200) {
      if (sk.split("#")[1].toLowerCase() === "schedule") {
        yield put(tweetSchedulePostDeleteSuccess(sk.split("#")[2]));
        if (history && history.location.pathname.includes("thread")) {
          history.push("/Schedule");
        }
      } else {
        yield put(tweetHistoryPostDeleteSuccess(sk.split("#")[2]));
        if (history && history.location.pathname.includes("thread")) {
          history.push("/History");
        }
      }
      yield put(showSnackbarNotification("success", "Tweet deleted."));
    } else {
      yield put(tweetScheduleError());
      yield put(tweetHistoryError());
    }
  } catch (error) {
    console.log(error);
    yield put(tweetScheduleError());
    yield put(tweetHistoryError());
    yield put(showSnackbarNotification("error", "Something went wrong."));
  }
}

function* handleGetTweetHistory(action: { type: string; payload: any }) {
  try {
    const { data, status, message } = yield axios.get(
      `${base_Url}tweet/history/${action.payload}`
    );

    if (status && status === 200) {
      yield put(tweetHistoryGetSuccess(data.data));
    } else {
      yield put(showSnackbarNotification("error", message));
    }
  } catch (error) {
    console.log(error);
    yield put(showSnackbarNotification("error", "Something went wrong."));
  }
}

function* reTweet(action: { type: string; payload: any }) {
  try {
    const { sk } = action.payload;
    // let { user } = yield select((state) => state.auth);
    let { accountId } = yield select((state) => state.account);
    const { user } = yield select((state) => state.auth);
    const { data, status, message } = yield axios.post(
      `${base_Url}posts/retweet`,
      { sk, accountId: accountId, userId: user?.id }
    );

    if (status && status === 200) {
      yield put(tweetRetweetSuccess(data.data));
      yield put(showSnackbarNotification("success", "Tweet retweeted."));
    } else {
      yield put(showSnackbarNotification("error", message));
    }
  } catch (error) {
    console.log(error);
    yield put(showSnackbarNotification("error", "Something went wrong."));
    yield put(tweetHistoryError());
  }
}

function* unReTweet(action: { type: string; payload: any }) {
  try {
    const { sk } = action.payload;
    // let { user } = yield select((state) => state.auth);
    let { accountId } = yield select((state) => state.account);
    const { user } = yield select((state) => state.auth);
    const { data, status, message } = yield axios.post(
      `${base_Url}posts/unretweet`,
      { sk, accountId: accountId, userId: user?.id }
    );

    if (status && status === 200) {
      yield put(tweetUnretweetSuccess(data.data));
      yield put(showSnackbarNotification("success", "Tweet untweeted."));
    } else {
      yield put(showSnackbarNotification("error", message));
    }
  } catch (error) {
    console.log(error);
    yield put(showSnackbarNotification("error", "Something went wrong."));
    yield put(tweetHistoryError());
  }
}

function* fetchTweetTimeline(action: { type: string; payload: any }) {
  try {
    const { userId, paginationNextToken } = action.payload;

    const { data, status } = yield axios.post(`${base_Url}list/timeline`, {
      userId: userId,
      paginationNextToken: paginationNextToken ? paginationNextToken : null,
    });
    if (data && status && status === 200) {
      yield put(
        tweetTimelineFetchSucess({
          tweetList: data.data || [],
          paginationNextToken: data?.meta?.next_token || null,
        })
      );
    } else {
      yield put(tweetTimelineFetchFail());
    }
  } catch (error) {
    console.log(error);
    yield put(showSnackbarNotification("error", "Something went wrong."));
    yield put(tweetTimelineFetchFail());
  }
}

function* fetchHashtagAsync(action: { type: string; payload: any }) {
  try {
    const { keyword } = action.payload;
    let options: any = {
      method: "GET",
      url: "https://hashtagy-generate-hashtags.p.rapidapi.com/v1/custom_1/tags",
      params: { keyword: keyword },
      headers: {
        "x-rapidapi-host": "hashtagy-generate-hashtags.p.rapidapi.com",
        "x-rapidapi-key": `${hashtagyApiKey}`,
      },
    };
    const { data, status } = yield axios.request(options);
    console.log(data, status);
    if (status === 200) {
      //give random id to each hashtag and send to state
      yield put(
        fetchHashtagSuccess(
          data.data.hashtags.map((item: any) => {
            return {
              ...item,
              id: Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, ""),
            };
          })
        )
      );
    }
  } catch (error) {
    console.log("error:", error);
    yield put(showSnackbarNotification("error", "Something went wrong."));
    yield put(fetchHashtagFail(error));
  }
}

export function* postTweet() {
  yield takeLatest(TweetTypes.START_TWEET_POST_PROCESS, handlePostTweet);
}
export function* postDraft() {
  yield takeLatest(TweetTypes.TWEET_START_SAVE_AS_DRAFT, handlePostDraft);
}
export function* fetchDraft() {
  yield takeLatest(TweetTypes.TWEET_START_DRAFT_GET, handleFetchDrafts);
}
export function* updateDraft() {
  yield takeLatest(TweetTypes.TWEET_DRAFT_UPDATE_START, handleUpdateDrafts);
}
export function* deleteDraft() {
  yield takeLatest(TweetTypes.TWEET_DRAFT_DELETE_START, handleDeleteDrafts);
}
export function* schedulePost() {
  yield takeLatest(TweetTypes.TWEET_SCHEDULE_START, handleScheduledPost);
}
export function* fetchSchedule() {
  yield takeLatest(TweetTypes.TWEET_SCHEDULE_GET_START, handleGetScheduledPost);
}
export function* updateSchedulePost() {
  yield takeLatest(
    TweetTypes.TWEET_SCHEDULE_UPDATE_START,
    handleUpdateSchedulePost
  );
}
export function* deleteSchedulePost() {
  yield takeLatest(
    TweetTypes.TWEET_SCHEDULE_DELETE_START,
    handleDeleteScheduledHistoryPost
  );
}
export function* deleteHistoryPost() {
  yield takeLatest(
    TweetTypes.TWEET_HISTORY_DELETE_START,
    handleDeleteScheduledHistoryPost
  );
}
export function* getTweetHistory() {
  yield takeLatest(TweetTypes.TWEET_HISTORY_GET_START, handleGetTweetHistory);
}

export function* watchRetweet() {
  yield takeLatest(TweetTypes.TWEET_RETWEET_START, reTweet);
}

export function* watchUnRetweet() {
  yield takeLatest(TweetTypes.TWEET_UNRETWEET_START, unReTweet);
}

export function* watchGetTweetTimeline() {
  yield takeLatest(TweetTypes.FETCH_TWEET_TIMELINE_START, fetchTweetTimeline);
}
export function* watchGetHashtagStart() {
  yield takeLatest(TweetTypes.FETCH_HASHATG_START, fetchHashtagAsync);
}
export function* tweetSagas() {
  yield all([
    call(postTweet),
    call(postDraft),
    call(fetchDraft),
    call(updateDraft),
    call(deleteDraft),
    call(schedulePost),
    call(fetchSchedule),
    call(updateSchedulePost),
    call(deleteSchedulePost),
    call(getTweetHistory),
    call(deleteHistoryPost),
    call(watchRetweet),
    call(watchUnRetweet),
    call(watchGetTweetTimeline),
    call(watchGetHashtagStart),
  ]);
}

import React, { Dispatch } from 'react';
import { useHistory } from 'react-router';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import Sidebar from '../../layout/components/Sidebar';
import Topbar from '../../layout/components/Topbar';
import LogoutModal from '../components/Topbar/components/LogoutModal';
import { logoutStart } from 'src/store/auth/authAction';
import container from './Main.container';


const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    height: '100vh',
    overflow: 'auto'
  },
  toolbar: theme.mixins.toolbar,
  content: {
    minHeight: '100%'
  },
  pages: {
    flexGrow: 1,
    padding: '3px 10px 0 10px',

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: (sidebar) => {
        if (sidebar) {
          return '200px';
        } else {
          return '50px';
        }
      }
    }
  }
}));

interface Props {
  children: React.FC;
  open: boolean;
  authState: any;
  siteCoordinator: any;
  onToggleSidebar: (value: boolean) => void;
  onToggleDarKMode: (value: boolean) => void;
  onSetLogoutModal: (value: boolean) => void;
}

export const MainComponent: React.FC<Props> = ({
  children,
  authState: { isAuthenticated },
  siteCoordinator: { sidebar, darkMode, logoutModal },
  onToggleSidebar,
  onToggleDarKMode,
  onSetLogoutModal
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(sidebar);

  const onClickToggleSidebar = (value: boolean) => {
    onToggleSidebar(value);
    // dispatch(toggleSidebar(value));
  };

  const onClickToggleDarKMode = (value: boolean) => {
    onToggleDarKMode(value);
    // dispatch(toggleDarKMode(value));
  };

  const handleLoginLogout = () => {
    if (isAuthenticated) {
      onSetLogoutModal(true)
    } else {
      history.push('/login');
      // dispatch(obtainOauthRequestTokenAction());
    }
  };

  const logUserOut = () => {
    dispatch((logoutStart(history))); // logout cognito user and data clear on state
    // dispatch(removeAccount(history)); // remove twitter account from storage
    // dispatch(resetState()); // reset tweetState for draft etc on logout
    onSetLogoutModal(false);
  };

  React.useEffect(() => {
    onToggleSidebar(!matchesSM);
    // dispatch(toggleSidebar(!matchesSM));
  }, [onToggleSidebar, matchesSM]);

  return (
    <div className={classes.root}>
      <div className={classes.toolbar} />
      <Topbar
        layout="main"
        darkMode={darkMode}
        onToggleDarkMode={onClickToggleDarKMode}
        sidebar={sidebar}
        onToggleSidebar={onClickToggleSidebar}
        handleLoginLogout={handleLoginLogout}
      />
      <Sidebar
        sidebar={sidebar}
        onToggleSidebar={onClickToggleSidebar}
        handleLoginLogout={handleLoginLogout}
      />
      <LogoutModal
        openPopUp={logoutModal}
        setOpenPopUp={onSetLogoutModal}
        logUserOut={logUserOut}
      />
      <div className={classes.pages}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default container(MainComponent);

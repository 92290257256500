import { all, call } from 'redux-saga/effects';
import { authSagas } from './auth/authSaga';
import { accountSaga } from 'src/store/account/account.saga';
import { subscriptionSagas } from './subscription/subscription.sagas';
import { tweetSagas } from './tweet/tweetSaga';
import { siteCoordinatorSagas } from './siteCoordinator/siteCoordinator.sagas';
import { tweetAnalyticsSaga } from './tweetAnalytics/tweetAnalyticSaga';
import { rssFeedSagas } from './rssFeed/rssFeed.sagas';
import { vendorSaga } from 'src/store/vendor/vendor.saga';

export default function* rootSaga() {
  yield all([
    call(authSagas),
    call(accountSaga),
    call(subscriptionSagas),
    call(tweetSagas),
    call(siteCoordinatorSagas),
    call(tweetAnalyticsSaga),
    call(rssFeedSagas),
    call(vendorSaga)
  ]);
}

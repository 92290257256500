export const OBTAIN_OAUTH_REQUEST_TOKEN = 'OBTAIN_OAUTH_REQUEST_TOKEN';

export const END_OAUTH_REQUEST_PROCESS = 'END_OAUTH_REQUEST_PROCESS';

export const ADD_NEW_ACCOUNT = 'ADD_NEW_ACCOUNT';

export const LOAD_USER_ACCOUNT_START = 'LOAD_USER_ACCOUNT_START';

export const SET_NEW_ACCOUNT = 'SET_NEW_ACCOUNT';

export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';

export const FETCH_USER_ACCOUNT_START = 'FETCH_USER_ACCOUNT_START';
export const FETCH_USER_ACCOUNT_SUCCESS = 'FETCH_USER_ACCOUNT_SUCCESS';
export const FETCH_USER_ACCOUNT_FAIL = 'FETCH_USER_ACCOUNT_FAIL';

export const REMOVE_ACCOUNT_START = 'REMOVE_ACCOUNT_START';
export const REMOVE_ACCOUNT_COMPLETE = 'REMOVE_ACCOUNT_COMPLETE';

export const CLEAR_STATE = 'CLEAR_STATE';


import React from "react";
import { Button, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        maxWidth: 500,
        margin: 'auto',
        textAlign: 'center'
    },
    button: {
        marginTop: 50,
        marginBottom: 20
    }
}));

export const PageNotFound = () => {
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Typography component="h1" variant="h1">
                400
            </Typography>
            <Typography component="h3" variant="h3">
                PAGE NOT FOUND
            </Typography>
            <Typography variant="body1">
                The page you are looking for might have been removed had its name
            </Typography>
            <Typography variant="body1">
                changed or its temporarily unavailable
            </Typography>

            <Button
                variant="outlined"
                className={classes.button}
                component={Link}
                to="/"
            >
                Homepage
            </Button>
        </Paper>
    );
}

export default PageNotFound;

import { RootState } from 'src/store';
import { connect } from 'react-redux';
import { loginStart } from 'src/store/auth/authAction';
import { showSnackbarNotification } from 'src/store/notification/notification.actions';

const mapStateToProps = (state: RootState) => ({
    authState: state.auth,
    notificationState: state.notification,
});

const mapDispatchToProps = (dispatch: any) => ({
    onLoginStart: (values: any, history: any) => dispatch(loginStart(values, history)),
    onShowNotification: (status: string, message: string) =>
        dispatch(showSnackbarNotification(status, message)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;

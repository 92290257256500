import axios from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as RSSType from './rssFeed.types';
import * as RSSAction from './rssFeed.actions';
import { rss_base_url } from 'src/constant/twitterApi';

function* fetchRSSStartAsync(action: { type: string; payload: any }) {
  try {
    let { user } = yield select((state) => state.auth);
    const { data } = yield axios.post(
      `${rss_base_url}/search/rss`,
      {
        userid: user.id,
        rss: action.payload.url
      }
    );
    if (data.statusCode === 200) {
      yield put(RSSAction.fetchRSSSuccess(action.payload.url, data.data));
      yield put(RSSAction.fetchRSSHistoryStart());
    }
  } catch (error) {
    yield put(RSSAction.fetchRSSFail(error));
  }
}

function* fetchRSSHistoryStartAsync(action: { type: string; payload: any }) {
  try {
    let { user } = yield select((state) => state.auth);
    if (user) {
      const { data } = yield axios.get(
        `${rss_base_url}/rss/history/${user.id}`,
      );
      if (data.statusCode === 200) {
        yield put(RSSAction.fetchRSSHistorySuccess(data.data));
      }
    }
  } catch (error) {
    yield put(RSSAction.fetchRSSHistoryFail(error));
  }
}

export function* fetchRSSStartWatcher() {
  yield takeLatest(RSSType.FETCH_RSS_START, fetchRSSStartAsync);
}

export function* fetchRSSHistoryStartWatcher() {
  yield takeLatest(RSSType.FETCH_RSS_HISTORY_START, fetchRSSHistoryStartAsync);
}

export function* rssFeedSagas() {
  yield all([
    call(fetchRSSStartWatcher),
    call(fetchRSSHistoryStartWatcher)
  ]);
}

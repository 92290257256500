import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import GifOutlinedIcon from "@material-ui/icons/GifOutlined";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useRef } from "react";
import { IUser } from "src/interfaces";
import { ITweetMedia } from "../Home.component";
import { parseTwitterText } from "../../../components/twitter-text-utils";
import AvatarComponent from "src/components/AvatarComponent";
import TitleComponent from "src/components/TitleComponent";

interface IProps {
  username: string;
  avatar: string;
  image: string | null;
  tweetMedia: ITweetMedia[];
  tweetArray: string[];
  handleMediaUpload: (
    tweetIndex: number,
    event: React.ChangeEvent<HTMLInputElement>,
    popupState?: any
  ) => void;
  deleteImage: (
    tweetIndex: number,
    imageIndex: number,
    tweetMedia: any
  ) => void;
  deleteVideo: (tweetIndex: number, tweetMedia: any) => void;
  deleteGif: (tweetIndex: number, tweetMedia: any) => void;
  user: IUser;
  setGifModal: (tweetIndex?: number) => void;
  changeQuoteModal: () => void;
  quoteId: string | null;
  setOpenBackdrop: (text: string | null) => void;
}

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "none",
  },
  textarea: {
    padding: "0 20px",
    height: "calc(100vh - 228px)",
  },
  button: {
    marginRight: 5,
    textTransform: "capitalize",
  },
  buttonActive: {
    marginRight: 5,
    textTransform: "capitalize",
    color: theme.palette.secondary.main,
  },
  iconButton: {
    marginRight: 5,
  },
  textareaNavBottomBtn: {
    flexGrow: 1,
  },
  tweetContainer: {
    flex: "30%",
    padding: "0",
    marginTop: 0,
    borderRadius: 8,
    maxHeight: "calc(100vh - 98px)",
    overflowY: "auto",
    overflowX: "hidden",
    boxShadow: "0px 1px 3px rgb(3 0 71 / 9%)",
    [theme.breakpoints.down("sm")]: {
      flex: "none",
      width: "100%",
    },
  },
  tweet: {},
  tweetContent: {
    paddingLeft: theme.spacing(11),
    paddingTop: "0px",
    paddingBottom: "11px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(3),
    },
  },
  firstTweetCardHeader: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  userName: {
    paddingBottom: "6px",
  },
  tweetImage: {
    objectFit: "cover",
    height: "100% !important",
    "& div ": {
      borderRadius: "8px",
      "& img": {
        borderRadius: "8px",
        width: "100%",
      },
    },
  },
  tweetCardHeader: {
    paddingTop: "0px",
    paddingBottom: "8px",
  },

  counter: {
    paddingTop: "10px",
  },
}));

const TweetContainer: React.FC<IProps> = ({
  username,
  avatar,
  image,
  tweetArray,
  tweetMedia,
  handleMediaUpload,
  deleteImage,
  deleteVideo,
  deleteGif,
  user,
  setGifModal,
  changeQuoteModal,
  quoteId,
  setOpenBackdrop,
}) => {
  const uploadRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  return (
    <>
      <Box className={classes.tweetContainer}>
        {tweetArray && tweetArray.length > 0 ? (
          tweetArray.map((tweet, tweetIndex) => (
            <Card elevation={0} color="inherit" key={tweetIndex}>
              <CardHeader
                className={
                  tweetIndex === 0
                    ? classes.firstTweetCardHeader
                    : classes.tweetCardHeader
                }
                avatar={<ImageComponent avatar={avatar} image={image} />}
                title={<TitleComponent username={username} />}
                action={
                  <PopupState variant="popover" popupId="popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <IconButton
                          color="primary"
                          {...bindTrigger(popupState)}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>

                        <Menu {...bindMenu(popupState)}>
                          <input
                            ref={uploadRef}
                            type="file"
                            className={classes.hidden}
                            onChange={(e) => {
                              handleMediaUpload(tweetIndex, e, popupState);
                            }}
                          />
                          <MenuItem
                            onClick={() => {
                              uploadRef.current && uploadRef.current.click();
                            }}
                          >
                            <ListItemIcon style={{ minWidth: "30px" }}>
                              <InsertPhotoIcon fontSize={"small"} />
                            </ListItemIcon>
                            <Typography component="h6">
                              {" "}
                              Upload Media
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setGifModal(tweetIndex);
                              popupState.close();
                            }}
                          >
                            <ListItemIcon style={{ minWidth: "30px" }}>
                              <GifOutlinedIcon />
                            </ListItemIcon>
                            <Typography component="h6">Add GIF</Typography>
                          </MenuItem>
                          {/* <MenuItem
                            onClick={() => {
                              changeQuoteModal();
                              popupState.close();
                            }}
                          >
                            Quote tweet
                          </MenuItem> */}
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                }
              />
              <CardContent className={classes.tweetContent}>
                <Typography
                  variant="body2"
                  component="p"
                  style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                >
                  {tweet && tweet}
                </Typography>
                <Typography
                  className={classes.counter}
                  variant="body2"
                  component="p"
                  color="textSecondary"
                >
                  {`${
                    tweet.length && parseTwitterText(tweet).weightedLength
                  }/280`}
                </Typography>
                {/* display tweet video */}
                {tweetMedia[tweetIndex] &&
                  tweetMedia[tweetIndex].video &&
                  tweetMedia[tweetIndex].video[0] && (
                    <Card>
                      <CardActions>
                        <IconButton
                          style={{
                            margin: "auto 0 auto auto",
                          }}
                          onClick={async () => {
                            setOpenBackdrop("Removing.Please wait.");
                            await deleteVideo(tweetIndex, tweetMedia);
                            setOpenBackdrop(null);
                          }}
                        >
                          <CancelIcon color={"primary"} />
                        </IconButton>
                      </CardActions>
                      <CardActionArea>
                        {tweetMedia[tweetIndex] &&
                          tweetMedia[tweetIndex].video &&
                          tweetMedia[tweetIndex].video.length !== 0 && (
                            <>
                              <CardMedia
                                controls
                                component="video"
                                height="auto"
                                src={tweetMedia[tweetIndex].video[0].url}
                              />
                            </>
                          )}{" "}
                      </CardActionArea>
                    </Card>
                  )}

                {/* display image store in same index as tweet  */}
                {tweetMedia[tweetIndex] &&
                  tweetMedia[tweetIndex].image &&
                  tweetMedia[tweetIndex].image.length !== 0 && (
                    <ImageList rowHeight={130} gap={1}>
                      {tweetMedia[tweetIndex].image.map(
                        (image: any, imageIndex: any) => (
                          <ImageListItem
                            cols={
                              tweetMedia[tweetIndex].image.length === 1 ? 2 : 1
                            }
                            rows={1}
                            key={imageIndex}
                            className={classes.tweetImage}
                            // style={{
                            //   aspectRatio:
                            //     tweetMedia[tweetIndex].image.length === 2
                            //       ? "7/8"
                            //       : tweetMedia[tweetIndex].image.length === 4
                            //       ? "2/1"
                            //       : tweetMedia[tweetIndex].image.length === 3
                            //       ? "4/7"
                            //       : "16/9",
                            // }}
                          >
                            <img src={image.url} alt="" />
                            <ImageListItemBar
                              position="top"
                              style={{ background: "none" }}
                              actionIcon={
                                <IconButton
                                  onClick={async () => {
                                    setOpenBackdrop("Removing.Please wait.");
                                    await deleteImage(
                                      tweetIndex,
                                      imageIndex,
                                      tweetMedia
                                    );
                                    setOpenBackdrop(null);
                                  }}
                                >
                                  <CancelIcon color="primary" />
                                </IconButton>
                              }
                              actionPosition="right"
                            />
                          </ImageListItem>
                        )
                      )}
                    </ImageList>
                  )}
                {tweetMedia[tweetIndex] &&
                  tweetMedia[tweetIndex].gif &&
                  tweetMedia[tweetIndex].gif.length !== 0 &&
                  tweetMedia[tweetIndex].gif.map((gif: any, gifIndex: any) => (
                    <ImageList rowHeight={"auto"}>
                      <ImageListItem cols={2} rows={2}>
                        <img
                          src={gif.url}
                          style={{
                            width: "100%",
                            borderRadius: "6px",
                            minHeight: "100px",
                          }}
                          alt=""
                        />
                        <ImageListItemBar
                          position="top"
                          style={{ background: "none" }}
                          actionIcon={
                            <IconButton
                              onClick={() => deleteGif(tweetIndex, tweetMedia)}
                            >
                              <CancelIcon color="primary" />
                            </IconButton>
                          }
                          actionPosition="right"
                        />
                      </ImageListItem>
                    </ImageList>
                  ))}

                {/* {!quoteId && (
                  <TweetEmbed
                    id={`${quoteId}`}
                    options={{
                      theme: !darkMode ? "light" : "dark",
                      cards: "hidden",
                      conversation: "none",
                    }}
                  />
                )} */}
              </CardContent>
            </Card>
          ))
        ) : (
          <Card color="inherit">
            <CardHeader
              className={classes.firstTweetCardHeader}
              avatar={<ImageComponent avatar={avatar} image={image} />}
              title={<TitleComponent username={username} />}
            />
            <CardContent className={classes.tweetContent}>
              <Typography
                variant="body2"
                component="p"
                style={{ whiteSpace: "pre-wrap" }}
                color="textSecondary"
              >
                Type something to see your tweet.
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
    </>
  );
};

export default TweetContainer;

type ImageComponentProps = {
  image: string | null;
  avatar: string;
};
const ImageComponent = ({ image, avatar }: ImageComponentProps) => {
  return image ? (
    <img
      src={image}
      style={{
        borderRadius: "50%",
        height: "30px",
        width: "30px",
        overflow: "hidden",
      }}
    />
  ) : (
    <AvatarComponent avatar={avatar} />
  );
};

import * as AccountTypes from 'src/store/account/account.types';

export const obtainOauthRequestTokenAction = () => ({
  type: AccountTypes.OBTAIN_OAUTH_REQUEST_TOKEN
});

export const endLoginProcess = () => ({
  type: AccountTypes.END_OAUTH_REQUEST_PROCESS
});

export const addNewAccountStart = ({ oauthToken, oauthVerifier }: {
  oauthToken: string;
  oauthVerifier: string;
}) => ({
  type: AccountTypes.ADD_NEW_ACCOUNT,
  payload: { oauthToken, oauthVerifier }
});

// export const loadUserAccountStart = () => ({
//   type: AccountTypes.LOAD_USER_ACCOUNT_START,
// });

// export const setNewAccount = (data: any) => ({
//   type: AccountTypes.SET_NEW_ACCOUNT,
//   payload: { data }
// });

// export const removeAccount = (history: any) => ({
//   type: AccountTypes.REMOVE_ACCOUNT,
//   payload: history
// });

export const setCurrentAccount = (userId: string) => ({
  type: AccountTypes.SET_CURRENT_ACCOUNT,
  payload: userId
});

export const fetchUserAccountStart = (userId: string) => ({
  type: AccountTypes.FETCH_USER_ACCOUNT_START,
  payload: { userId }
});

export const fetchUserAccountSuccess = (data: any) => ({
  type: AccountTypes.FETCH_USER_ACCOUNT_SUCCESS,
  payload: data
});

export const fetchUserAccountFail = (error: any) => ({
  type: AccountTypes.FETCH_USER_ACCOUNT_FAIL,
  payload: error
});

export const removeTweetAccount = (accountId: string, value: boolean) => ({
  type: AccountTypes.REMOVE_ACCOUNT_START,
  payload: { accountId, value }
});

export const removeTweetAccountComplete = () => ({
  type: AccountTypes.REMOVE_ACCOUNT_COMPLETE
});

export const clearAccountState = () => ({
  type: AccountTypes.CLEAR_STATE
});

import { useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Dispatch } from 'react';
import Topbar from '../../layout/components/Topbar';
import container from './Minimal.container';
import { logoutStart } from '../../store/auth/authAction';
import LogoutModal from '../components/Topbar/components/LogoutModal';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff'
  },
  content: {
    minHeight: '100%',
    overflow: 'hidden',
    maxWidth: '100vw'
  },
  pages: {
    flexGrow: 1,
    marginTop: '68.5px'
  }
}));

interface Props {
  children: React.FC;
  open: boolean;
  authState: any;
  siteCoordinator: any;
  onToggleSidebar: (value: boolean) => void;
  onToggleDarKMode: (value: boolean) => void;
  onSetLogoutModal: (value: boolean) => void;
}

export const Minimal: React.FC<Props> = ({
  children,
  authState: { isAuthenticated },
  siteCoordinator: { sidebar, darkMode, logoutModal },
  onToggleSidebar,
  onToggleDarKMode,
  onSetLogoutModal
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(sidebar);

  const onClickToggleSidebar = (value: boolean) => {
    onToggleSidebar(value);
    // dispatch(toggleSidebar(value));
  };

  const onClickToggleDarKMode = (value: boolean) => {
    onToggleDarKMode(value);
    // dispatch(toggleDarKMode(value));
  };

  const handleLoginLogout = () => {
    if (isAuthenticated) {
      onSetLogoutModal(true);
    } else {
      history.push('/login');
      // dispatch(obtainOauthRequestTokenAction());
    }
  };

  const logUserOut = () => {
    dispatch(logoutStart(history)); // logout cognito user and data clear on state
    // dispatch(removeAccount(history)); // remove twitter account from storage
    // dispatch(resetState()); // reset tweetState for draft etc on logout
    onSetLogoutModal(false);
  };

  React.useEffect(() => {
    onToggleSidebar(!matchesSM);
    // dispatch(toggleSidebar(!matchesSM));
  }, [onToggleSidebar, matchesSM]);

  return (
    <div className={classes.root}>
      <Topbar
        layout="minimal"
        sidebar={false}
        darkMode={darkMode}
        onToggleDarkMode={onClickToggleDarKMode}
        onToggleSidebar={onClickToggleSidebar}
        handleLoginLogout={handleLoginLogout}
      />
      <LogoutModal
        openPopUp={logoutModal}
        setOpenPopUp={onSetLogoutModal}
        logUserOut={logUserOut}
      />
      <div className={classes.pages}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default container(Minimal);

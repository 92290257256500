import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    background: "#E3F2FF",
    // marginTop: 90,
    padding: "40px 0",
    "& > *": {
      textAlign: "center",
    },
  },
  footerHeader: {
    fontSize: "16px",
    lineHeight: "21.79px",
    fontWeight: 500,
    textDecoration: "none",
    color: "gray",
  },
  footerSubtitle: {
    fontSize: "14px",
    lineHeight: "19.07px",
  },
  line: {
    margin: "30px 0",
    background: "#999999",
  },
}));

export const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.footerWrapper}>
        <Container maxWidth={"lg"}>
          <hr className={classes.line} />
          <Grid justifyContent="flex-start" container spacing={3}>
            <Grid item>
              <Link to="/terms-and-conditions" className={classes.footerHeader}>
                <Typography component={"h4"}>Terms Of Service</Typography>
              </Link>
            </Grid>
            <Grid item>
              <Link to="/privacy-policy" className={classes.footerHeader}>
                <Typography component={"h4"}>Privacy Policy</Typography>
              </Link>
            </Grid>
          </Grid>
          <Typography className={classes.footerSubtitle} component={"h4"}>
            2022 Tweetsky.com. all rights reserved.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Footer;

import { createStyles, makeStyles } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import {
  DatePicker as MaterialDatePicker,
  TimePicker as MaterialTimePicker
} from '@material-ui/pickers';
import * as React from 'react';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardBody: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 400,
      minWidth: 200,
      backgroundColor: theme.palette.type === 'light' ? '#f3f7fa' : '#424242',
      borderRadius: 5,
      padding: '25px 25px 18px 25px'
    },
    header: {
      fontWeight: 500,
      marginBottom: 10
    },
    dateTimePickerContainer: {
      display: 'flex',
      gap: 10,
      '& div': {
        color:
          theme.palette.type === 'dark' ? '#fff' : theme.palette.text.primary,
        '& input': {
          color:
            theme.palette.type === 'dark' ? '#fff' : theme.palette.text.primary
        },
        '& select': {
          color:
            theme.palette.type === 'dark' ? '#FFF' : theme.palette.text.primary,
          background: theme.palette.type === 'light' ? '#f3f7fa' : '#424242'
        },
        '& button': {
          '& svg': {
            '& line': {
              stroke:
                theme.palette.type === 'dark'
                  ? '#fff'
                  : theme.palette.text.primary
            },
            '& rect': {
              stroke:
                theme.palette.type === 'dark'
                  ? '#fff'
                  : theme.palette.text.primary
            },
            '& circle': {
              fill: theme.palette.type === 'dark' ? '#fff' : 'none'
            }
          }
        }
      }
    },
    scheduleButton: {
      margin: '18px 0'
    }
  })
);

export default function ScheduleModal(props: {
  openScheduleModal: boolean;
  setScheduleModal: () => void;
  onScheduleTweet: (date: string) => void;
  scheduleLoading: boolean;
  setScheduleAt: any;
  scheduleAt: string | null;
  autoTime: string | null;
  scheduleThread: boolean;
  schedulePostIsPosted: boolean;
  onShowNotification: (status: string, message: string) => void;
}) {
  const classes = useStyles();
  const {
    openScheduleModal,
    setScheduleModal,
    onScheduleTweet,
    scheduleLoading,
    setScheduleAt,
    scheduleAt,
    autoTime,
    scheduleThread,
    schedulePostIsPosted,
    onShowNotification
  } = props;
  const [selectedDate, setSelectedDate] = React.useState<any>(new Date());
  const [selectedTime, setSelectedTime] = React.useState<any>(new Date());

  const formatEpoch = (epoch: any) => {
    return new Date(epoch);
  };

  React.useEffect(() => {
    if (scheduleAt || autoTime) {
      setSelectedDate(
        new Date(formatEpoch(Number(scheduleAt || autoTime)).toString())
      );
      setSelectedTime(
        formatEpoch(Number(scheduleAt || autoTime))
          .toTimeString()
          .split(' ')[0]
      );
    }
  }, [scheduleAt, setScheduleAt, autoTime]);

  //if less than 10 i.e 9 return in format 09
  const formatNumber = (numberToBeChecked: number) => {
    return numberToBeChecked < 10
      ? `0${numberToBeChecked}`
      : `${numberToBeChecked}`;
  };

  const handleScheduleAction = () => {
    if (!scheduleLoading) {
      if (!schedulePostIsPosted) {
        let year = selectedDate.getFullYear();
        let month = selectedDate.getMonth() + 1;
        let day = formatNumber(selectedDate.getDate());
        var hours = selectedTime.getHours();
        var minutes = selectedTime.getMinutes();

        let UtcEpochTime = Date.parse(
          new Date(`${year}/${month}/${day}, ${hours}:${minutes}`).toUTCString()
        );
        console.log(year, month, day, `${hours}:${minutes}`, UtcEpochTime);

        // const userLocaleEpochTime = Date.parse(
        //   new Date(convertedUserLocalTime).toISOString()
        // );
        // const finalEpochTime = Date.parse(
        //   new Date(convertedUSADateTime).toISOString()
        // );
        if (Date.parse(new Date().toUTCString()) < UtcEpochTime) {
          onScheduleTweet(String(UtcEpochTime));
        } else {
          onShowNotification('error', 'Tweet cannot be scheduled for past.');
        }
      } else {
        onShowNotification('error', 'Tweet is already published.');
      }
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openScheduleModal}
        onClose={setScheduleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        disableEnforceFocus
      >
        <Fade in={openScheduleModal}>
          <Box className={classes.cardBody}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.header}
              color={'textPrimary'}
            >
              Schedule at specific time
            </Typography>
            <div className={classes.dateTimePickerContainer}>
              <MaterialDatePicker
                disablePast
                label="Pick a date"
                value={selectedDate}
                onChange={(date) => {
                  setSelectedDate(date);
                }}
              />

              <MaterialTimePicker
                label="Pick a time"
                value={selectedTime}
                onChange={(time) => {
                  setSelectedTime(time);
                }}
              />
            </div>
            <div
              className={classes.dateTimePickerContainer}
              style={{ marginTop: '20px' }}
            >
              <Typography
                component="p"
                className={classes.header}
                color={'textPrimary'}
              >
                Timezone:{' '}
                <Typography component="span" color={'textPrimary'}>
                  {' '}
                  {Intl.DateTimeFormat().resolvedOptions().timeZone}
                </Typography>
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.scheduleButton}
              onClick={handleScheduleAction}
              disabled={scheduleLoading}
            >
              {scheduleLoading
                ? 'loading'
                : scheduleThread
                ? 'Update schedule'
                : 'Schedule'}
            </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

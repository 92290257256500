import { connect } from 'react-redux';

import {RootState} from "src/store";
import { toggleSidebar, toggleDarKMode } from "src/store/siteCoordinator/siteCoordinator.actions";

const mapState = (state: RootState) => ({
    authState: state.auth,
    siteCoordinator: state.siteCoordinator,
})

const mapDispatch = (dispatch: any) => ({
    onToggleSidebar: (value: boolean) => dispatch(toggleSidebar(value)),
    onToggleDarKMode: (value: boolean) => dispatch(toggleDarKMode(value))
    // dispatch({ type: 'TOGGLE_SIDEBAR' }),
})

const container = connect(mapState, mapDispatch);

export default container;

import { Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px'
    }
  })
);

export default function SimpleBackdrop(props: { open: string | null }) {
  const { open } = props;
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open ? true : false}>
        <CircularProgress color="inherit" />
        <Typography>{open || 'Loading.'}</Typography>
      </Backdrop>
    </div>
  );
}

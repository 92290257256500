import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, Container, Grid, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import container from './Login.container';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 600,
    padding: '35px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 0px 30px 0px'
    }
  },
  card: {
    padding: '10px 20px 50px',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      maxWidth: '100%'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    flexDirection: 'column',
    alignItems: 'center'
  },
  titleOne: {
    padding: 3,
    fontSize: 24,
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14
    }
  },
  text: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    textDecoration: 'none',
    [theme.breakpoints.only('xs')]: {
      fontSize: 14
    }
  }
}));

const Login = (props: any) => {
  const {
    authState: { loading },
    onLoginStart,
    history
  } = props;
  const classes = useStyles();

  const handleLogin = (values: any) => {
    onLoginStart(values, history);
  };

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="sm">
        <Grid style={{ margin: '2% 0' }}>
          <Card className={classes.card}>
            <div className={classes.paper}>
              <Typography component="span" className={classes.titleOne}>
                Login
              </Typography>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email('Invalid email address')
                    .required('Please add your email address'),
                  password: Yup.lazy(() => {
                    return Yup.string()
                      .required('No password provided')
                      .max(32, 'Too long');
                  })
                })}
                onSubmit={handleLogin}
              >
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Field
                        component={TextField}
                        id="email"
                        name="email"
                        variant="outlined"
                        required
                        fullWidth
                        label="Email"
                        disabled={loading}
                        // autoFocus
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        id="password"
                        name="password"
                        variant="outlined"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        disabled={loading}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : 'Login'}
                  </Button>
                </Form>
              </Formik>
              <Grid container>
                <Grid item xs>
                  <Link to="/forgot-password" className={classes.text}>
                    <span className={classes.link}>Forgot Password?</span>
                  </Link>
                </Grid>
                <Grid item xs>
                  <Link to="/signup" className={classes.text}>
                    Don&apos;t have an account?{' '}
                    <span className={classes.link}>Sign Up</span>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Container>
    </div>
  );
};

export default container(Login);

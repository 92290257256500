import React from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Collapse,
  createStyles,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  BiChevronsUp,
  BiHistory,
  BiMessageSquareAdd,
  BiMessageSquareEdit,
  BiRss,
  BiPlus,
} from "react-icons/bi";
import { BsTwitter } from "react-icons/bs";
import { MdOutlineAnalytics } from "react-icons/md";
import { MdSchedule } from "react-icons/md";
import SectionTab from "../../../constant/SectionTab";
import { RootState } from "../../../store";
import { setCurrentTab } from "../../../store/siteCoordinator/siteCoordinator.actions";
import SidebarItem from "./components/SidebarItem";
import SidebarMoreItem from "./components/SidebarMoreItem";
import {
  obtainOauthRequestTokenAction,
  setCurrentAccount,
} from "src/store/account/account.actions";
import { IAccount, IAuth } from "src/interfaces";
import { subscriptionAccess, isUserAVendor } from "src/components/utils";
import { showSnackbarNotification } from "src/store/notification/notification.actions";

interface Props {
  sidebar: boolean;
  onToggleSidebar: (value: boolean) => void;
  handleLoginLogout: () => void;
}

const drawerWidth = 200;
const miniDrawerWidth = 55;
const useStyles = makeStyles((theme: any) =>
  createStyles({
    appBar: {
      boxShadow: "0px 1px 3px rgb(3 0 71 / 9%)",
      padding: "11px 0",
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${miniDrawerWidth}px)`,
      },
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      boxShadow: "0px 1px 3px rgb(3 0 71 / 9%)",
      padding: "11px 0",
      [theme.breakpoints.up("md")]: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
      },
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    drawerOpen: {
      borderRight: "none",
      marginTop: 64,
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      marginTop: 64,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: miniDrawerWidth,
      [theme.breakpoints.up("sm")]: {
        width: miniDrawerWidth,
      },
    },
    active: {
      background: theme.palette.primary.A100,
    },
    listItemIcon: {
      minWidth: "35px",
      color: ({ section, currentTab }: any) =>
        section === currentTab
          ? theme.palette.primary.main
          : theme.palette.text.secondary,
    },
    fontText: {
      fontSize: "16px",
      color: ({ section, currentTab }: any) =>
        section === currentTab
          ? theme.palette.primary.main
          : theme.palette.text.secondary,
    },
    addAccount: {
      fontSize: "16px",
      marginLeft: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    account: {
      fontSize: "14px",
      marginLeft: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    avatar: {
      margin: 8,
      color: "#fff",
      backgroundColor: "#ccc",
      textTransform: "capitalize",
    },
    collapsePadding: {
      paddingBottom: "75px",
    },
    name: {
      width: 145,
      paddingLeft: 5,
      display: "block",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      "&:hover": {
        overflow: "visible",
      },
      fontSize: 14,
      textTransform: "none",
      cursor: "pointer",
    },
  })
);

export const Sidebar: React.FC<Props> = ({
  sidebar,
  onToggleSidebar,
  handleLoginLogout,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(sidebar);
  const dispatch = useDispatch();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const { loading, user }: IAuth = useSelector(
    (state: RootState) => state.auth
  );
  const { accountId, results }: IAccount = useSelector(
    (state: RootState) => state.account
  );
  const { currentTab } = useSelector(
    (state: RootState) => state.siteCoordinator
  );
  const [name, setName] = React.useState<string | null>(null);
  const [avatar, setAvatar] = React.useState<string | null>(null);
  const [image, setImage] = React.useState<string | null>(null);
  const [username, setUsername] = React.useState<string | null>(null);
  const [access, setAccess] = React.useState<any>(null);
  const [canAddAccount, setCanAddAccount] = React.useState<boolean>(false);
  const [isVendor, setIsVendor] = React.useState(false);

  const list = Object.values(results);

  React.useEffect(() => {
    if (user && user.subscriptionType) {
      (async function anyName() {
        const allow = await subscriptionAccess(user.subscriptionType);
        setAccess(allow);
        setCanAddAccount(
          allow &&
            list.length < allow.account &&
            user.subscriptionStatus !== "canceled"
        );
      })();
    }
    if (user && user.role) {
      setIsVendor(isUserAVendor(user.role));
    }
  }, [user]);

  React.useEffect(() => {
    if (user) {
      setName(`${user?.firstname || ""} ${user?.lastname || ""}`);
      setAvatar(user?.firstname?.charAt(0)?.toUpperCase());
      setUsername(user?.email);
    }
    if (accountId && results) {
      const activeAccount = results[accountId];
      console.log(results, "accountid");
      setName(`${activeAccount?.username}`);
      setImage(`https://unavatar.io/twitter/${activeAccount?.username}`);
      setAvatar(activeAccount?.username?.charAt(0)?.toUpperCase());
    }
  }, [user, accountId, results]);

  const toggleMobileNav = () => {
    onToggleSidebar(!sidebar);
  };

  const onListItemClick = (tab: string) => {
    if (matchesSM) {
      onToggleSidebar(false);
    }
    if (!sidebar && tab === SectionTab.Upgrade) {
      setOpenList(!openList);
    }
    if (tab === SectionTab.NewThread) {
      const isPosted = localStorage.getItem("isPosted")
        ? localStorage.getItem("isPosted")
        : false;
      //isPosted is true when user post tweet without saving to draft
      if (isPosted && isPosted === "true") {
        history.push("/new-thread?newThreadAfterQuickPost=true");
      } else {
        history.push("/new-thread?newThread=true");
      }
    } else {
      history.push(`/${tab}`);
    }
    dispatch(setCurrentTab(tab));
    return tab;
  };

  const [openList, setOpenList] = React.useState(false);
  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenList(!openList);
  };

  const setCurrentAccountHandler = (account: any) => {
    // console.log('account: ', account);
    if (account) {
      dispatch(setCurrentAccount(account.twitterId));
    }
  };

  const addNewAccount = () => {
    if (access) {
      if (canAddAccount) {
        dispatch(obtainOauthRequestTokenAction());
      } else {
        dispatch(
          showSnackbarNotification(
            "error",
            `Your subscription limit for ${access.account} profile. Upgrade to higher tier subscription plan.`
          )
        );
      }
    } else {
      dispatch(
        showSnackbarNotification(
          "error",
          "This feature is only for subscribed user. Subscribe now."
        )
      );
    }
  };

  const renderList = () => {
    return (
      <List>
        {user && (
          <div style={{ display: "flex", height: 55 }}>
            {accountId && results[accountId]?.username && image ? (
              <img
                src={image}
                alt={user?.firstname}
                style={{ borderRadius: "50%", padding: "10px" }}
              />
            ) : (
              <Avatar className={classes.avatar}>{avatar || "A"}</Avatar>
            )}
            <div style={{ marginTop: 8 }}>
              <Typography
                component="div"
                style={{ textTransform: "capitalize" }}
                className={classes.name}
              >
                {name}
              </Typography>
              <Typography component="div" className={classes.name}>
                {username}
              </Typography>
            </div>
          </div>
        )}
        {matchesSM && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (matchesSM) {
                onToggleSidebar(false);
              }
              handleLoginLogout();
            }}
            style={{ display: "flex", margin: "auto", width: "80%" }}
            disabled={loading}
          >
            {loading ? "loading" : user ? "Logout" : "Login"}
          </Button>
        )}
        <SidebarItem
          section={SectionTab.NewThread}
          clicked={() => onListItemClick(SectionTab.NewThread)}
          text="New Thread"
          icon={<BiMessageSquareAdd size={"23px"} />}
          currentTab={currentTab}
        />
        <SidebarItem
          section={SectionTab.Draft}
          clicked={() => onListItemClick(SectionTab.Draft)}
          text="Draft"
          icon={<BiMessageSquareEdit size={"23px"} />}
          currentTab={currentTab}
        />
        <SidebarItem
          section={SectionTab.Schedule}
          clicked={() => onListItemClick(SectionTab.Schedule)}
          text="Schedule"
          icon={<MdSchedule size={"23px"} />}
          currentTab={currentTab}
        />
        <SidebarItem
          section={SectionTab.History}
          clicked={() => onListItemClick(SectionTab.History)}
          text="History"
          icon={<BiHistory size={"23px"} />}
          currentTab={currentTab}
        />
        <SidebarItem
          section={SectionTab.RSSFeed}
          clicked={() => onListItemClick(SectionTab.RSSFeed)}
          text="RSS Feed"
          icon={<BiRss size={"23px"} />}
          currentTab={currentTab}
        />
        <SidebarItem
          section={SectionTab.Analytics}
          clicked={() => onListItemClick(SectionTab.Analytics)}
          text="Analytics"
          icon={<MdOutlineAnalytics size={"23px"} />}
          currentTab={currentTab}
        />
        <SidebarItem
          section={SectionTab.Upgrade}
          clicked={() => onListItemClick(SectionTab.Upgrade)}
          text="Accounts"
          nestedList={
            <SidebarMoreItem openList={openList} handleClick={handleClick} />
          }
          icon={<BiChevronsUp size={"23px"} />}
          currentTab={currentTab}
        />
        <Collapse
          in={openList}
          timeout="auto"
          unmountOnExit
          className={classes.collapsePadding}
        >
          {user && (
            <>
              <Divider light />
              {list.length > 0 &&
                list.map((value: any, index) => {
                  return (
                    <ListItem
                      button
                      key={index}
                      onClick={() => setCurrentAccountHandler(value)}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        {value ? (
                          <img
                            src={`https://unavatar.io/twitter/${value.username}`}
                            alt={value.username}
                            style={{
                              borderRadius: "50%",
                              padding: "10px",
                              height: "30px",
                              width: "30px",
                            }}
                          />
                        ) : (
                          <BsTwitter size={"23px"} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            className={classes.account}
                            variant={"h6"}
                            color={"textSecondary"}
                          >
                            {value.username}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
              {!isVendor && (
                <ListItem
                  button
                  key="AddAccount"
                  onClick={() => addNewAccount()}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    {<BiPlus size={"23px"} />}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        className={classes.addAccount}
                        variant={"h6"}
                        color={"textSecondary"}
                      >
                        Add Account
                      </Typography>
                    }
                  />
                </ListItem>
              )}
            </>
          )}
          {!user && (
            <SidebarItem
              section={SectionTab.SignUp}
              clicked={() => onListItemClick(SectionTab.SignUp)}
              text="Sign Up"
              icon={<BiPlus size={"23px"} />}
              currentTab={currentTab}
            />
          )}
        </Collapse>
      </List>
    );
  };

  return (
    <>
      <Hidden mdUp>
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          classes={{ paper: classes.appBar }}
          open={sidebar}
          onOpen={toggleMobileNav}
          onClose={toggleMobileNav}
          variant="temporary"
        >
          {renderList()}
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          className={classes.drawer}
          classes={{
            paper: sidebar ? classes.drawerOpen : classes.drawerClose,
          }}
        >
          {renderList()}
        </Drawer>
      </Hidden>
    </>
  );
};

export default Sidebar;

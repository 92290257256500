export const RESET_STATE = 'RESET_STATE';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAIL = 'LOAD_USER_FAIL';

export const START_TWEET_POST_PROCESS = 'START_TWEET_POST_PROCESS';
export const TWEET_POST_SUCCESS = 'TWEET_POST_SUCCESS';
export const TWEET_POST_ERROR = 'TWEET_POST_ERROR';
export const TWEET_SCHEDULE = 'TWEET_SCHEDULE';

export const TWEET_START_SAVE_AS_DRAFT = 'TWEET_START_SAVE_AS_DRAFT';
export const TWEET_SAVE_AS_DRAFT_SUCCESS = 'TWEET_SAVE_AS_DRAFT_SUCCESS';
export const TWEET_DRAFT_ERROR = 'TWEET_DRAFT_ERROR';
export const TWEET_START_DRAFT_GET = 'TWEET_START_DRAFT_GET';
export const TWEET_START_DRAFT_GET_SUCESS = 'TWEET_START_DRAFT_GET_SUCESS';
export const TWEET_DRAFT_DELETE_START = 'TWEET_DRAFT_DELETE_START';
export const TWEET_DRAFT_DELETE_SUCCESS = 'TWEET_DRAFT_DELETE_SUCCESS';
export const TWEET_DRAFT_UPDATE_START = 'TWEET_DRAFT_UPDATE_START';
export const TWEET_DRAFT_UPDATE_SUCCESS = 'TWEET_DRAFT_UPDATE_SUCCESS';

export const TWEET_SCHEDULE_GET_START = 'TWEET_SCHEDULE_GET_START';
export const TWEET_SCHEDULE_GET_SUCESS = 'TWEET_SCHEDULE_GET_SUCESS';
export const TWEET_SCHEDULE_START = 'TWEET_SCHEDULE_START';
export const TWEET_SCHEDULE_SUCCESS = 'TWEET_SCHEDULE_SUCCESS';
export const TWEET_SCHEDULE_ERROR = 'TWEET_SCHEDULE_ERROR';
export const TWEET_SCHEDULE_UPDATE_START = 'TWEET_SCHEDULE_UPDATE_START';
export const TWEET_SCHEDULE_UPDATE_SUCCESS = 'TWEET_SCHEDULE_UPDATE_SUCCESS';
export const TWEET_SCHEDULE_DELETE_START = 'TWEET_SCHEDULE_DELETE_START';
export const TWEET_SCHEDULE_DELETE_SUCCESS = 'TWEET_SCHEDULE_DELETE_SUCCESS';

export const TWEET_HISTORY_GET_START = 'TWEET_HISTORY_GET_START';
export const TWEET_HISTORY_GET_SUCESS = 'TWEET_HISTORY_GET_SUCESS';
export const TWEET_HISTORY_DELETE_START = 'TWEET_HISTORY_DELETE_START';
export const TWEET_HISTORY_DELETE_SUCCESS = 'TWEET_HISTORY_DELETE_SUCCESS';
export const TWEET_HISTORY_ERROR = 'TWEET_HISTORY_ERROR';

export const TWEET_RETWEET_START = 'TWEET_RETWEET_START';
export const TWEET_RETWEET_SUCCESS = 'TWEET_RETWEET_SUCCESS';

export const TWEET_UNRETWEET_START = 'TWEET_UNRETWEET_START';
export const TWEET_UNRETWEET_SUCCESS = 'TWEET_UNRETWEET_SUCCESS';

export const FETCH_TWEET_TIMELINE_START = 'FETCH_TWEET_TIMELINE_START';
export const FETCH_TWEET_TIMELINE_SUCCESS = 'FETCH_TWEET_TIMELINE_SUCCESS';
export const FETCH_TWEET_TIMELINE_FAIL = 'FETCH_TWEET_TIMELINE_FAIL';

export const FETCH_TWEET_ANALYTICS_START = 'FETCH_TWEET_ANALYTICS_START';
export const FETCH_TWEET_ANALYTICS_SUCCESS = 'FETCH_TWEET_ANALYTICS_SUCCESS';
export const FETCH_TWEET_ANALYTICS_FAIL = 'FETCH_TWEET_ANALYTICS_FAIL';

export const FETCH_HASHATG_START = 'FETCH_HASHATG_START';
export const FETCH_HASHATG_SUCCESS = 'FETCH_HASHATG_SUCCESS';
export const FETCH_HASHATG_FAILURE = 'FETCH_HASHATG_FAILURE';


import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Slide,
  Typography,
  useTheme
} from '@material-ui/core';
import clsx from 'clsx';
import {
  addDays,
  endOfMonth,
  format,
  isBefore,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays
} from 'date-fns';
import {useHistory} from 'react-router';
import ScheduleCards from './components.tsx/scheduleCards';
import ScheduleTopSection from './components.tsx/scheduleTopSection';
import ScheduleSkeleton from 'src/components/skeleton-schedule';
import container from './Schedule.container';
import DisplayCard from 'src/components/displayCard/displayCard';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      gap: '5px',
      alignItems: 'center'
    },

    header: {
      fontSize: '14px',
      fontWeight: 500,
      display: 'flex',
      color: theme.palette.text.primary,
      gap: '5px',
      textAlign: 'center',
      justifyContent: 'center',
      marginBottom: '10px',

      [theme.breakpoints.down('md')]: {
        marginRight: '2px',
        marginLeft: '2px',
        flexWrap: 'wrap',
        fontSize: '12px'
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: '0.5px',
        marginLeft: '0.5px',
        flexDirection: 'column'
      }
    },
    gridContainer: {
      width: '100%'
    },
    column: {
      overflow: 'hidden',
      width: '100%',
      minHeight: '75vh',
      border:
        theme.palette.type === 'dark'
          ? `1px solid ${theme.palette.primary.contrastText}`
          : `1px solid ${theme.palette.secondary.contrastText}`,
      borderTop: 'none',
      '&:nth-of-type(2n)': {
        borderLeft: 'none',
        borderRight: 'none'
      }
    },
    scheduledPostContainer: {
      maxHeight: (props) => (!props.weeklyView ? '65px' : '10000px'),
      overflow: 'hidden',
      borderTop: (props: any) =>
        props.weeklyView
          ? theme.palette.type === 'dark'
            ? `1px solid ${theme.palette.primary.contrastText}`
            : `1px solid ${theme.palette.secondary.contrastText}`
          : 'none'
    },
    day: {
      position: 'relative',
      color: theme.palette.text.primary,
      padding: 2,
      height: '115px',
      outline: '1px solid',
      marginTop: '1px',
      marginLeft: '1px',
      background: theme.palette.type === 'light' ? '#fff' : '#535050',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        height: '120px'
      }
    },
    pastDay: {
      background: '#6d6d6d1f'
    },
    iconButton: {
      padding: '5px'
    },

    dayButtonContainer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '25px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '5px'
    },
    hideDayButtonContainer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '40px',
      zIndex: 1,
      background: theme.palette.type === 'light' ? '#f1f1f1' : '#535050'
    },
    monthlyUiContainer: {
      display: 'flex',
      gap: '10px',
      maxHeight: '604px'
    },
    monthlyUi: {
      flex: '70%',
      transition: 'width 1s '
    },
    dayDetailViewContainer: {
      flex: '30%',
      marginTop: '60px',
      minHeight: '300px',
      borderTop: 'none',
      maxWidth: '100%',
      padding: '5px 0',

      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      }
    },
    dayDetailView: {
      maxHeight: '408px',
      overflow: 'auto'
    },
    dayDetailViewTitle: {
      paddingLeft: `5px`
    },
    blurEffect: {
      height: '20px',
      position: 'absolute',
      bottom: 0
    }
  })
);

const Schedule = (props: any) => {
  const {
    tweetskyState: { scheduledPost, scheduleLoading },
    onDeleteSchedulePost,
    accountState: { accountId, results }
  } = props;
  const theme = useTheme();
  const history = useHistory();
  const dayScheduleViewRef: any = useRef();
  const [weekContainer, setWeekContainer] = useState<Date[]>([]);
  const [monthContainer, setMonthContainer] = useState<Date[][]>([]);
  const [weeklyView, setWeeklyView] = useState<boolean>(true);
  const [dayScheduleView, setDayScheduleView] = useState<Date | null>(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles({ weeklyView: weeklyView });
  const [username, setUsername] = React.useState<string>('');
  const [avatar, setAvatar] = React.useState<string>('');

  React.useEffect(() => {
    if (accountId && results) {
      const activeAccount = results[accountId];
      setUsername(`${activeAccount?.username}`);
      setAvatar(activeAccount?.username?.charAt(0)?.toUpperCase());
    }
  }, [accountId, results]);

  function takeWeek(start: Date) {
    let date = startOfWeek(startOfDay(start));
    return [...Array(7)].map((item, i) => addDays(date, i));
  }

  const takeMonth = useCallback((start) => {
    let month: any = [];
    let date = start;
    const weekGenerated = takeWeek(startOfMonth(date));
    const endDate = startOfDay(endOfMonth(date));
    month.push(weekGenerated);

    while (isBefore(month[month.length - 1][6], endDate)) {
      date = addDays(month[month.length - 1][6], 1);

      const weekGenerated = takeWeek(date);
      month.push(weekGenerated);
    }

    return month;
  }, []);

  const generateWeek = useCallback((start) => {
    const weekGenerated = takeWeek(start);
    setWeekContainer(weekGenerated);
  }, []);

  const generateMonth = useCallback(
    (start) => {
      const monthGenerated = takeMonth(start);
      setMonthContainer(monthGenerated);
    },
    [takeMonth]
  );

  useEffect(() => {
    generateWeek(new Date());
    generateMonth(new Date());
  }, [generateWeek, generateMonth]);

  const nextWeek = () => {
    generateWeek(addDays(weekContainer[6], 1));
  };
  const prevWeek = () => {
    generateWeek(subDays(weekContainer[0], 1));
  };

  const nextMonth = () => {
    if (
      monthContainer[monthContainer.length - 1] &&
      monthContainer[monthContainer.length - 1].length > 6
    ) {
      generateMonth(addDays(monthContainer[monthContainer.length - 1][6], 1));
    }
  };

  const prevMonth = () => {
    generateMonth(subDays(monthContainer[0][0], 1));
  };

  const formatEpoch = (epoch: any) => {
    const date = new Date(epoch);

    return date;
  };

  const returnTodaysScheduledPost = (
    scheduledPost: any,
    dayScheduleView: Date
  ) => {
    return (
      <>
        {Object.values(scheduledPost) &&
          Object.values(scheduledPost).map((post: any) => (
            <div key={post.postId}>
              {post.scheduleAt &&
                format(new Date(Number(post.scheduleAt)), 'dMY') ===
                  format(dayScheduleView, 'dMY') && (
                  <DisplayCard
                    avatar={avatar}
                    username={username}
                    historyData={post}
                    onDeleteSchedulePost={onDeleteSchedulePost}
                    area={'schedule'}
                  />
                )}
            </div>
          ))}
      </>
    );
  };

  return (
    <Box style={{ flexGrow: 1 }}>
      {scheduleLoading ? (
        <ScheduleSkeleton />
      ) : (
        <>
          {weeklyView ? (
            <>
              {/* weekly view of schedule tweet */}
              <ScheduleTopSection
                weeklyView={weeklyView}
                monthContainer={monthContainer}
                weekContainer={weekContainer}
                nextMonth={nextMonth}
                prevMonth={prevMonth}
                nextWeek={nextWeek}
                prevWeek={prevWeek}
                generateMonth={generateMonth}
                generateWeek={generateWeek}
                setWeeklyView={setWeeklyView}
              />

              <Grid
                container
                style={{ flexWrap: 'nowrap' }}
                className={classes.gridContainer}
              >
                {weekContainer.map((week, index) => (
                  <Grid item className={classes.column} key={index}>
                    <div className={classes.header}>
                      <span> {format(week, 'ccc')}</span>
                      <span>{format(week, 'd')}</span>
                    </div>
                    <div className={classes.scheduledPostContainer}>
                      {Object.values(scheduledPost) &&
                        Object.values(scheduledPost).map((post: any) => (
                          <div key={post.postId}>
                            {post.scheduleAt &&
                              formatEpoch(Number(post.scheduleAt))
                                .toLocaleString()
                                .split(',')[0] ===
                                week.toLocaleString().split(',')[0] && (
                                <ScheduleCards
                                  schedulePost={post}
                                  onDeleteSchedulePost={onDeleteSchedulePost}
                                  smallCardView={true}
                                  hideIconOnDayView={true}
                                />
                              )}
                          </div>
                        ))}
                    </div>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <>
              {/* monthly view */}
              <div className={classes.monthlyUiContainer}>
                <div className={classes.monthlyUi}>
                  <ScheduleTopSection
                    weeklyView={weeklyView}
                    monthContainer={monthContainer}
                    weekContainer={weekContainer}
                    nextMonth={nextMonth}
                    prevMonth={prevMonth}
                    nextWeek={nextWeek}
                    prevWeek={prevWeek}
                    generateMonth={generateMonth}
                    generateWeek={generateWeek}
                    setWeeklyView={setWeeklyView}
                  />
                  <Grid
                    container
                    style={{ flexWrap: 'nowrap' }}
                    className={classes.gridContainer}
                    spacing={1}
                  >
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(
                      (day, index) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={2}
                              className={classes.header}
                              key={index}
                            >
                              {day}
                            </Grid>
                          </>
                        );
                      }
                    )}
                  </Grid>
                  <Grid container className="calendar">
                    {monthContainer &&
                      monthContainer.map((week: any, index: any) => (
                        <Grid
                          item
                          xs={12}
                          style={{ display: 'flex' }}
                          key={index}
                        >
                          {week.map((day: any, index: any) => (
                            <Grid
                              item
                              xs={2}
                              className={clsx(
                                classes.day,
                                !isSameDay(day, new Date()) &&
                                  isBefore(day, new Date()) &&
                                  classes.pastDay
                              )}
                              key={index}
                              onClick={(e: any) => {
                                if (e.target.tagName.toLowerCase() === 'div') {
                                  history.push(
                                    `/new-thread?newThread=true&autoTime=${day.getTime()}`
                                  );
                                }
                              }}
                            >
                              {format(day, 'd')}

                              <div className={classes.scheduledPostContainer}>
                                {Object.values(scheduledPost) &&
                                  Object.values(scheduledPost)
                                    .sort(function (a: any, b: any) {
                                      return (
                                        Number(b.updatedAt) -
                                        Number(a.updatedAt)
                                      );
                                    })
                                    .map((post: any) => (
                                      <div key={post.postId}>
                                        {post.scheduleAt &&
                                          format(
                                            formatEpoch(
                                              Number(post.scheduleAt)
                                            ),
                                            'dMY'
                                          ) === format(day, 'dMY') && (
                                            <>
                                              <ScheduleCards
                                                schedulePost={post}
                                                onDeleteSchedulePost={
                                                  onDeleteSchedulePost
                                                }
                                                hideIconOnDayView={true}
                                                smallCardView={true}
                                              />
                                              <div
                                                className={
                                                  classes.dayButtonContainer
                                                }
                                                onClick={(e: any) => {
                                                  setDayScheduleView(day);
                                                  setOpen(true);
                                                }}
                                              >
                                                <Typography
                                                  color="primary"
                                                  style={{
                                                    fontSize: '11px',
                                                    paddingLeft: '5px'
                                                  }}
                                                >
                                                  View more
                                                </Typography>
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    ))}
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      ))}
                  </Grid>
                </div>
                {/* mobile view for scheduledpost view */}
                {window.innerWidth < 886 && !weeklyView && dayScheduleView && (
                  <Dialog
                    open={open}
                    onClose={() => {}}
                    scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    maxWidth="lg"
                    fullWidth={true}
                  >
                    <DialogTitle
                      style={{
                        background:
                          theme.palette.type === 'dark' ? '#383838' : '#e7e6e6'
                      }}
                    >
                      <Typography
                        variant="h6"
                        className={classes.dayDetailViewTitle}
                        color="textSecondary"
                        ref={dayScheduleViewRef}
                      >
                        {format(dayScheduleView, 'eee')}{' '}
                        {format(dayScheduleView, 'd')}{' '}
                        {format(dayScheduleView, 'MMM')}
                      </Typography>
                    </DialogTitle>
                    <DialogContent
                      dividers={true}
                      style={{
                        background:
                          theme.palette.type === 'dark' ? '#383838' : '#e7e6e6'
                      }}
                    >
                      <div
                        className={classes.dayDetailViewContainer}
                        style={{ marginTop: 0 }}
                      >
                        <div className={classes.dayDetailView}>
                          {returnTodaysScheduledPost(
                            scheduledPost,
                            dayScheduleView
                          )}
                        </div>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          setOpen(false);
                        }}
                        color="primary"
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
                {/* desktop view for scheduledpost view */}
                {window.innerWidth > 886 && !weeklyView && dayScheduleView && (
                  <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <div className={classes.dayDetailViewContainer}>
                      <div className={classes.dayDetailView}>
                        <Typography
                          variant="h6"
                          className={classes.dayDetailViewTitle}
                          color="textSecondary"
                          ref={dayScheduleViewRef}
                        >
                          {format(dayScheduleView, 'eee')}{' '}
                          {format(dayScheduleView, 'd')}{' '}
                          {format(dayScheduleView, 'MMM')}
                        </Typography>
                        {returnTodaysScheduledPost(
                          scheduledPost,
                          dayScheduleView
                        )}
                      </div>
                    </div>
                  </Slide>
                )}
              </div>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default container(Schedule);

import { connect } from "react-redux";
import { RootState } from "src/store";
import { showSnackbarNotification } from "src/store/notification/notification.actions";
import { startTweetDraftDelete } from "src/store/tweet/tweetAction";

const mapStateToProps = (state: RootState) => ({
  tweetskyState: state.tweetsky,
  authState: state.auth,
  accountState: state.account,
});

const mapDispatchToProps = (dispatch: any) => ({
  onShowNotification: (status: string, message: string) =>
    dispatch(showSnackbarNotification(status, message)),
  onDeleteDraft: (postId: string) =>
    dispatch(startTweetDraftDelete(postId)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;

import React from 'react';
import { Skeleton } from '@material-ui/lab';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader, Container,
  createStyles,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    card: {
      position: 'relative',
      boxShadow: '0px 1px 3px rgb(3 0 71 / 9%)',
      width: '100%',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    header: {
      fontSize: '12px',
      paddingBottom: '16px',
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    },
    content: {
      padding: '0',
      paddingBottom: 0,
      marginBottom: 17
    }
  })
);

export default function ThreadSkeleton() {
  const classes = useStyles();
  return (
    <Container style={{ maxWidth: 800 }}>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            className={classes.header}
            component="p"
            color="textSecondary"
          >
            <Skeleton animation="wave" height={40} width="60%"/>
          </Typography>
          <CardHeader
            className={classes.content}
            avatar={
              <Skeleton variant="circle" width={30} height={30}/>
            }
            title={
              <div>
                <Skeleton animation="wave" height={40} width="90%"/>
                <Skeleton animation="wave" height={80} width="90%"/>
              </div>
            }
          />
          <CardHeader
            className={classes.content}
            avatar={
              <Skeleton variant="circle" width={30} height={30}/>
            }
            title={
              <div>
                <Skeleton animation="wave" height={40} width="90%"/>
                <Skeleton animation="wave" height={80} width="90%"/>
              </div>
            }
          />
        </CardContent>
        <CardActions>
          <Skeleton animation="wave" height={40} width={80}/>
          <Skeleton animation="wave" height={40} width={80}/>
        </CardActions>
      </Card>
    </Container>
  );
}

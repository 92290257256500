import { RootState } from 'src/store';
import { connect } from 'react-redux';
import { registerNewUser, signupStart, checkSubVendorAUser } from 'src/store/auth/authAction';
import { subscribeUser } from 'src/store/subscription/subscription.actions';

const mapStateToProps = (state: RootState) => ({
  authState: state.auth,
  notificationState: state.notification,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRegisterNewUser: (values: any) => dispatch(registerNewUser(values)),
  onSignupStart: (values: any, history: any) => dispatch(signupStart(values, history)),
  onSubscribeUser: (user: any) => dispatch(subscribeUser(user)),
  onCheckSubVendorAUser: (invitationId: string, history: any) => dispatch(checkSubVendorAUser(invitationId, history))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;

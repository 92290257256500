import {
  createStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardBody: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 400,
      minWidth: 200,
      backgroundColor: theme.palette.type === 'light' ? '#f3f7fa' : '#424242',
      borderRadius: 5,
      padding: '25px 25px 18px 25px'
    },
    header: {
      fontWeight: 500,
      marginBottom: 10
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    button: {
      margin: '18px 0'
    }
  })
);

export default function LogoutModal(props: {
  openPopUp: boolean;
  setOpenPopUp: (value: boolean) => void;
  logUserOut: () => void;
}) {
 

  const classes = useStyles();
  const { openPopUp, setOpenPopUp, logUserOut } = props;

  return (
    <div>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h6"
            component="h2"
            color={'textPrimary'}
            className={classes.header}
          >
            Are you sure, you want to logout?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={classes.buttonContainer}>
              <Button
                type="button"
                color="secondary"
                variant="contained"
                className={classes.button}
                onClick={logUserOut}
              >
                Logout
              </Button>
              <Button
                type="button"
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => setOpenPopUp(false)}
              >
                Cancel
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

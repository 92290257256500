import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import HomePage from "../pages/Home/index";
import PageNotFound from "../pages/PageNotFound";
import RouteWithLayout from "./components/RouteWithLayout";
import MainLayout from "src/layout/Main";
import Minimal from "../layout/Minimal";
import Draft from "src/pages/Draft";
import Schedule from "src/pages/Schedule";
import ThreadView from "src/pages/ThreadView";
import { useDispatch, useSelector } from "react-redux";
import {
  startGetTweetDraft,
  startTweetScheduleGet,
  tweetHistoryFetchStart,
  tweetTimelineFetchStart,
} from "src/store/tweet/tweetAction";
import { RootState } from "src/store";
import HistoryPage from "src/pages/History";
import UpgradePage from "src/pages/Profile";
import SuccessPage from "src/pages/SubscriptionSuccess";
import CancelPage from "src/pages/SubscriptionCanceled";
import RSSFeedPage from "src/pages/RSSFeed";
import AnalyticsPage from "src/pages/Analytics";
import { fetchTweetAnalyticsStart } from "src/store/tweetAnalytics/tweetAnalyticAction";
import { Signup, SignupVerification } from "../pages/Signup";
import Login from "../pages/Login";
import LandingPage from "src/pages/LandingPage/LandingPage.component";
import {
  ForgotPassword,
  ForgotPasswordConfirmation,
} from "src/pages/ForgotPassword";
import Pricing from "src/pages/Pricing";
import Terms from "src/pages/TermsandPrivacy/Terms";
import Privacy from "src/pages/TermsandPrivacy/Privacy";

const Routes = () => {
  const dispatch = useDispatch();
  // const { user } = useSelector((state: RootState) => state.auth);
  const { accountId } = useSelector((state: RootState) => state.account);
  const { paginationNextToken } = useSelector(
    (state: RootState) => state.tweetsky
  );

  useEffect(() => {
    if (accountId) {
      dispatch(startGetTweetDraft(accountId)); /// list draft
      dispatch(tweetHistoryFetchStart(accountId)); /// list history
      dispatch(startTweetScheduleGet()); /// list schedules
      dispatch(
        tweetTimelineFetchStart({ userId: accountId, paginationNextToken })
      );
      dispatch(fetchTweetAnalyticsStart());
    }
  }, [accountId, dispatch]);

  return (
    <Switch>
      <RouteWithLayout
        exact
        component={LandingPage}
        layout={Minimal}
        path="/"
      />
      <RouteWithLayout
        exact
        component={Terms}
        layout={Minimal}
        path="/terms-and-conditions"
      />
      <RouteWithLayout
        exact
        component={Privacy}
        layout={Minimal}
        path="/privacy-policy"
      />
      <RouteWithLayout
        exact
        component={Signup}
        layout={MainLayout}
        path={"/signup"}
      />
      <RouteWithLayout
        exact
        component={SignupVerification}
        layout={MainLayout}
        path={"/signup-verification"}
      />
      <RouteWithLayout
        exact
        component={Login}
        layout={MainLayout}
        path={"/login"}
      />
      <RouteWithLayout
        exact
        component={Pricing}
        layout={MainLayout}
        path={"/pricing"}
      />
      <RouteWithLayout
        exact
        component={ForgotPassword}
        layout={MainLayout}
        path={"/forgot-password"}
      />
      <RouteWithLayout
        exact
        component={ForgotPasswordConfirmation}
        layout={MainLayout}
        path={"/forgot-password-confirmation"}
      />
      <RouteWithLayout
        exact
        component={HomePage}
        layout={MainLayout}
        path="/new-thread"
      />
      <RouteWithLayout
        exact
        component={HomePage}
        layout={MainLayout}
        path="/draft/:postId"
      />
      <RouteWithLayout
        exact
        component={Draft}
        layout={MainLayout}
        path="/draft"
      />
      <RouteWithLayout
        exact
        component={Schedule}
        layout={MainLayout}
        path="/schedule"
      />
      <RouteWithLayout
        exact
        component={HomePage}
        layout={MainLayout}
        path="/schedule/:postId"
      />
      <RouteWithLayout
        exact
        component={HomePage}
        layout={MainLayout}
        path="/rss/:postId"
      />
      <RouteWithLayout
        exact
        component={ThreadView}
        layout={MainLayout}
        path="/thread/:postId"
      />
      <RouteWithLayout
        exact
        component={HistoryPage}
        layout={MainLayout}
        path="/history"
      />
      <RouteWithLayout
        exact
        component={RSSFeedPage}
        layout={MainLayout}
        path="/rss-feed"
      />
      <RouteWithLayout
        exact
        component={AnalyticsPage}
        layout={MainLayout}
        path="/analytics"
      />
      <RouteWithLayout
        exact
        component={UpgradePage}
        layout={MainLayout}
        path="/upgrade"
      />
      <RouteWithLayout
        exact
        component={SuccessPage}
        layout={MainLayout}
        path="/success"
      />
      <RouteWithLayout
        exact
        component={CancelPage}
        layout={MainLayout}
        path="/canceled"
      />
      <RouteWithLayout path="*" component={PageNotFound} layout={MainLayout} />
      {/*<Route component={Home} />*/}
    </Switch>
  );
};

export default Routes;

import * as SubscriptionType from './subscription.types';

export const subscribeUser = (user: any) => ({
  type: SubscriptionType.SUBSCRIBE_USER_START,
  payload: { user }
});

export const updateSubscription = (user: any) => ({
  type: SubscriptionType.UPDATE_SUBSCRIPTION_START,
  payload: { user }
});

export const completeSubscriptionProcess = () => ({
  type: SubscriptionType.SUBSCRIBE_USER_COMPLETE
});

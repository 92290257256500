import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { authReducer } from './auth/authReducer';
import { accountReducer } from "src/store/account/account.reducer";
import siteCoordinatorReducer from './siteCoordinator/siteCoordinator.reducer';
import { tweetReducer } from './tweet/tweetReducer';
import rootSaga from './root-sagas';
import notificationReducer from './notification/notification.reducer';
import { tweetAnalyticsReducer } from './tweetAnalytics/tweetAnalyticReducer';
import rssFeedReducer from './rssFeed/rssFeed.reducer';
import { vendorReducer } from 'src/store/vendor/vendor.reducer';
// import rootSaga from "./saga";

const rootReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  tweetsky: tweetReducer,
  siteCoordinator: siteCoordinatorReducer,
  notification: notificationReducer,
  tweetAnalytics: tweetAnalyticsReducer,
  rssFeed: rssFeedReducer,
  vendor: vendorReducer
});
export type RootState = ReturnType<typeof rootReducer>;
const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga);

export default store;

import { IAnalytics } from "src/interfaces";
import {
  FETCH_TWEET_ANALYTICS_FAIL,
  FETCH_TWEET_ANALYTICS_START,
  FETCH_TWEET_ANALYTICS_SUCCESS,
} from "../types/types";

const init_state = {
  loading: false,
  impressionCountArray: [],
  likesCountArray: [],
  retweetCountArray: [],
  userProfileCountArray: [],
  hastagArray: [],
  followersCountArray: [],
};

export const tweetAnalyticsReducer = (state: any = init_state, action: any) => {
  switch (action.type) {
    case FETCH_TWEET_ANALYTICS_START:
      return {
        ...state,
        loading: true,
        impressionCountArray: [],
        likesCountArray: [],
        retweetCountArray: [],
        userProfileCountArray: [],
        hastagArray: [],
        followersCountArray: [],
      };

    case FETCH_TWEET_ANALYTICS_SUCCESS:
      let updatedImpressionCount = [...state.impressionCountArray];
      let updatedLikesCount = [...state.likesCountArray];
      let updatedRetweetCount = [...state.retweetCountArray];
      let updatedUserProfileCount = [...state.userProfileCountArray];
      let updatedHashtagArray = [...state.hastagArray];
      let updatedFollowersCount = [...state.followersCountArray];

      //update each state value
      action.payload &&
        action.payload.forEach((analytic: IAnalytics) => {
          updatedFollowersCount.push([
            Number(analytic.sk.split("#")[2]),
            analytic.todayFollowersCounts,
          ]);
          updatedImpressionCount.push([
            Number(analytic.sk.split("#")[2]),
            analytic.todayImpressionCounts,
          ]);
          updatedLikesCount.push([
            Number(analytic.sk.split("#")[2]),
            analytic.todayLikeCounts,
          ]);
          updatedRetweetCount.push([
            Number(analytic.sk.split("#")[2]),
            analytic.todayRetweetCounts,
          ]);
          updatedUserProfileCount.push([
            Number(analytic.sk.split("#")[2]),
            analytic.todayUserProfileClickedCounts,
          ]);

          //update hastag
          analytic.todayHashTagArray &&
            analytic.todayHashTagArray.forEach((hastagObj: any) => {
              let hastagExistIndex = state.hastagArray.findIndex(
                (stateHashtagObj: any) =>
                  stateHashtagObj.hashtag === hastagObj.hashtag
              );
              if (hastagExistIndex !== -1) {
                updatedHashtagArray[hastagExistIndex].value++;
              } else {
                updatedHashtagArray.push(hastagObj);
              }
            });
        });

      return {
        ...state,
        loading: false,
        impressionCountArray: updatedImpressionCount.reverse(),
        likesCountArray: updatedLikesCount.reverse(),
        retweetCountArray: updatedRetweetCount.reverse(),
        userProfileCountArray: updatedUserProfileCount.reverse(),
        hastagArray: updatedHashtagArray.reverse(),
        followersCountArray: updatedFollowersCount.reverse(),
      };
    case FETCH_TWEET_ANALYTICS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { Alert, Autocomplete, Skeleton } from "@material-ui/lab";
import container from "./RSSFeed.container";
import { useHistory } from "react-router";
import { formatDate, subscriptionAccess } from "src/components/utils";
import { RSSBodyParser } from "src/components/rss-body-parser";

const useStyles = makeStyles((theme: any) => ({
  root: {
    minHeight: 600,
    padding: "35px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0px 30px 0px",
    },
  },
  searchCard: {
    marginTop: 16,
    display: "flex",
    flexWrap: "wrap",
    gap: 5,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  cardRoot: {
    marginTop: 16,
    padding: 8,
    display: "flex",
  },
  textField: {
    background: theme.palette.primary.A200,
    [theme.breakpoints.up("sm")]: {
      minWidth: 500,
    },
  },
  submit: {
    marginLeft: 8,
  },
  alert: {
    fontSize: 16,
    marginTop: 20,
    border: "1px solid rgb(33 150 243)",
    maxWidth: "fit-content",
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
    },
  },
  card: {
    width: "100%",
    marginTop: 8,
    border: theme.palette.primary.A200,
    marginBottom: "1%",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  subheader: {
    fontSize: 16,
  },
  title: {
    color: "#409bfc",
  },
  links: {
    color: "rgb(29, 155, 240)",
    fontSize: 16,
    textDecoration: "none",
    fontWeight: 500,
    lineHeight: "35px",
  },
  iconButton: {
    borderRadius: 8,
  },
  outlined: {
    border: "1px solid rgb(29, 155, 240)",
  },
  autoComplete: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const RSSFeed = (props: any) => {
  const {
    authState: { user },
    rssState: { isSearching, url, results, history },
    onFetchRSSStart,
    onFetchRSSHistory,
    onShowNotification,
  } = props;
  const theme = useTheme();
  const locationHistory = useHistory();
  const classes = useStyles();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXSBT = useMediaQuery(theme.breakpoints.between(599, 750));
  const [inputUrl, setInputUrl] = React.useState(null);
  const [isRSSAccess, setRSSAccess] = React.useState<boolean>(false);
  const items = Object.values(results);

  React.useEffect(() => {
    if (
      user &&
      user.subscriptionType &&
      user.subscriptionStatus !== "canceled"
    ) {
      (async function anyName() {
        const access = await subscriptionAccess(user.subscriptionType);
        setRSSAccess(access && access.searchRSS);
      })();
    }
  }, [user]);

  React.useEffect(() => {
    if (user) {
      setInputUrl(url);
      onFetchRSSHistory();
    }
  }, [user, url, onFetchRSSHistory]);

  const rssList = [
    {
      name: "CNN",
      url: `http://rss.cnn.com/rss/cnn_topstories.rss`,
    },
    {
      name: "Cointelegraph",
      url: `https://cointelegraph.com/rss`,
    },
    {
      name: "USA Today",
      url: `http://rssfeeds.usatoday.com/UsatodaycomNation-TopStories`,
    },
    {
      name: "Yahoo News",
      url: `https://www.yahoo.com/news/rss`,
    },
    {
      name: "Lifehacker",
      url: `https://lifehacker.com/rss`,
    },
    {
      name: "Fortune RSS",
      url: `https://fortune.com/feed`,
    },
  ];

  const selectRssSearch = (events: any) => {
    if (user) {
      if (isRSSAccess) {
        if (events.currentTarget.value) {
          setInputUrl(events.currentTarget.value);
          onFetchRSSStart(events.currentTarget.value);
        }
      }
    } else {
      onShowNotification("error", "User not logged in.");
    }
  };

  const onInputChangeHandler = (event: any) => {
    if (event.target.value && typeof event.target.value === "string") {
      setInputUrl(event.target.value);
    } else {
      setInputUrl(null);
    }
  };

  const onChangeHandler = (event: any, val: any) => {
    if (val) {
      setInputUrl(val.url_link);
    }
  };

  const rssSearchHandler = () => {
    // console.log('inputUrl: ', inputUrl);
    if (user) {
      if (inputUrl) {
        if (isRSSAccess) {
          onFetchRSSStart(inputUrl);
        }
      } else {
        onShowNotification("error", "Please enter the valid RSS field.");
      }
    } else {
      onShowNotification("error", "User not logged in.");
    }
  };

  const headerLink = (props: any) => {
    return (
      <a href={props.link} className={classes.links} target="_">
        {props.title}
      </a>
    );
  };

  // const normalTransform = (node: any) => {
  //   const parser = new DOMParser();
  //   const descContents = parser.parseFromString(node, 'text/html');
  //   return descContents.body.innerText;
  // };

  return (
    <div className={classes.root}>
      <Container style={{ maxWidth: 1400 }}>
        <div className={classes.searchCard}>
          <Autocomplete
            id="rss-search"
            options={history}
            getOptionLabel={(option) => (option ? option.url_link : "")}
            freeSolo
            onInputChange={onInputChangeHandler}
            onChange={onChangeHandler}
            inputValue={inputUrl || ""}
            className={classes.autoComplete}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                id="rssInput"
                label=""
                fullWidth
                className={classes.textField}
                value={inputUrl || ""}
                // onChange={(value) => inputTextHandler(value)}
                onKeyDown={(e: any) => {
                  if (e.keyCode === 13 && e.target.value) {
                    rssSearchHandler();
                  }
                }}
                placeholder="Enter RSS link or URL"
              />
            )}
          />
          <Button
            type="button"
            fullWidth={matchesXS || matchesXSBT}
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={rssSearchHandler}
          >
            Search RSS Link
          </Button>
        </div>
        <div className={classes.searchCard}>
          {rssList.map((value, index) => {
            return (
              <Button
                key={`rss-${index}`}
                variant="outlined"
                classes={{
                  outlined: classes.outlined,
                }}
                style={{ margin: "0px 5px" }}
                value={value.url}
                onClick={selectRssSearch}
              >
                {value.name}
              </Button>
            );
          })}
        </div>
        {!isRSSAccess && (
          <Alert severity="info" color="info" className={classes.alert}>
            Your subscription don't have this access.{" "}
            <strong>Upgrade to higher tier subscription plan</strong>.
          </Alert>
        )}
        <div>
          {isSearching
            ? [1, 2, 3, 4].map((row: any, index: any) => (
                <Card key={`rss-news-${index}`} className={classes.card}>
                  <div>
                    <CardHeader
                      title={
                        <Skeleton
                          animation="wave"
                          height={20}
                          width="80%"
                          style={{ marginBottom: 6 }}
                        />
                      }
                      subheader={
                        <Skeleton animation="wave" height={20} width="40%" />
                      }
                      classes={{
                        subheader: classes.subheader,
                        title: classes.title,
                      }}
                    />
                    <CardContent>
                      <Skeleton animation="wave" height={20} width="80%" />
                    </CardContent>
                    <CardActions>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={80}
                        height={30}
                        style={{ marginLeft: 8 }}
                      />
                    </CardActions>
                  </div>
                </Card>
              ))
            : items.length > 0 &&
              items.map((row: any, index: any) => (
                <Card key={`rss-news-${index}`} className={classes.card}>
                  <div className={classes.details}>
                    <CardHeader
                      title={headerLink(row)}
                      subheader={
                        row.pubDate
                          ? formatDate(row.pubDate.substring(0, 25))
                          : ""
                      }
                      classes={{
                        subheader: classes.subheader,
                        title: classes.title,
                      }}
                    />
                    <CardContent>
                      {/*{normalTransform(row.description || '')}*/}
                      <a href={row?.link} target="_blank" rel="noreferrer">
                        {row?.link}
                      </a>
                      {/* {alert(JSON.stringify(row))} */}
                      {/* {RSSBodyParser(row)} */}
                    </CardContent>
                    <CardActions>
                      <Button
                        style={{ padding: "8px" }}
                        variant="outlined"
                        className={classes.iconButton}
                        onClick={() =>
                          locationHistory.push(`rss/${row?.rssId}`)
                        }
                      >
                        Tweet New Thread
                      </Button>
                    </CardActions>
                  </div>
                </Card>
              ))}
        </div>
      </Container>
    </div>
  );
};

export default container(RSSFeed);

import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { S3 } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import {
  AppBar,
  Box,
  Button,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import CancelIcon from "@material-ui/icons/Cancel";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import GifOutlinedIcon from "@material-ui/icons/GifOutlined";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";
import axios from "axios";
import clsx from "clsx";
import Picker from "emoji-picker-react";
import SimpleBackdrop from "src/components/backdrop";
import { base_Url, bucketName, bucketUrl } from "src/constant/twitterApi";
import { makeId } from "src/utils/helper";
import useComponentVisible from "src/utils/useComponentVisible";
import { s3AccessKey, s3SecretAccessKey } from "../../constant/twitterApi";
import useQuery from "../../utils/useQuery";
import { validateFile, validateSize } from "../../utils/validate";
import GifContainer from "./components/GifContainer";
import HashtagGeneratorModal from "./components/hastagGenerator";
import QuoteTweetModal from "./components/quoteTweetModal";
import ScheduleModal from "./components/ScheduleModal";
import TweetContainer from "./components/TweetContainer";
import container from "./Home.container";
import { HiOutlineHashtag } from "react-icons/hi";
import { RSSBodyParser } from "src/components/rss-body-parser";
import { subscriptionAccess } from "../../components/utils";
import { IAccount } from "../../interfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

declare global {
  interface Params {
    postId: any;
  }
}
export interface ITweetMedia {
  post_index?: "string";
  image: {
    url?: string;
    key?: string;
    type?: string;
    size?: number;
  }[];
  video: {
    url?: string;
    key?: string;

    type?: string;
    size?: number;
  }[];
  gif: {
    url?: string;
    type?: string;
  }[];
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      alignItems: "center",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    hidden: {
      display: "none",
    },
    topLine: {
      position: "absolute",
      bottom: "129px",
      left: 0,
      right: 0,
    },
    textareaContainer: {
      position: "relative",
      flex: "60%",
      borderRadius: "8px",
      boxShadow: "0px 1px 3px rgb(3 0 71 / 9%)",
      background: theme.palette.primary.A200,
      border: "none",
      height: "calc(100vh - 69px)",
      maxHeight: "100vh",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 327px)",
        flex: "none",
      },
    },
    textareaNav: {
      boxShadow: "none",
      color: "inherit",
      borderRadius: "6px",
    },
    bottomNav: {
      position: "absolute",
      bottom: 0,
    },
    textarea: {
      overflowX: "auto",
      height: "77%",
      padding: "0 5px",
      "&::placeholder": {
        color: "#fff",
      },
      [theme.breakpoints.down("sm")]: {
        height: "63%",
      },
    },
    button: {
      color: theme.palette.primary.A400,
      marginRight: 5,
      textTransform: "capitalize",
      "&:active": {
        color: theme.palette.primary.main,
      },
    },
    buttonActive: {
      marginRight: 5,
      textTransform: "capitalize",
      color: theme.palette.primary.main,
    },
    iconButton: {
      marginRight: 5,
    },
    textareaNavBottomBtn: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
    emojiPIckerCloseButton: {
      width: 0,
      height: 0,
      left: 266,
      bottom: 215,
      zIndex: 3,
      position: "absolute",
      color: "rgb(29, 155, 240)",
      background: "#ffffff",
      "&:hover": {
        background: "#ffffff",
      },
    },
    emojiPicker: {},
    emojiPickerWrapper: {
      position: "relative",
    },
  })
);

const Home = (props: any) => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const params: Params = useParams();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const { postId } = params;

  const {
    authState: { user },
    tweetskyState: {
      loading: tweetPostLoading,
      drafts,
      historyData,
      draftLoading,
      scheduleLoading,
      scheduledPost,
      isFetching,
      hashtagList,
    },
    rssState: { results: rssFeeds },
    accountState: { accountId, results },
    onShowNotification,
    onAddNewAccount,
    onStartPostTweet,
    onStartPostTweetDraft,
    onUpdateDraft,
    onStartSchedulePost,
    onUpdateSchedulePost,
    onFetchHashtagStart,
  } = props;
  let isSubscriber = !!(
    user &&
    user.subscriptionId &&
    user.subscription &&
    user.subscription.status !== "canceled"
  );

  const oauthToken = query.get("oauth_token");
  const autoTime = query.get("autoTime");
  const oauthVerifier = query.get("oauth_verifier");
  const denied = query.get("denied");
  const newThread = query.get("newThread");
  const newThreadAfterQuickPost = query.get("newThreadAfterQuickPost");
  const alreadySavedDraft = window.location.pathname.includes("draft");
  const scheduleThread = window.location.pathname.includes("schedule");
  const rssThread = window.location.pathname.includes("rss");
  let localStorageTweetInput = localStorage.getItem("tweetInput");
  let localStorageTweetMedia = localStorage.getItem("tweetMedia");
  const inputRef = useRef<HTMLInputElement>(null);
  const quickImageUploadRef = useRef<HTMLInputElement>(null);

  const [schedulePostIsPosted, setSchedulePostIsPosted] = useState(false);
  const [scheduleAt, setScheduleAt] = useState<string | null>(null);
  const [tweetArray, setTweetArray] = useState<string[]>([]);
  const [tweetMedia, setTweetMedia] = useState<ITweetMedia[]>([]);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [input, setInput] = useState<string>("");
  const [numbering, setNumbering] = useState<boolean>(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openGifModal, setOpenGifModal] = useState<number | null>(null);
  const [displayEmojiPicker, setDisplayEmojiPicker] = useState(false);
  const [quoteId, setQuoteId] = useState<string | null>(null);
  const [displayQuoteModal, setDisplayQuoteModal] = useState<boolean>(false);
  const [openBackdrop, setOpenBackdrop] = useState<string | null>(null);
  const [hastagModal, setHastagModal] = useState<boolean>(false);
  const [isHashtagAccess, setHashtagAccess] = React.useState<boolean>(false);
  const [username, setUsername] = React.useState<string>("");
  const [avatar, setAvatar] = React.useState<string>("");
  const [image, setImage] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (accountId && results) {
      const activeAccount = results[accountId];
      setUsername(`${activeAccount?.username}`);
      setAvatar(activeAccount?.username?.charAt(0)?.toUpperCase());
      setImage(`https://unavatar.io/twitter/${activeAccount?.username}`);
    }
  }, [accountId, results]);

  React.useEffect(() => {
    if (
      user &&
      user.subscriptionType &&
      user.subscriptionStatus !== "canceled"
    ) {
      (async function anyName() {
        const access = await subscriptionAccess(user.subscriptionType);
        setHashtagAccess(access && access.hashtag);
      })();
    }
  }, [user]);

  useEffect(() => {
    if (postId) {
      if (alreadySavedDraft) {
        const selectedDraft = drafts && drafts[postId];
        if (selectedDraft) {
          setInput(selectedDraft ? selectedDraft.post.join("[...]") : "");
          setTweetMedia(
            selectedDraft && selectedDraft.media_URLs
              ? selectedDraft.media_URLs
              : []
          );
          setSelectedPost(selectedDraft && selectedDraft);
        } else {
          setInput("");
          setTweetMedia([]);
          setSelectedPost([]);
          history.push("/new-thread");
        }
      }
      if (scheduleThread) {
        const selectedSchedule = scheduledPost && scheduledPost[postId];
        if (selectedSchedule) {
          setInput(selectedSchedule ? selectedSchedule.post.join("[...]") : "");

          setSelectedPost(selectedSchedule && selectedSchedule);
          setScheduleAt(selectedSchedule ? selectedSchedule.scheduleAt : "");
          setSchedulePostIsPosted(
            selectedSchedule ? !!selectedSchedule.isPosted : false
          );
          setTweetMedia(
            selectedSchedule && selectedSchedule.media_URLs
              ? selectedSchedule.media_URLs
              : []
          );
        } else {
          setInput("");
          setSelectedPost(null);
          setScheduleAt("");
          setSchedulePostIsPosted(false);
          setTweetMedia([]);
          history.push("/new-thread");
        }
      }
      if (rssThread) {
        const rssFeed = rssFeeds && rssFeeds[postId];
        if (rssFeed) {
          // setting content part
          // let pubDate = rssFeed.pubDate ? formatDate(rssFeed.pubDate.substring(0, 25)) : '';
          let contents = RSSBodyParser(rssFeed);
          // const parser = new DOMParser();
          // const descContents = parser.parseFromString(testDescription, 'text/html');
          // contents = descContents.body.innerText;
          setInput(rssFeed ? `${rssFeed.title}\n\n${contents}` : "");

          setSelectedPost(rssFeed && rssFeed);
        } else {
          setInput("");
          setSelectedPost(null);
          history.push("/new-thread");
        }
      }
    }

    if (localStorageTweetInput && !postId) {
      setInput(localStorageTweetInput);
      if (localStorageTweetMedia) {
        setTweetMedia([...JSON.parse(localStorageTweetMedia)]);
      }
    }

    if (autoTime) {
      setTimeout(() => {
        query.delete("autoTime");
        history.replace({
          search: query.toString(),
        });
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    postId,
    drafts,
    autoTime,
    scheduledPost,
    scheduleThread,
    history,
    alreadySavedDraft,
    localStorageTweetInput,
    localStorageTweetMedia,
  ]);

  useEffect(() => {
    if (newThread || newThreadAfterQuickPost) {
      localStorage.removeItem("tweetInput");
      localStorage.removeItem("tweetMedia");
      localStorage.setItem("isPosted", "false");
      //if newThreadAfterQuickPost : only clear state after direct tweet post to avoid deleting from s3 bucket to later show in history
      if (selectedPost || newThreadAfterQuickPost) {
        setInput("");
        setTweetMedia([]);
        query.delete("newThread");
        query.delete("newThreadAfterQuickPost");
        history.replace({
          search: query.toString(),
        });
      } else {
        let mediaTweet =
          tweetMedia.length === 0
            ? localStorageTweetMedia
              ? JSON.parse(localStorageTweetMedia)
              : []
            : tweetMedia;

        if (mediaTweet.length === 0) {
          setInput("");
          setTweetMedia([]);
        } else {
          removeMedias(mediaTweet);
        }
        query.delete("newThread");
        history.replace({
          search: query.toString(),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tweetMedia, newThreadAfterQuickPost, newThread, input, selectedPost]);

  const removeMedias = async (mediaTweet: any) => {
    setOpenBackdrop("Removing medias");
    onShowNotification("info", "Removing all medias.");
    for (let index = 0; index < mediaTweet.length; index++) {
      if (mediaTweet[index] && mediaTweet[index].image) {
        for (
          let imageIndex = 0;
          imageIndex < mediaTweet[index].image.length;
          imageIndex++
        ) {
          await deleteImage(index, imageIndex, mediaTweet);
        }
      }
      if (mediaTweet[index] && mediaTweet[index].video.length > 0) {
        await deleteVideo(index, mediaTweet);
      }
      if (mediaTweet[index] && mediaTweet[index].gif.length > 0) {
        await deleteGif(index, mediaTweet);
      }
    }
    localStorage.removeItem("tweetInput");
    localStorage.removeItem("tweetMedia");
    setOpenBackdrop(null);
    setInput("");
    setTweetMedia([]);
  };
  const completeLogin = async () => {
    if (oauthToken && oauthVerifier) {
      onAddNewAccount({ oauthToken, oauthVerifier });
    }
  };

  useEffect(() => {
    if (oauthToken && user) {
      console.log("completeLogin");
      completeLogin();
    }
    if (denied) {
      query.delete("denied");
      history.replace({
        search: query.toString(),
      });
      onShowNotification("error", "Login cancelled by user");
    }
    if (user && oauthVerifier && oauthToken) {
      query.delete("oauth_token");
      query.delete("oauth_verifier");
      history.replace({
        search: query.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oauthToken, oauthVerifier, denied, user]);

  const handleMediaUpload = async (
    tweetIndex: number,
    event?: React.ChangeEvent<HTMLInputElement>,
    popupState?: any,
    gif?: any
  ) => {
    if (isSubscriber) {
      popupState && popupState.close();
      let mediaArray: any = tweetMedia;
      let file;
      onShowNotification("info", "Media uploading.");
      if (event) {
        file = event.target.files;
      }
      if (file && file.length > 0) {
        if (validateFile(file[0])) {
          //handle video if there is no image and gif
          if (validateSize(file[0])) {
            if (file[0].type.split("/")[0] === "video") {
              uploadVideo(mediaArray, tweetIndex, file[0]);
            }
            //handle image if there is no video and gif
            else if (file[0].type.split("/")[0] === "image") {
              if (file[0].type.split("/")[1] === "gif") {
                uploadGif(mediaArray, tweetIndex, file[0]);
              } else {
                uploadImage(mediaArray, tweetIndex, file[0]);
              }
            } else {
              onShowNotification(
                "error",
                "Either 1 video, 4 image or 1 gif allowed."
              );
            }
          } else {
            onShowNotification("error", "Media file too large.");
          }
        } else {
          onShowNotification("error", "Invalid file format.");
          return;
        }
      }
      if (gif) {
        //handle gif if there is no image and video
        uploadGif(mediaArray, tweetIndex, null, gif);
      }
    } else {
      setOpenGifModal(null);
      onShowNotification(
        "error",
        "This feature is only for subscribed user. Subscribe now"
      );
    }
  };

  const uploadVideo = async (
    mediaArray: any,
    tweetIndex: number,
    videoFile: File
  ) => {
    if (
      (!mediaArray[tweetIndex] ||
        !mediaArray[tweetIndex].gif ||
        mediaArray[tweetIndex].gif.length === 0) &&
      (!mediaArray[tweetIndex] ||
        !mediaArray[tweetIndex].image ||
        mediaArray[tweetIndex].image.length === 0) &&
      (!mediaArray[tweetIndex] ||
        !mediaArray[tweetIndex].video ||
        mediaArray[tweetIndex].video.length === 0)
    ) {
      if (validateSize(videoFile)) {
        const uploadResponse: { url?: any; key?: String } | undefined =
          await multiPartupload(videoFile);

        if (uploadResponse) {
          let obj = {
            ...uploadResponse,
            size: videoFile.size,
            type: videoFile.type,
            media_category: "tweet_video",
          };

          mediaArray[tweetIndex]
            ? (mediaArray[tweetIndex].video = [obj])
            : (mediaArray[tweetIndex] = {
                ...mediaArray[tweetIndex],
                video: [obj],
                image: [],
                gif: [],
              });
          setTweetMedia([...mediaArray]);
          saveInputInLocalStorage(input, mediaArray);
          onShowNotification("success", "Video uploaded successfully.");
        }
      } else {
        onShowNotification("error", "File too large.");
      }
    } else {
      onShowNotification("error", "Either 1 video, 4 image or 1 gif allowed.");
    }
  };

  const uploadGif = async (
    mediaArray: any,
    tweetIndex: number,
    gifFile?: File | null,
    gif?: any
  ) => {
    if (
      (!mediaArray[tweetIndex] ||
        !mediaArray[tweetIndex].video ||
        mediaArray[tweetIndex].video.length === 0) &&
      (!mediaArray[tweetIndex] ||
        !mediaArray[tweetIndex].image ||
        mediaArray[tweetIndex].image.length === 0) &&
      (!mediaArray[tweetIndex] ||
        !mediaArray[tweetIndex].gif ||
        mediaArray[tweetIndex].gif.length === 0)
    ) {
      if (gifFile) {
        if (validateSize(gifFile)) {
          const uploadResponse: { url?: any; key?: String } | undefined =
            await multiPartupload(gifFile);

          if (uploadResponse) {
            let obj = {
              ...uploadResponse,
              size: gifFile.size,
              type: gifFile.type,
              media_category: "tweet_gif",
            };
            mediaArray[tweetIndex] && mediaArray[tweetIndex].gif
              ? mediaArray[tweetIndex].gif.push(obj)
              : (mediaArray[tweetIndex] = {
                  ...mediaArray[tweetIndex],
                  image: [],
                  video: [],
                  gif: [obj],
                });
            setTweetMedia([...mediaArray]);
            saveInputInLocalStorage(input, mediaArray);
            onShowNotification("success", "Gif uploaded successfully.");
          }
        } else {
          onShowNotification("error", "File too large.");
        }
      } else {
        mediaArray[tweetIndex] = {
          ...mediaArray[tweetIndex],
          image: [],
          video: [],
          gif: [
            {
              url: gif.images.original.url,
              size: gif.images.original.size,
              type: "image/gif",
              media_category: "tweet_gif",
            },
          ],
        };
        setTweetMedia([...mediaArray]);
        saveInputInLocalStorage(input, mediaArray);
        onShowNotification("success", "Gif uploaded successfully.");
        setGifModal();
      }
    } else {
      onShowNotification("error", "Either 1 video, 4 image or 1 gif allowed.");
      setOpenGifModal(null);
    }
  };

  const uploadImage = async (
    mediaArray: any,
    tweetIndex: number,
    imageFile: File
  ) => {
    if (
      (!mediaArray[tweetIndex] ||
        !mediaArray[tweetIndex].video ||
        mediaArray[tweetIndex].video.length === 0) &&
      (!mediaArray[tweetIndex] ||
        !mediaArray[tweetIndex].gif ||
        mediaArray[tweetIndex].gif.length === 0) &&
      (!mediaArray[tweetIndex] ||
        !mediaArray[tweetIndex].image ||
        mediaArray[tweetIndex].image.length !== 4)
    ) {
      if (validateSize(imageFile)) {
        const uploadResponse: { url?: any; key?: String } | undefined =
          await multiPartupload(imageFile);
        if (uploadResponse) {
          let obj = {
            ...uploadResponse,
            size: imageFile.size,
            type: imageFile.type,
            media_category: "tweet_image",
          };
          mediaArray[tweetIndex] && mediaArray[tweetIndex].image
            ? mediaArray[tweetIndex].image.push(obj)
            : (mediaArray[tweetIndex] = {
                ...mediaArray[tweetIndex],
                image: [obj],
                video: [],
                gif: [],
              });
          setTweetMedia([...mediaArray]);
          saveInputInLocalStorage(input, mediaArray);
          onShowNotification("success", "Image uploaded successfully.");
        }
      } else {
        onShowNotification("error", "File too large.");
      }
    } else {
      onShowNotification("error", "Either 1 video, 4 image or 1 gif allowed.");
    }
  };

  const deleteImage = async (
    tweetIndex: number,
    imageIndex: number,
    mediaArray: any
  ) => {
    if (mediaArray[tweetIndex]) {
      onShowNotification("info", "Removing media");
      const { status } = await axios.post(`${base_Url}remove/s3/media`, {
        fileName: mediaArray[tweetIndex].image[imageIndex].key,
        fileType: mediaArray[tweetIndex].image[imageIndex].type,
      });

      if (status === 200) {
        onShowNotification("success", "Media deleted.");
        mediaArray[tweetIndex].image.splice(imageIndex, 1);
        saveInputInLocalStorage(input, mediaArray);
        setTweetMedia([...mediaArray]);
      } else {
        onShowNotification("error", "Something went wrong.");
      }
    }
  };

  const deleteVideo = async (tweetIndex: number, mediaArray: any) => {
    if (mediaArray[tweetIndex]) {
      onShowNotification("info", "Removing video");
      const { data } = await axios.post(`${base_Url}remove/s3/media`, {
        fileName: mediaArray[tweetIndex].video[0].key,
        fileType: mediaArray[tweetIndex].video[0].type,
      });
      if (data.status === 200) {
        onShowNotification("success", "Video deleted.");
        mediaArray[tweetIndex].video.splice(0, 1);
        saveInputInLocalStorage(input, mediaArray);
        setTweetMedia([...mediaArray]);
      } else {
        onShowNotification("error", "Something went wrong.");
      }
    }
  };

  const deleteGif = async (tweetIndex: number, mediaArray: any) => {
    if (mediaArray[tweetIndex]) {
      if (mediaArray[tweetIndex].gif && mediaArray[tweetIndex].gif[0].key) {
        onShowNotification("info", "Removing media");
        const { status } = await axios.post(`${base_Url}remove/s3/media`, {
          fileName: mediaArray[tweetIndex].gif[0].key,
          fileType: mediaArray[tweetIndex].gif[0].type,
        });

        if (status === 200) {
          onShowNotification("success", "Media deleted.");
          onShowNotification("info", "Removing Gif");
          mediaArray[tweetIndex].gif.splice(0, 1);
          saveInputInLocalStorage(input, mediaArray);
          setTweetMedia([...mediaArray]);
        } else {
          onShowNotification("error", "Something went wrong.");
        }
      } else {
        onShowNotification("info", "Removing Gif");
        mediaArray[tweetIndex].gif.splice(0, 1);
        saveInputInLocalStorage(input, mediaArray);
        setTweetMedia([...mediaArray]);
      }
    }
  };

  //insert [...] at cursor position in inputState to split tweet from there
  function insertAtCursor() {
    let cursorPosition = inputRef.current
      ? inputRef.current.selectionStart
      : undefined;

    setInput(
      input.slice(0, cursorPosition ? cursorPosition : undefined) +
        "[...]" +
        (cursorPosition ? input.slice(cursorPosition) : "")
    );
  }

  let textArray: string[] = [];
  function splitBasedOnLinks(text: string) {
    let content: string = "";
    let contentLength: number = 0;
    let linkLength = 23;

    // console.log('text: ', text);
    if (text.includes("https://") || text.includes("http://")) {
      text.split(" ").map((x, index) => {
        if (x.startsWith("https://") || x.startsWith("http://")) {
          if (contentLength + linkLength <= 280) {
            // check length of content less than 280 which is one tweet thread
            if (index === text.split(" ").length - 1) {
              // check last word not to have space suffix after split with ' '
              content += x;
              contentLength += linkLength;
            } else {
              content += x + " ";
              contentLength += linkLength + 1;
            }
          } else if (contentLength + linkLength > 280) {
            // if content length greater than 280 split to next tweet thread
            textArray.push(content);
            content = x + " ";
            contentLength = linkLength + 1;
          } else {
            console.log(`case not handled for text: ${x}`);
          }
        } else {
          if (contentLength + x.length <= 280) {
            // check length of content less than 280 which is one tweet thread
            if (index === text.split(" ").length - 1) {
              // check last word not to have space suffix after split with ' '
              content += x;
              contentLength += x.length;
            } else {
              content += x + " ";
              contentLength += x.length + 1;
            }
          } else if (contentLength + x.length > 280) {
            // if content length greater than 280 split to next tweet thread
            textArray.push(content);
            content = x + " ";
            contentLength = x.length + 1;
          } else {
            console.log(`case not handled for text: ${x}`);
          }
        }
        return null;
      });
      if (content && contentLength) {
        textArray.push(content.trim());
      }
    } else {
      const tweets = text.match(/[\s\S]{1,280}/g);

      while (tweets && tweets.length > 0) {
        let chunk = tweets.splice(0, 1);
        textArray.push(chunk.join(""));
      }
    }
  }

  //split input at certain length "text" => ["te","xt"](split at 2 length)
  // let splitedTweetArray: string[] = [];
  // function splitBasedOnLength(text: string) {
  //   //split on 269 length to provide space at end for numbering tweets
  //   var tweets = text.match(/[\s\S]{1,280}/g);
  //
  //   while (tweets && tweets.length > 0) {
  //     let chunk = tweets.splice(0, 1);
  //     splitedTweetArray.push(chunk.join(''));
  //   }
  // }

  const handleChange = (
    e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let text: string = e ? e.target.value : input;
    let splittedArray;

    setInput(text);
    saveInputInLocalStorage(text);
    splittedArray = text.split("[...]");

    // splittedArray.forEach((text) => splitBasedOnLength(text));
    splittedArray.forEach((text) => splitBasedOnLinks(text));
    // console.log('textArray: ', textArray);
    if (numbering) {
      //Add number at the end of each string
      let tweetCounts = 0;
      let tweetArrayWithNumbering: string[] = [];
      textArray.forEach((text) => {
        tweetCounts = tweetCounts + 1;

        let numberingTobeAdded = `${tweetCounts}/${textArray.length}`;
        let finalStringInArray = `${text}\n${numberingTobeAdded}`;
        tweetArrayWithNumbering.push(finalStringInArray);
      });
      setTweetArray([...tweetArrayWithNumbering]);
    } else {
      setTweetArray([...textArray]);
    }
  };

  const saveInputInLocalStorage = (text?: string, media?: any[]) => {
    if (!postId) {
      text && localStorage.setItem("tweetInput", text);
      media && localStorage.setItem("tweetMedia", JSON.stringify(tweetMedia));
    }
  };

  const saveAsDraft = () => {
    if (user) {
      if (!draftLoading) {
        let draftArray = Object.keys(drafts);
        if (
          (!user.subscriptionId && draftArray.length <= 1) ||
          (user.subscriptionStatus === "canceled" && draftArray.length <= 1) ||
          user.subscriptionStatus !== "canceled"
        ) {
          if (input.length !== 0) {
            // create new draft if (no postId) and if (postId available and rssThread true)
            if (postId && !rssThread) {
              onUpdateDraft(postId, tweetArray, tweetMedia, history);
            } else {
              onStartPostTweetDraft(tweetArray, tweetMedia, history);
            }
          } else {
            onShowNotification("error", "Draft cannot be empty.");
          }
        } else {
          onShowNotification("error", "You have reached your free limit.");
        }
      }
    } else {
      onShowNotification("error", "User not logged in.");
    }
  };

  const onTweet = () => {
    if (!tweetPostLoading) {
      let historyArray = Object.keys(historyData);
      if (!user) {
        onShowNotification("error", "User not logged in.");
        return;
      }
      if (
        (!user.subscriptionId && historyArray.length < 5) ||
        (user.subscriptionStatus === "canceled" && historyArray.length <= 1) ||
        user.subscriptionStatus !== "canceled"
      ) {
        if (tweetArray.length === 0) {
          onShowNotification("error", "Tweet cannot be empty.");
        } else {
          onStartPostTweet(tweetArray, tweetMedia, history);
        }
      } else {
        onShowNotification("error", "You have reached your free limit.");
      }
    }
  };

  const onScheduleTweet = (date: string) => {
    if (isSubscriber) {
      if (!scheduleLoading && input.length !== 0) {
        if (!scheduleThread) {
          onStartSchedulePost({
            tweetArray,
            scheduleAt: date,
            tweetMedia,
            filters:
              selectedPost && selectedPost.filters
                ? selectedPost.filters
                : null,
            history,
          });
        } else {
          onUpdateSchedulePost({
            post: tweetArray,
            scheduleAt: date,
            postId,
            tweetMedia,
            history,
          });
        }
      } else {
        onShowNotification("error", "Tweet cannot be empty.");
      }
    } else {
      onShowNotification(
        "error",
        "This feature is only for subscribed user. Subscribe now."
      );
    }
  };

  const setGifModal = (tweetIndex?: number) => {
    if (typeof tweetIndex === "number") {
      setOpenGifModal(tweetIndex);
    } else {
      setOpenGifModal(null);
    }
  };

  const setScheduleModal = () => {
    setOpenScheduleModal(!openScheduleModal);
  };

  useEffect(() => {
    if (input.length > 0) {
      handleChange();
    } else {
      setTweetArray([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numbering, input]);

  const onEmojiClick = (event: any, emojiObject: any) => {
    let cursorPosition = inputRef.current
      ? inputRef.current.selectionStart
      : undefined;
    let firstPart = input.slice(
      0,
      cursorPosition ? cursorPosition : input.length
    );
    let saveIndex = firstPart.length + emojiObject.emoji.length;

    setInput(
      firstPart +
        emojiObject.emoji +
        (cursorPosition ? input.slice(cursorPosition) : "")
    );

    //again add cursor to wherever add emoji so that multiple emoji can be added at same place
    if (inputRef.current) {
      window.setTimeout(function () {
        inputRef?.current?.setSelectionRange(saveIndex, saveIndex);
      }, 0);
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (isComponentVisible) {
      setDisplayEmojiPicker(true);
    } else {
      setDisplayEmojiPicker(false);
    }
  }, [isComponentVisible]);

  const changeQuoteModal = () => {
    setDisplayQuoteModal(!displayQuoteModal);
  };

  //directly multi part upload file to s3 bucket
  const multiPartupload = async (file: any) => {
    try {
      if (s3AccessKey && s3SecretAccessKey) {
        let fileExtension = file.name.split(".")[1];
        let fileNameInS3 = `${makeId()}.${fileExtension}`;
        setOpenBackdrop("Uploading media. Please wait.");
        const target = {
          Bucket: bucketName,
          Key: fileNameInS3,
          Body: file,
          ACL: "public-read",
        };
        const parallelUploads3 = new Upload({
          client: new S3({
            region: "us-east-1",
            credentials: {
              accessKeyId: s3AccessKey,
              secretAccessKey: s3SecretAccessKey,
            },
          }),
          leavePartsOnError: false, // optional manually handle dropped parts
          params: target,
        });
        parallelUploads3.on("httpUploadProgress", (progress: any) => {});

        const response = await parallelUploads3.done();

        if (response.$metadata.httpStatusCode === 200) {
          let mediaURLs = {
            url: `${bucketUrl}${fileNameInS3}`,
            key: fileNameInS3,
          };
          setOpenBackdrop(null);
          return mediaURLs;
        } else {
          onShowNotification("error", "Something went wrong.");
          setOpenBackdrop(null);
        }
      }
    } catch (error) {
      console.log("error:", error);
      onShowNotification("error", "Something went wrong.");
      setOpenBackdrop(null);
    }
  };

  return (
    <>
      <SimpleBackdrop open={openBackdrop} />
      <HashtagGeneratorModal
        hastagModal={hastagModal}
        isSubscriber={isSubscriber}
        setHastagModal={setHastagModal}
        onShowNotification={onShowNotification}
        onFetchHashtagStart={onFetchHashtagStart}
        isFetching={isFetching}
        hashtagList={hashtagList}
      />
      <ScheduleModal
        openScheduleModal={openScheduleModal}
        setScheduleModal={setScheduleModal}
        onScheduleTweet={onScheduleTweet}
        scheduleLoading={scheduleLoading}
        setScheduleAt={setScheduleAt}
        scheduleAt={scheduleAt}
        autoTime={autoTime}
        scheduleThread={scheduleThread}
        schedulePostIsPosted={schedulePostIsPosted}
        onShowNotification={onShowNotification}
      />
      <QuoteTweetModal
        quoteId={quoteId}
        setQuoteId={setQuoteId}
        displayQuoteModal={displayQuoteModal}
        changeQuoteModal={changeQuoteModal}
        onShowNotification={onShowNotification}
      />
      <GifContainer
        setGifModal={setGifModal}
        openGifModal={openGifModal}
        handleMediaUpload={handleMediaUpload}
      />

      <Box className={classes.root} color="inherit">
        <Box className={classes.textareaContainer} color="inherit">
          <AppBar
            position="static"
            className={classes.textareaNav}
            color="inherit"
          >
            <Toolbar>
              <Button
                className={classes.button}
                startIcon={<CallSplitIcon />}
                onClick={() => insertAtCursor()}
              >
                Split
              </Button>
              <Button
                className={numbering ? classes.buttonActive : classes.button}
                startIcon={<FormatListNumberedIcon />}
                onClick={() => setNumbering(!numbering)}
              >
                Numbering
              </Button>
              <Button
                className={numbering ? classes.buttonActive : classes.button}
                startIcon={<HiOutlineHashtag />}
                onClick={() => {
                  if (isSubscriber) {
                    if (isHashtagAccess) {
                      setHastagModal((prev) => !prev);
                    } else {
                      onShowNotification(
                        "error",
                        `Your subscription don't have this access. Upgrade to higher tier subscription plan.`
                      );
                    }
                  } else {
                    onShowNotification(
                      "error",
                      "This feature is only for subscribed user. Subscribe now."
                    );
                  }
                }}
              >
                Hashtag
              </Button>
            </Toolbar>
            <Divider />
          </AppBar>
          <TextField
            inputRef={inputRef}
            value={input}
            onChange={handleChange}
            placeholder="Start typing..."
            className={classes.textarea}
            multiline
            minRows={10}
            fullWidth
            InputProps={{
              disableUnderline: true,
              style: {
                paddingRight: "8px",
              },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: input }}></div>
          </TextField>
          <Divider className={classes.topLine} />
          <AppBar
            position="static"
            className={clsx(classes.textareaNav, classes.bottomNav)}
            color="inherit"
          >
            {displayEmojiPicker && isComponentVisible && (
              <div className={classes.emojiPickerWrapper} ref={ref}>
                <IconButton
                  className={classes.emojiPIckerCloseButton}
                  disableRipple={true}
                  onClick={() => setIsComponentVisible(false)}
                >
                  <CancelIcon />
                </IconButton>
                <Picker
                  onEmojiClick={onEmojiClick}
                  pickerStyle={{
                    position: "absolute",
                    top: "-228px",
                    zIndex: "2",
                    height: "229px",
                  }}
                />
              </div>
            )}
            <Toolbar>
              <Box className={classes.textareaNavBottomBtn}>
                <input
                  ref={quickImageUploadRef}
                  type="file"
                  className={classes.hidden}
                  onChange={(e) => {
                    handleMediaUpload(0, e);
                  }}
                />
                <IconButton
                  onClick={() =>
                    quickImageUploadRef.current &&
                    quickImageUploadRef.current.click()
                  }
                  color="primary"
                  className={classes.iconButton}
                >
                  <InsertPhotoIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  onClick={() => setGifModal(0)}
                >
                  <GifOutlinedIcon />
                </IconButton>

                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  onClick={() => {
                    setIsComponentVisible(true);
                    setDisplayEmojiPicker(!displayEmojiPicker);
                  }}
                >
                  <SentimentSatisfiedOutlinedIcon />
                </IconButton>
              </Box>

              <IconButton onClick={() => setScheduleModal()} color="primary">
                <Tooltip title={"Schedule your tweet."}>
                  <QueryBuilderIcon />
                </Tooltip>
              </IconButton>
            </Toolbar>
            <Toolbar>
              <Box className={classes.textareaNavBottomBtn}>
                {!scheduleThread &&
                  (!alreadySavedDraft ? (
                    <Button
                      id="save"
                      variant="contained"
                      color="primary"
                      onClick={() => saveAsDraft()}
                      disabled={draftLoading}
                    >
                      {draftLoading ? "saving..." : "Save as draft"}
                    </Button>
                  ) : (
                    <Button
                      id="update"
                      variant="contained"
                      color="primary"
                      onClick={() => saveAsDraft()}
                      disabled={draftLoading}
                    >
                      {draftLoading ? "updating..." : "Update draft"}
                    </Button>
                  ))}
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={() => onTweet()}
                disabled={tweetPostLoading}
              >
                {tweetPostLoading ? "Tweeting..." : "Tweet"}
              </Button>
            </Toolbar>
            <Divider />
          </AppBar>
        </Box>
        <TweetContainer
          username={username}
          avatar={avatar}
          image={image}
          tweetArray={tweetArray}
          tweetMedia={tweetMedia}
          handleMediaUpload={handleMediaUpload}
          deleteImage={deleteImage}
          deleteVideo={deleteVideo}
          deleteGif={deleteGif}
          user={user}
          setGifModal={setGifModal}
          changeQuoteModal={changeQuoteModal}
          quoteId={quoteId}
          setOpenBackdrop={setOpenBackdrop}
        />
      </Box>
    </>
  );
};

export default container(Home);

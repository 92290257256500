import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Container, Typography } from "@material-ui/core";
import bottomFrame from "src/assets/bottomFrame.png";
import hero from "src/assets/hero.png";
import topFrame from "src/assets/topFrame.png";
// import heroBg from 'src/assets/heroBg.png';

const useStyles = makeStyles((theme) => ({
  heroSection: {
    // background: `url(${heroBg}) no-repeat center`,
    height: "600px",
    backgroundSize: "cover",
    position: "relative",
    background: "linear-gradient(90deg, #2774AE 0%, #002E5D 50%, #002E5D 100%)",
    [theme.breakpoints.down(1100)]: {
      height: "560px",
    },
    [theme.breakpoints.down(440)]: {
      height: "590px",
    },
  },

  button: {
    color: "#000",
    background: "#fff",
    padding: "10px 14px",
    marginTop: 20,
    "&:hover": {
      background: "rgb(215 215 215)",
    },
  },
  heroBg: {
    width: "100%",
    position: "absolute",
    height: "100%",
    [theme.breakpoints.down(1200)]: {
      display: "none",
    },
  },
  topFrame: {
    position: "absolute",
    top: "15px",
    right: "15px",
    [theme.breakpoints.down(1200)]: {
      maxWidth: "50px",
    },
  },

  bottomFrame: {
    position: "absolute",
    bottom: "30px",
    left: "12px",
    [theme.breakpoints.down(1200)]: {
      maxWidth: "50px",
      bottom: "22px",
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-around",
    position: "relative",
    zIndex: 1,
    height: "100%",
    "& > :first-child": {
      width: "45%",
    },
    "& > :nth-of-type(2)": {
      width: "32%",
    },
    [theme.breakpoints.down(900)]: { "& > :first-child": { width: "90%" } },
  },
  header: {
    color: "#fff",
    fontSize: 37,
    fontWeight: 500,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: "25px",
    },
    [theme.breakpoints.down(420)]: {
      lineHeight: "48px",
    },
    [theme.breakpoints.down(1200)]: {
      fontSize: "2.5rem",
    },
  },
  descriptions: {
    color: "#fff",
    fontSize: 20,
    marginTop: 10,
    fontWeight: 300,
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0",
    },
    [theme.breakpoints.down(420)]: {
      margin: "10px 0",
    },
  },
  list: {
    margin: "10px 0",
    listStyle: "dotted",
    color: "#fff",
    "& >*": {
      paddingLeft: 0,
    },
  },
  heroImg: {
    maxWidth: "100%",
    [theme.breakpoints.down(900)]: { display: "none" },
    [theme.breakpoints.down(1350)]: {
      marginTop: "125px",
      maxHeight: "350px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
      maxHeight: "350px",
    },
  },
  riskfree: {
    fontSize: "18px",
    color: "#fff",
    marginTop: "30px",
    fontWeight: 500,
  },
}));

const features = [
  {
    name: "Schedule Posts",
  },
  {
    name: "Tweet History",
  },
  {
    name: "Share Image and Videos",
  },
  {
    name: "RSS Feeds for Content Ideas",
  },
  {
    name: "Tweet Analytics",
  },
];

export const Hero = (props: any) => {
  const classes = useStyles();
  const { isAuthenticated } = props;

  return (
    <>
      <Box className={classes.heroSection}>
        {/*<img className={classes.heroBg} src={heroBg} alt="hero" />*/}
        <img className={classes.topFrame} src={topFrame} alt="hero" />
        <img className={classes.bottomFrame} src={bottomFrame} alt="hero" />

        <Container maxWidth={"lg"} className={classes.content}>
          <Box>
            <Typography className={classes.header} component="h1">
              Tweet better, Engage more
            </Typography>
            <Typography className={classes.descriptions}>
              Don't just tweet more, tweet better. Tweetsky is the best twitter
              thread tool that helps you make more engaging tweets without all
              the extra work. With unlimited threads, RSS to tweet, powerful
              analytics, hashtag generation, and more, you'll be able to take
              your social media marketing to the next level.
            </Typography>
            <Button
              className={classes.button}
              component={Link}
              to={isAuthenticated ? "/new-thread" : "/signup"}
            >
              Start Free Trial
            </Button>
            <Typography className={classes.riskfree}>
              Risk Free, Cancel Anytime
            </Typography>
          </Box>
          <Box style={{ width: "38%" }}>
            <img className={classes.heroImg} src={hero} alt="heroImg" />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Hero;

import { RootState } from 'src/store';
import { connect } from 'react-redux';
import { subscribeUser, updateSubscription } from 'src/store/subscription/subscription.actions';

const mapStateToProps = (state: RootState) => ({
  authState: state.auth
});

const mapDispatchToProps = (dispatch: any) => ({
  onSubscribeUser: (user: any) => dispatch(subscribeUser(user)),
  onUpdateSubscription: (user: any) => dispatch(updateSubscription(user))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;

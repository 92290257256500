import { Box, Container, Grid } from "@material-ui/core";
import React from "react";
import Footer from "../LandingPage/components/footer";

function Privacy() {
  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box marginTop={10}>
              <h1
                style={{
                  marginTop: "24.0pt",
                  marginRight: "0in",
                  marginBottom: "6.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "27px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "31px", lineHeight: "115%" }}>
                    Tweetsky Website Privacy Policy
                  </span>
                </strong>
              </h1>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    1. Introduction
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Welcome to <strong>Adzdata LLC</strong>.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Adzdata LLC (“us”, “we”, or “our”) operates http://tweetsky.com
                (hereinafter referred to as <strong>“Service”</strong>).
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Our Privacy Policy governs your visit to http://tweetsky.com,
                and explains how we collect, safeguard and disclose information
                that results from your use of our Service.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We use your data to provide and improve Service. By using
                Service, you agree to the collection and use of information in
                accordance with this policy. Unless otherwise defined in this
                Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Our Terms and Conditions (<strong>“Terms”</strong>) govern all
                use of our Service and together with the Privacy Policy
                constitutes your agreement with us (<strong>“agreement”</strong>
                ).
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    2. Definitions
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>SERVICE</strong> means the http://tweetsky.com website
                operated by Adzdata LLC.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>PERSONAL DATA</strong> means data about a living
                individual who can be identified from those data (or from those
                and other information either in our possession or likely to come
                into our possession).
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>USAGE DATA</strong> is data collected automatically
                either generated by the use of Service or from Service
                infrastructure itself (for example, the duration of a page
                visit).
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>COOKIES</strong> are small files stored on your device
                (computer or mobile device).
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>DATA CONTROLLER</strong> means a natural or legal person
                who (either alone or jointly or in common with other persons)
                determines the purposes for which and the manner in which any
                personal data are, or are to be, processed. For the purpose of
                this Privacy Policy, we are a Data Controller of your data.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means
                any natural or legal person who processes the data on behalf of
                the Data Controller. We may use the services of various Service
                Providers in order to process your data more effectively.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>DATA SUBJECT</strong> is any living individual who is
                the subject of Personal Data.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>THE USER</strong> is the individual using our Service.
                The User corresponds to the Data Subject, who is the subject of
                Personal Data.
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    3. Information Collection and Use
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    4. Types of Data Collected
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>Personal Data</strong>
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you (<strong>“Personal Data”</strong>).
                Personally identifiable information may include, but is not
                limited to:
              </p>
              <ol
                start={1}
                style={{ marginBottom: "0in", marginTop: "0in" }}
                type="1"
              >
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                    marginTop: "14.0pt",
                  }}
                >
                  Email address
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  First name and last name
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  Phone number
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  Address, State, Province, ZIP/Postal code, City
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: "14.0pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  Cookies and Usage Data
                </li>
              </ol>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                We may use your Personal Data to contact you with newsletters,
                marketing or promotional materials and other information that
                may be of interest to you. You may opt out of receiving any, or
                all, of these communications from us by following the
                unsubscribe link.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                <strong>Usage Data</strong>
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                We may also collect information that your browser sends whenever
                you visit our Service or when you access Service by or through a
                mobile device (“Usage Data”).
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                This Usage Data may include information such as your computer's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                When you access Service with a mobile device, this Usage Data
                may include information such as the type of mobile device you
                use, your mobile device unique ID, the IP address of your mobile
                device, your mobile operating system, the type of mobile
                Internet browser you use, unique device identifiers and other
                diagnostic data.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                <strong>Tracking Cookies Data</strong>
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyze our
                Service.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                Examples of Cookies we use:
              </p>
              <ol
                start={1}
                style={{ marginBottom: "0in", marginTop: "0in" }}
                type="1"
              >
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                    marginTop: "14.0pt",
                  }}
                >
                  <strong>Session Cookies:</strong> We use Session Cookies to
                  operate our Service.
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  <strong>Preference Cookies:</strong> We use Preference Cookies
                  to remember your preferences and various settings.
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  <strong>Security Cookies:</strong> We use Security Cookies for
                  security purposes.
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: "14.0pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  <strong>Advertising Cookies:</strong> Advertising Cookies are
                  used to serve you with advertisements that may be relevant to
                  you and your interests.
                </li>
              </ol>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    5. Use of Data
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Adzdata LLC uses the collected data for various purposes:
              </p>
              <ul style={{ listStyleType: "undefined", marginLeft: "0in" }}>
                <li>to provide and maintain our Service;</li>
                <li>to notify you about changes to our Service;</li>
                <li>
                  to allow you to participate in interactive features of our
                  Service when you choose to do so;
                </li>
                <li>to provide customer support;</li>
                <li>
                  to gather analysis or valuable information so that we can
                  improve our Service;
                </li>
                <li>to monitor the usage of our Service;</li>
                <li>to detect, prevent and address technical issues;</li>
                <li>to fulfill any other purpose for which you provide it;</li>
                <li>
                  to carry out our obligations and enforce our rights arising
                  from any contracts entered into between you and us, including
                  for billing and collection;
                </li>
                <li>
                  to provide you with notices about your account and/or
                  subscription, including expiration and renewal notices,
                  email-instructions, etc.;
                </li>
                <li>
                  to provide you with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless you have opted not to
                  receive such information;
                </li>
                <li>
                  in any other way we may describe when you provide the
                  information;
                </li>
                <li>for any other purpose with your consent.</li>
              </ul>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    6. Retention of Data
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We will retain your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period, except
                when this data is used to strengthen the security or to improve
                the functionality of our Service, or we are legally obligated to
                retain this data for longer time periods.
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    7. Transfer of Data
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Your information, including Personal Data, may be transferred to
                – and maintained on – computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                If you are located outside the United States and choose to
                provide information to us, please note that we transfer the
                data, including Personal Data, to the United States and process
                it there.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Adzdata LLC will take all the steps reasonably necessary to
                ensure that your data is treated securely and in accordance with
                this Privacy Policy and no transfer of your Personal Data will
                take place to an organization or a country unless there are
                adequate controls in place including the security of your data
                and other personal information.
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    8. Disclosure of Data
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We may disclose personal information that we collect, or you
                provide:
              </p>
              <ol
                start={1}
                style={{ marginBottom: "0in", marginTop: "0in" }}
                type="1"
              >
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                    marginTop: "14.0pt",
                  }}
                >
                  <strong>
                    Disclosure for Law Enforcement.
                    <br />
                    &nbsp;
                  </strong>
                  Under certain circumstances, we may be required to disclose
                  your Personal Data if required to do so by law or in response
                  to valid requests by public authorities.
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  <strong>
                    Business Transaction.
                    <br />
                    &nbsp;
                  </strong>
                  If we or our subsidiaries are involved in a merger,
                  acquisition or asset sale, your Personal Data may be
                  transferred.
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  <strong>
                    Other cases. We may disclose your information also:
                    <br />
                    &nbsp;
                  </strong>
                  <ol
                    start={1}
                    style={{ marginBottom: "0in", marginTop: "0in" }}
                    type="1"
                  >
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      to our subsidiaries and affiliates;
                    </li>
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      to contractors, service providers, and other third parties
                      we use to support our business;
                    </li>
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      to fulfill the purpose for which you provide it;
                    </li>
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      for the purpose of including your company’s logo on our
                      website;
                    </li>
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      for any other purpose disclosed by us when you provide the
                      information;
                    </li>
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: "14.0pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      if we believe disclosure is necessary or appropriate to
                      protect the rights, property, or safety of the Company,
                      our customers, or others.
                    </li>
                  </ol>
                </li>
              </ol>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    9. Security of Data
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    10. Your Data Protection Rights Under General Data
                    Protection Regulation (GDPR)
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                If you are a resident of the European Union (EU) and European
                Economic Area (EEA), you have certain data protection rights,
                covered by GDPR. – See more at
                https://eur-lex.europa.eu/eli/reg/2016/679/oj
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We aim to take reasonable steps to allow you to correct, amend,
                delete, or limit the use of your Personal Data.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                If you wish to be informed what Personal Data we hold about you
                and if you want it to be removed from our systems, please email
                us at support@tweetsky.com.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                In certain circumstances, you have the following data protection
                rights:
              </p>
              <ol
                start={1}
                style={{ marginBottom: "0in", marginTop: "0in" }}
                type="1"
              >
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                    marginTop: "14.0pt",
                  }}
                >
                  the right to access, update or to delete the information we
                  have on you;
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  the right of rectification. You have the right to have your
                  information rectified if that information is inaccurate or
                  incomplete;
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  the right to object. You have the right to object to our
                  processing of your Personal Data;
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  the right of restriction. You have the right to request that
                  we restrict the processing of your personal information;
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  the right to data portability. You have the right to be
                  provided with a copy of your Personal Data in a structured,
                  machine-readable and commonly used format;
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: "14.0pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  the right to withdraw consent. You also have the right to
                  withdraw your consent at any time where we rely on your
                  consent to process your personal information;
                </li>
              </ol>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                Please note that we may ask you to verify your identity before
                responding to such requests. Please note, we may not be able to
                provide Service without some necessary data.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                You have the right to complain to a Data Protection Authority
                about our collection and use of your Personal Data. For more
                information, please contact your local data protection authority
                in the European Economic Area (EEA).
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    11. Your Data Protection Rights under the California Privacy
                    Protection Act (CalOPPA)
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                CalOPPA is the first state law in the nation to require
                commercial websites and online services to post a privacy
                policy. The law’s reach stretches well beyond California to
                require a person or company in the United States (and
                conceivable the world) that operates websites collecting
                personally identifiable information from California consumers to
                post a conspicuous privacy policy on its website stating exactly
                the information being collected and those individuals with whom
                it is being shared, and to comply with this policy. – See more
                at:
                https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                According to CalOPPA we agree to the following:
              </p>
              <ol
                start={1}
                style={{ marginBottom: "0in", marginTop: "0in" }}
                type="1"
              >
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                    marginTop: "14.0pt",
                  }}
                >
                  users can visit our site anonymously;
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  our Privacy Policy link includes the word “Privacy”, and can
                  easily be found on the page specified above on the home page
                  of our website;
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  users will be notified of any privacy policy changes on our
                  Privacy Policy Page;
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: "14.0pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  users are able to change their personal information by
                  emailing us at support@tweetsky.com.
                </li>
              </ol>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                Our Policy on “Do Not Track” Signals:
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                We honor Do Not Track signals and do not track, plant cookies,
                or use advertising when a Do Not Track browser mechanism is in
                place. Do Not Track is a preference you can set in your web
                browser to inform websites that you do not want to be tracked.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                You can enable or disable Do Not Track by visiting the
                Preferences or Settings page of your web browser.
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    12. Your Data Protection Rights under the California
                    Consumer Privacy Act (CCPA)
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                If you are a California resident, you are entitled to learn what
                data we collect about you, ask to delete your data and not to
                sell (share) it. To exercise your data protection rights, you
                can make certain requests and ask us:
              </p>
              <ol
                start={1}
                style={{ marginBottom: "0in", marginTop: "0in" }}
                type="1"
              >
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                    marginTop: "14.0pt",
                  }}
                >
                  <strong>What personal information we have about you</strong>.
                  If you make this request, we will return to you:
                  <ol
                    start={1}
                    style={{ marginBottom: "0in", marginTop: "0in" }}
                    type="1"
                  >
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      The categories of personal information we have collected
                      about you.
                    </li>
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      The categories of sources from which we collect your
                      personal information.
                    </li>
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      The business or commercial purpose for collecting or
                      selling your personal information.
                    </li>
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      The categories of third parties with whom we share
                      personal information.
                    </li>
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      The specific pieces of personal information we have
                      collected about you.
                    </li>
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      A list of categories of personal information that we have
                      sold, along with the category of any other company we sold
                      it to. If we have not sold your personal information, we
                      will inform you of that fact.
                    </li>
                    <li
                      style={{
                        margin: "0in",
                        marginBottom: ".0001pt",
                        lineHeight: "115%",
                        fontSize: "15px",
                        fontFamily: '"Arial","sans-serif"',
                      }}
                    >
                      A list of categories of personal information that we have
                      disclosed for a business purpose, along with the category
                      of any other company we shared it with.
                    </li>
                  </ol>
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  Please note, you are entitled to ask us to provide you with
                  this information up to two times in a rolling twelve-month
                  period. When you make this request, the information provided
                  may be limited to the personal information we collected about
                  you in the previous 12 months.
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: ".0001pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  <strong>To delete your personal information</strong>. If you
                  make this request, we will delete the personal information we
                  hold about you as of the date of your request from our records
                  and direct any service providers to do the same. In some
                  cases, deletion may be accomplished through de-identification
                  of the information. If you choose to delete your personal
                  information, you may not be able to use certain functions that
                  require your personal information to operate.
                </li>
                <li
                  style={{
                    margin: "0in",
                    marginBottom: "14.0pt",
                    lineHeight: "115%",
                    fontSize: "15px",
                    fontFamily: '"Arial","sans-serif"',
                  }}
                >
                  <strong>To stop selling your personal information</strong>. We
                  don't sell or rent your personal information to any third
                  parties for any purpose. You are the only owner of your
                  Personal Data and can request disclosure or deletion at any
                  time.
                  <br />
                  &nbsp;
                  <br />
                  &nbsp;Please note, if you ask us to delete or stop selling
                  your data, it may impact your experience with us, and you may
                  not be able to participate in certain programs or membership
                  services which require the usage of your personal information
                  to function. But in no circumstances, we will discriminate
                  against you for exercising your rights.
                  <br />
                  &nbsp;
                  <br />
                  &nbsp;To exercise your California data protection rights
                  described above, please send your request(s) by one of the
                  following means:
                  <br />
                  &nbsp;
                  <br />
                  &nbsp;By email: support@tweetsky.com
                  <br />
                  &nbsp;
                  <br />
                  &nbsp;By mail: ADZDATA LLC 2108 N ST, STE N, Sacramento, CA
                  95816
                </li>
              </ol>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                Your data protection rights, described above, are covered by the
                CCPA, short for the California Consumer Privacy Act. To find out
                more, visit the official California Legislative Information
                website. The CCPA took effect on 01/01/2020.
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    13. Service Providers
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We may employ third party companies and individuals to
                facilitate our Service (<strong>“Service Providers”</strong>),
                provide Service on our behalf, perform Service-related services
                or assist us in analyzing how our Service is used.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    14. Analytics
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>Google Analytics</strong>
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualize and personalize the ads of its
                own advertising network.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                For more information on the privacy practices of Google, please
                visit the Google Privacy Terms web page:
                https://policies.google.com/privacy?hl=en
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We also encourage you to review Google's policy for safeguarding
                your data: https://support.google.com/analytics/answer/6004245.
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    15. Advertising
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We may use third-party Service Providers to show advertisements
                to you to help support and maintain our Service.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>Google AdSense DoubleClick Cookie</strong>
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Google, as a third party vendor, uses cookies to serve ads on
                our Service. Google's use of the DoubleClick cookie enables it
                and its partners to serve ads to our users based on their visit
                to our Service or other websites on the Internet.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                You may opt out of the use of the DoubleClick Cookie for
                interest-based advertising by visiting the Google Ads Settings
                web page: http://www.google.com/ads/preferences/
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    16. Behavioral Remarketing
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Adzdata LLC uses remarketing services to advertise on third
                party websites to you after you visited our Service. We and our
                third-party vendors use cookies to inform, optimize and serve
                ads based on your past visits to our Service.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>Google Ads (AdWords)</strong>
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Google Ads (AdWords) remarketing service is provided by Google
                Inc.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                You can opt-out of Google Analytics for Display Advertising and
                customize the Google Display Network ads by visiting the Google
                Ads Settings page: http://www.google.com/settings/ads
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Google also recommends installing the Google Analytics Opt-out
                Browser Add-on – https://tools.google.com/dlpage/gaoptout – for
                your web browser. Google Analytics Opt-out Browser Add-on
                provides visitors with the ability to prevent their data from
                being collected and used by Google Analytics.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                For more information on the privacy practices of Google, please
                visit the Google Privacy Terms web page:
                https://policies.google.com/privacy?hl=en
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>Bing Ads Remarketing</strong>
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Bing Ads remarketing service is provided by Microsoft Inc.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                You can opt-out of Bing Ads interest-based ads by following
                their instructions:
                https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                You can learn more about the privacy practices and policies of
                Microsoft by visiting their Privacy Policy page:
                https://privacy.microsoft.com/en-us/PrivacyStatement
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>Twitter</strong>
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Twitter remarketing service is provided by Twitter Inc.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                You can opt-out from Twitter's interest-based ads by following
                their instructions:
                https://support.twitter.com/articles/20170405
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                You can learn more about the privacy practices and policies of
                Twitter by visiting their Privacy Policy page:
                https://twitter.com/privacy
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    17. Payments
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We may provide paid products and/or services within Service. In
                that case, we use third-party services for payment processing
                (e.g. payment processors).
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We will not store or collect your payment card details. That
                information is provided directly to our third-party payment
                processors whose use of your personal information is governed by
                their Privacy Policy. These payment processors adhere to the
                standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa,
                Mastercard, American Express and Discover. PCI-DSS requirements
                help ensure the secure handling of payment information.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                The payment processors we work with are:
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                <strong>Stripe:</strong>
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Their Privacy Policy can be viewed at:
                https://stripe.com/us/privacy
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    18. Links to Other Sites
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Our Service may contain links to other sites that are not
                operated by us. If you click a third party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    19. Children's Privacy
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                Our Services are not intended for use by children under the age
                of 13 (<strong>“Children”</strong>).
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We do not knowingly collect personally identifiable information
                from Children under 13. If you become aware that a Child has
                provided us with Personal Data, please contact us. If we become
                aware that we have collected Personal Data from Children without
                verification of parental consent, we take steps to remove that
                information from our servers.
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    20. Changes to This Privacy Policy
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update
                “effective date” at the top of this Privacy Policy.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
              <h2
                style={{
                  marginTop: ".25in",
                  marginRight: "0in",
                  marginBottom: "4.0pt",
                  marginLeft: "0in",
                  lineHeight: "115%",
                  fontSize: "21px",
                  fontFamily: '"Arial","sans-serif"',
                  fontWeight: "normal",
                }}
              >
                <strong>
                  <span style={{ fontSize: "23px", lineHeight: "115%" }}>
                    21. Contact Us
                  </span>
                </strong>
              </h2>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                If you have any questions about this Privacy Policy, please
                contact us:
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: "14.0pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                  marginTop: "14.0pt",
                  marginRight: "0in",
                  marginLeft: "0in",
                }}
              >
                By email: support@tweetsky.com.
              </p>
              <p
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  lineHeight: "115%",
                  fontSize: "15px",
                  fontFamily: '"Arial","sans-serif"',
                }}
              >
                &nbsp;
              </p>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Privacy;

import React from 'react';
import Feature from './components/feature';
import Hero from './components/hero';
import Pricing from './components/Pricing';
import Footer from './components/footer';
import container from './LandingPage.container';
// import Reviews from './components/Reviews';
// import Testimonials from './components/testimonials';
// import Contact from './components/contact';

const LandingPage = (props: any) => {
  const { history, authState: { isAuthenticated } } = props;

  return (
    <>
      <Hero isAuthenticated={isAuthenticated} />
      {/*<Testimonials />*/}
      <Feature />
      {/*<Reviews />*/}
      <div style={{ background: '#1F74C21A' }}>
        <Pricing history={history} />
      </div>
      {/*<Contact />*/}
      <Footer />
    </>
  );
};

export default container(LandingPage);

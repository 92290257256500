export const gifApiKey = process.env.REACT_APP_GIF_KEY;
export const s3AccessKey = process.env.REACT_APP_S3_ACCESS_KEY;
export const s3SecretAccessKey = process.env.REACT_APP_S3_ACCESS_SECRET_KEY;
export const bucketName = `tweetsky-prod-media-bucket`;
export const auth_base_url = 'https://itzy09bi9d.execute-api.us-east-1.amazonaws.com/prod';
export const base_Url = 'https://xludjyi9ec.execute-api.us-east-1.amazonaws.com/prod/';
export const stripe_base_Url = 'https://agqr7vi95c.execute-api.us-east-1.amazonaws.com/prod';
export const analytics_Url = `https://7z3hmxde0a.execute-api.us-east-1.amazonaws.com/prod/`;
export const rss_base_url = 'https://665qi3tri5.execute-api.us-east-1.amazonaws.com/prod';
export const bucketUrl = `https://tweetsky-prod-media-bucket.s3.us-east-1.amazonaws.com/`;
export const stripeConfig = {
    baseUrl: 'https://api.stripe.com',
    currency: 'usd',
    publicKey: 'pk_live_51K6pUcEbk9l0jMJrVjbygp5ouDQBgVpw192jHomqnCYsCO7Zpvh0nNsG97X02sOkoCMNt9qxYHA9huOOtqxDBqd700xFqJHVsS',
    singleMonthlyPriceId: 'price_1KZ861Ebk9l0jMJrdTK1nRBH',
    basicMonthlyPriceId: 'price_1KZ8AbEbk9l0jMJrDXuj4wFv',
    advancedMonthlyPriceId: 'price_1KZ8EZEbk9l0jMJrMQHf6For',
    teamMonthlyPriceId: 'price_1KZ8IUEbk9l0jMJrLH50KGvi',
    singleYearlyPriceId: 'price_1KZ8KkEbk9l0jMJrhop76xCa',
    basicYearlyPriceId: 'price_1KZ8N5Ebk9l0jMJrUFAfX4f8',
    advancedYearlyPriceId: 'price_1KZ8OJEbk9l0jMJrB8JCjlQA',
    teamYearlyPriceId: 'price_1KZ8Q1Ebk9l0jMJrdwoeZ6fl',
};
export const tweetLambda = 'tweetsky-prod-tweetAPost';
export const hashtagyApiKey = process.env.REACT_APP_HASHTAGY_API_KEY;

import { RootState } from 'src/store';
import { connect } from 'react-redux';
import { showSnackbarNotification } from 'src/store/notification/notification.actions';
import { setActiveAccount, cancelUserSubscription } from 'src/store/auth/authAction';
import { obtainOauthRequestTokenAction, removeTweetAccount } from 'src/store/account/account.actions';
import { vendorInvitationStart, removeVendorStart } from 'src/store/vendor/vendor.actions';
import { subscribeUser } from 'src/store/subscription/subscription.actions';

const mapStateToProps = (state: RootState) => ({
  authState: state.auth,
  accountState: state.account,
  vendorState: state.vendor
});

const mapDispatchToProps = (dispatch: any) => ({
  onShowNotification: (status: string, message: string) =>
    dispatch(showSnackbarNotification(status, message)),
  onRemoveTweetAccount: (accountId: string, value: boolean) =>
    dispatch(removeTweetAccount(accountId, value)),
  onSetActiveAccount: (accountId: string) =>
    dispatch(setActiveAccount(accountId)),
  onVendorInvitation: (values: any) => dispatch(vendorInvitationStart(values)),
  onRemoveVendor: (vendorId: string) => dispatch(removeVendorStart(vendorId)),
  onSubscribeUser: (user: any) => dispatch(subscribeUser(user)),
  onCancelUserSubscription: (userId: string) =>
    dispatch(cancelUserSubscription(userId)),
  onObtainOauthRequestTokenAction: () => dispatch(obtainOauthRequestTokenAction())
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;

import {
  createStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';
import React from 'react';

interface Props {
  text: string;
  icon: any;
  currentTab?: string;
  section: string;
  clicked: () => string;
  nestedList?: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    active: {
      background: theme.palette.primary.A100
    },
    listItemIcon: {
      minWidth: '35px',
      color: ({ section, currentTab }: any) =>
        section === currentTab
          ? theme.palette.primary.main
          : theme.palette.text.secondary
    },
    fontText: {
      fontSize: '16px',
      marginLeft: theme.spacing(1),
      color: ({ section, currentTab }: any) =>
        section === currentTab
          ? theme.palette.primary.main
          : theme.palette.text.secondary
    }
  })
);

export const SidebarItem: React.FC<Props> = (props) => {
  const { text, icon, currentTab, section, clicked, nestedList } = props;
  const classes = useStyles(props);

  return (
    <ListItem
      button
      key={text}
      className={section === currentTab ? classes.active : undefined}
      onClick={clicked}
    >
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography
            className={classes.fontText}
            variant={'h6'}
            color={'textSecondary'}
          >
            {text}
          </Typography>
        }
      />
      {nestedList}
    </ListItem>
  );
};

export default SidebarItem;

import React from 'react';
import {
  Button,
  CardContent,
  Grid
} from '@material-ui/core';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import ContentModal from '../ContentModal';
import RemoveModal from '../RemoveModal';

interface Props {
  classes: any;
  loading: boolean;
  user: any;
  matchesXS: boolean;
  openConfirm: boolean,
  handleCloseConfirm: () => void,
  handleInvitation: (value: any) => void;
  isVendor: boolean;
  vendorList: any;
  onRemoveVendorHandler: (value: string) => void;
}

const Agency = (props: Props) => {
  const {
    classes,
    loading,
    user,
    matchesXS,
    openConfirm,
    handleCloseConfirm,
    handleInvitation,
    isVendor,
    vendorList,
    onRemoveVendorHandler
  } = props;
  const [openInvite, setOpenInvite] = React.useState(false);
  const [removeVendor, setRemoveVendor] = React.useState<string|null>(null);

  const handleOpen = () => {
    setOpenInvite(!openInvite);
  };

  const handleAction = (values: any) => {
      setOpenInvite(false);
      handleInvitation(values);
  };

  const removeVendorConfirm = (vendorId: string) => {
    setRemoveVendor(vendorId);
    handleCloseConfirm();
  };

  const removeHandler = () => {
    if (removeVendor) {
      onRemoveVendorHandler(removeVendor);
    }
  };

  return(
    <CardContent>
      {!isVendor &&
        <Button
          type="button"
          variant="contained"
          color="primary"
          startIcon={<AiOutlineUsergroupAdd />}
          fullWidth={matchesXS}
          disabled={loading}
          onClick={handleOpen}
        >
          Invite
        </Button>
      }
      <ContentModal
        open={openInvite}
        handleOpen={handleOpen}
        title="Invite sub-vendor through email."
        user={user}
        handleAction={handleAction}
      />
      <Grid container style={{ padding: '16px 0px 0px' }}>
        {vendorList && vendorList.map((value: any, index: number) =>
          <Grid container spacing={2} key={`vendor-${index}`} className={classes.row}>
            <Grid item xs={12} sm={4}>{`${value.firstname} ${value.lastname}`}</Grid>
            <Grid item xs={12} sm={4}>{`${value.email}`}</Grid>
            <Grid item xs={12} sm={4}>
              <Button
                type="button"
                fullWidth={matchesXS}
                variant="contained"
                color="secondary"
                onClick={() => removeVendorConfirm(value.id)}
              >
                Remove Vendor
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <RemoveModal
        open={openConfirm}
        title={`Are you sure, you want to remove vendor?`}
        description={null}
        handleClose={handleCloseConfirm}
        removeHandler={removeHandler}
      />
    </CardContent>
  );
}

export default Agency;

import React from "react";
import { useHistory } from "react-router";
import { format } from "date-fns";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  createStyles,
  Grid,
  ImageList,
  ImageListItem,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import GradeIcon from "@material-ui/icons/Grade";
import LoopIcon from "@material-ui/icons/Loop";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import RepeatIcon from "@material-ui/icons/Repeat";
import ReplyIcon from "@material-ui/icons/Reply";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AvatarComponent from "src/components/AvatarComponent";
import TitleComponent from "src/components/TitleComponent";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    thread: {
      position: "relative",
      width: "100%",
      // maxWidth: '500px',
      height: "auto",
      cursor: "pointer",
      margin: "16px auto",
      boxShadow: "0px 1px 3px rgb(3 0 71 / 9%)",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    threadNotifier2: {
      paddingBottom: "16px",
      display: "flex",
      alignItems: "center",
      gap: "9px",
    },
    threadNotifier: {
      paddingBottom: "16px",
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    threadNotifierHeader: {
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
    },
    threadBody: {
      marginLeft: theme.spacing(7),
    },
    threadText: {
      position: "relative",
      padding: "0",
      wordBreak: "break-all",
      paddingBottom: 0,
      fontSize: "16px",
    },
    threadTextContent: {
      fontSize: 16,
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
      paddingBottom: theme.spacing(1),
    },
    cardHeader: {
      padding: 0,
    },
    threadContent: {
      padding: "0",
      margin: "auto",
      alignItems: "start",
    },
    line: {
      width: "2px",
      background: "hsl(205, 20%, 78%)",
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "20px",
    },
    tweetImage: {
      objectFit: "cover",
      height: "100% !important",
      "& div ": {
        borderRadius: "8px",
        "& img": {
          borderRadius: "8px",
          width: "100%",
        },
      },
    },
    iconButtonContainer: {
      width: "100%",
      // maxWidth: '500px',
      margin: "16px auto",
      // display: 'flex',
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },
    iconButton: {
      padding: "8px",
      margin: "0px 8px",
      borderRadius: 13,
    },
    info: {
      padding: theme.spacing(1),
    },
    imageStyle: {
      height: "auto",
      width: "auto",
    },
    infoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "5px",
      color: theme.palette.type === "dark" ? "#e2e0e0de" : "#4a4646de",
      "& span": {
        fontSize: "16px",
      },
    },
  })
);
interface IThreadViewCard {
  username: string;
  avatar: string;
  isSubscriber: boolean;
  thread: any;
  onDeleteSchedulePost: (sk: string, history: any) => void;
  onDeleteHistoryPost: (sk: string, history: any) => void;
  onRetweetThread: ({ sk }: { sk: string }) => void;
  onUnretweetThread: ({ sk }: { sk: string }) => void;
  historyLoading: boolean;
}
function ThreadViewCard(props: IThreadViewCard) {
  const {
    username,
    avatar,
    isSubscriber,
    thread,
    onDeleteSchedulePost,
    onDeleteHistoryPost,
    onRetweetThread,
    onUnretweetThread,
    historyLoading,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container style={{ maxWidth: 800 }}>
      <Card className={classes.thread}>
        <CardContent>
          {thread.isRetweeted && (
            <div className={classes.threadNotifier2}>
              <LoopIcon />
              <Typography
                component="p"
                color="textSecondary"
                className={classes.threadNotifierHeader}
              >
                You retweeted.
              </Typography>
            </div>
          )}
          <div className={classes.threadNotifier}>
            {thread &&
            thread.filters &&
            thread.filters.split("#")[2].toLowerCase() === "true" ? (
              <Tooltip title={"Tweet is posted."}>
                <DoneIcon fontSize={"small"} style={{ color: "#0cd30c" }} />
              </Tooltip>
            ) : thread.filters &&
              thread.filters.split("#")[2].toLowerCase() === "false" ? (
              <QueryBuilderIcon fontSize={"small"} />
            ) : (
              <Tooltip title={thread.errors && thread.errors}>
                <ErrorOutlineIcon style={{ color: "#c90a0a" }} />
              </Tooltip>
            )}
            <Typography
              component="p"
              color="textSecondary"
              className={classes.threadNotifierHeader}
            >
              {thread &&
              thread.filters &&
              thread.filters.split("#")[2].toLowerCase() === "true"
                ? "Published on"
                : thread.filters &&
                  thread.filters.split("#")[2].toLowerCase() === "false"
                ? "Scheduled on"
                : "Failed on"}{" "}
              {thread.scheduleAt &&
                format(new Date(thread.scheduleAt), "MMMM dd, yyyy, p")}
            </Typography>
          </div>

          {thread.post &&
            thread.post.map((tweet: string, threadPostIndex: number) => (
              <>
                <CardHeader
                  key={threadPostIndex}
                  avatar={
                    username ? (
                      <img
                        src={`https://unavatar.io/twitter/${username}`}
                        alt={username}
                        style={{
                          borderRadius: "50%",
                          padding: "4px",
                          height: "36px",
                          width: "36px",
                        }}
                      />
                    ) : (
                      <AvatarComponent avatar={avatar} />
                    )
                  }
                  title={<TitleComponent username={username} />}
                  classes={{
                    root: classes.cardHeader,
                    content: classes.threadContent,
                  }}
                />
                <div className={classes.threadBody}>
                  <CardContent className={classes.threadText}>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.threadTextContent}
                    >
                      {tweet ? tweet : "Write something to see the preview"}
                    </Typography>
                    {thread.media_URLs &&
                      thread.media_URLs[threadPostIndex] &&
                      thread.media_URLs[threadPostIndex].video &&
                      thread.media_URLs[threadPostIndex].video[0] && (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <CardActionArea>
                            {thread.media_URLs[threadPostIndex] &&
                              thread.media_URLs[threadPostIndex].video &&
                              thread.media_URLs[threadPostIndex].video
                                .length !== 0 && (
                                <>
                                  <CardMedia
                                    controls
                                    component="video"
                                    height="auto"
                                    src={
                                      thread.media_URLs[threadPostIndex]
                                        .video[0].mediaFile
                                        ? `data:${thread.media_URLs[threadPostIndex].video[0].mediaType};base64,${thread.media_URLs[threadPostIndex].video[0].mediaFile}`
                                        : thread.media_URLs[threadPostIndex]
                                            .video[0].url
                                    }
                                  />
                                </>
                              )}{" "}
                          </CardActionArea>
                        </Grid>
                      )}
                    {thread.media_URLs &&
                      thread.media_URLs.length > 0 &&
                      thread.media_URLs[threadPostIndex] &&
                      thread.media_URLs[threadPostIndex].image &&
                      thread.media_URLs[threadPostIndex].image.length !== 0 && (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <ImageList
                            cols={2}
                            rowHeight={200}
                            gap={1}
                            className={classes.imageStyle}
                          >
                            {thread.media_URLs[threadPostIndex].image.map(
                              (image: any, imageIndex: any) => (
                                <ImageListItem
                                  cols={
                                    thread.media_URLs[threadPostIndex].image
                                      .length === 1
                                      ? 2
                                      : 1
                                  }
                                  rows={1}
                                  key={imageIndex}
                                  className={classes.tweetImage}
                                  // style={{
                                  //   aspectRatio:
                                  //     thread.media_URLs[threadPostIndex].image
                                  //       .length === 2
                                  //       ? "7/8"
                                  //       : thread.media_URLs[threadPostIndex]
                                  //           .image.length === 4
                                  //       ? "2/1"
                                  //       : thread.media_URLs[threadPostIndex]
                                  //           .image.length === 3
                                  //       ? "4/7"
                                  //       : "16/9",
                                  // }}
                                >
                                  <img src={image.url} alt="Not available." />
                                </ImageListItem>
                              )
                            )}
                          </ImageList>
                        </Grid>
                      )}
                    {thread.media_URLs &&
                      thread.media_URLs[threadPostIndex] &&
                      thread.media_URLs[threadPostIndex].gif &&
                      thread.media_URLs[threadPostIndex].gif.length !== 0 &&
                      thread.media_URLs[threadPostIndex].gif.map(
                        (gif: any, gifIndex: any) => (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ImageList
                              key={gifIndex}
                              rowHeight={"auto"}
                              cellHeight="auto"
                            >
                              <ImageListItem cols={2} rows={2}>
                                <img
                                  src={gif.url}
                                  style={{ width: "100%", borderRadius: "6px" }}
                                  alt="Gif not available."
                                />
                              </ImageListItem>
                            </ImageList>
                          </Grid>
                        )
                      )}
                  </CardContent>
                  {thread.tweetInfo && thread.tweetInfo[threadPostIndex] && (
                    <Grid container spacing={2} className={classes.info}>
                      <Grid item xs={2} className={classes.infoContainer}>
                        <Tooltip title={"Like count."}>
                          <GradeIcon fontSize="small" />
                        </Tooltip>
                        <Typography component={"span"}>
                          {thread.tweetInfo[threadPostIndex].like_count}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.infoContainer}>
                        <Tooltip title={" Tweet reply count."}>
                          <ReplyIcon fontSize="small" />
                        </Tooltip>
                        <Typography component={"span"}>
                          {thread.tweetInfo[threadPostIndex].reply_count}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.infoContainer}>
                        <Tooltip title={"Retweet count."}>
                          <RepeatIcon fontSize="small" />
                        </Tooltip>
                        <Typography component={"span"}>
                          {thread.tweetInfo[threadPostIndex].retweet_count}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.infoContainer}>
                        <Tooltip title={"Tweet impression count."}>
                          <VisibilityIcon fontSize="small" />
                        </Tooltip>
                        <Typography component={"span"}>
                          {thread.tweetInfo[threadPostIndex].impression_count}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </>
            ))}
        </CardContent>
        <CardActions>
          {isSubscriber && (
            <div className={classes.iconButtonContainer}>
              <Button
                variant="outlined"
                className={classes.iconButton}
                startIcon={
                  <DeleteOutlineIcon
                    style={{ fontSize: "16px", padding: "0 5px" }}
                  />
                }
                onClick={(e) => {
                  if (
                    window &&
                    window.confirm(
                      "Are you sure you want to delete the scheduled tweet?"
                    )
                  ) {
                    if (
                      thread &&
                      thread.sk &&
                      thread.sk.split("#")[1].toLowerCase === "schedule"
                    ) {
                      onDeleteSchedulePost(thread.sk, history);
                    } else {
                      onDeleteHistoryPost(thread.sk, history);
                    }
                  }
                }}
                disabled={historyLoading && historyLoading}
              >
                Delete Thread
              </Button>
              {thread &&
              thread.filters &&
              thread.filters.split("#")[2].toLowerCase() !== "true" ? (
                <Button
                  variant="outlined"
                  className={classes.iconButton}
                  startIcon={
                    <EditIcon style={{ fontSize: "16px", padding: "0 5px" }} />
                  }
                  onClick={() => {
                    history.push(`/schedule/${thread.postId}`);
                  }}
                  disabled={historyLoading}
                >
                  Edit Thread
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={classes.iconButton}
                  startIcon={
                    <EditIcon style={{ fontSize: "16px", padding: "0 5px" }} />
                  }
                  disabled={historyLoading}
                  onClick={() => {
                    if (thread.isRetweeted) {
                      onUnretweetThread({ sk: thread.sk });
                    } else {
                      onRetweetThread({ sk: thread.sk });
                    }
                  }}
                >
                  {!thread.isRetweeted ? "Retweet Thread" : "Unretweet Thread"}
                </Button>
              )}
            </div>
          )}
        </CardActions>
      </Card>
    </Container>
  );
}

export default ThreadViewCard;

export const REGISTER_NEW_USER = 'REGISTER_NEW_USER';
export const REGISTER_NEW_USER_FAIL = 'REGISTER_NEW_USER_FAIL';
export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const SIGNUP_VERIFY_START = 'SIGNUP_VERIFY_START';
export const SIGNUP_VERIFY_SUCCESS = 'SIGNUP_VERIFY_SUCCESS';
export const SIGNUP_VERIFY_FAIL = 'SIGNUP_VERIFY_FAIL';

export const LOGIN_START = 'LOGIN_START';
export const LOAD_CURRENT_USER_START = 'LOAD_CURRENT_USER_START';
export const LOAD_CURRENT_USER_SUCCESS = 'LOAD_CURRENT_USER_SUCCESS';
export const LOAD_CURRENT_USER_FAIL = 'LOAD_CURRENT_USER_FAIL';

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const SET_USER_ACTIVE_ACCOUNT = 'SET_USER_ACTIVE_ACCOUNT';

export const CHECK_SUB_VENDOR_A_USER = 'CHECK_SUB_VENDOR_A_USER';
export const LOAD_INVITATION_SUCCESS = 'LOAD_INVITATION_SUCCESS';

export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAIL = 'FETCH_CURRENT_USER_FAIL';

// export const FETCH_USER_SUBSCRIPTION_START = 'FETCH_USER_SUBSCRIPTION_START';
// export const FETCH_USER_SUBSCRIPTION_SUCCESS = 'FETCH_USER_SUBSCRIPTION_SUCCESS';

export const CANCEL_USER_SUBSCRIPTION_START = 'CANCEL_USER_SUBSCRIPTION_START';
export const CANCEL_USER_SUBSCRIPTION_SUCCESS = 'CANCEL_USER_SUBSCRIPTION_SUCCESS';


export const VENDOR_INVITATION_START = 'VENDOR_INVITATION_START';
export const VENDOR_INVITATION_SUCCESS = 'VENDOR_INVITATION_SUCCESS';
export const VENDOR_INVITATION_FAIL = 'VENDOR_INVITATION_FAIL';

export const FETCH_USER_VENDOR_START = 'FETCH_USER_VENDOR_START';
export const FETCH_USER_VENDOR_SUCCESS = 'FETCH_USER_VENDOR_SUCCESS';
export const FETCH_USER_VENDOR_FAIL = 'FETCH_USER_VENDOR_FAIL';

export const REMOVE_VENDOR_START = 'REMOVE_VENDOR_START';
export const REMOVE_VENDOR_SUCCESS = 'REMOVE_VENDOR_SUCCESS';

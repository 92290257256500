import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader, createStyles,
  Grid, makeStyles,
  Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    card: {
      position: 'relative',
      boxShadow: '0px 1px 3px rgb(3 0 71 / 9%)',
      width: '100%',
      height: '183px',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    header: {
      fontSize: '12px',
      paddingBottom: '16px',
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    },
    content: {
      padding: '0',
      paddingBottom: 0,
      marginBottom: 17
    }
  })
);

export default function SkeletonPost() {
  const theme = useTheme();
  const classes = useStyles();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      {Array(matchesXS ? 3 : matchesSM ? 6 : matchesMD ? 9 : 8).fill(undefined).map((row: any, index: any) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.header}
                component="p"
                color="textSecondary"
              >
                <Skeleton animation="wave" height={20} width="60%"/>
              </Typography>
              <CardHeader
                key={index}
                className={classes.content}
                avatar={
                  <Skeleton variant="circle" width={30} height={30}/>
                }
                title={
                  <div>
                    <Skeleton animation="wave" height={80} width="90%"/>
                  </div>
                }
              />
              <CardActions>
                <Skeleton animation="wave" height={20} width={50}/>
                <Skeleton animation="wave" height={20} width={50}/>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
}

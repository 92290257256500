import React from 'react';
import { Box, Grid } from '@material-ui/core';
import EmptyScreen from '../Draft/components/emptyScreen';
import SkeletonPost from 'src/components/skeleton-post';
import container from './History.cotainer';
import DisplayCard from 'src/components/displayCard/displayCard';

const History = (props: any) => {
  const {
    tweetskyState: { historyData, historyLoading },
    accountState: { results, accountId },
    onDeleteHistoryPost,
    onRetweetThread,
    onUnretweetThread
  } = props;
  const [username, setUsername] = React.useState<string>('');
  const [avatar, setAvatar] = React.useState<string>('');

  React.useEffect(() => {
    if (accountId && results) {
      const activeAccount = results[accountId];
      setUsername(`${activeAccount?.username}`);
      setAvatar(activeAccount?.username?.charAt(0)?.toUpperCase());
    }
  }, [accountId, results]);

  return (
    <>
      <Box style={{ margin: '16px auto' }}>
        <Grid container spacing={2} justifyContent="center">
          {historyLoading ? (
            <SkeletonPost />
          ) : historyData &&
            Object.values(historyData) &&
            Object.values(historyData).length > 0 ? (
            Object.values(historyData)
              .sort(function (a: any, b: any) {
                return Number(b.updatedAt) - Number(a.updatedAt);
              })
              .map((historyData: any, index: any) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <DisplayCard
                    avatar={avatar}
                    username={username}
                    historyData={historyData}
                    onDeleteHistoryPost={onDeleteHistoryPost}
                    onRetweetThread={onRetweetThread}
                    onUnretweetThread={onUnretweetThread}
                  />
                </Grid>
              ))
          ) : (
            <EmptyScreen />
          )}
        </Grid>
      </Box>
    </>
  );
};

export default container(History);

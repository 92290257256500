import { createStyles, Grid, makeStyles } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import * as React from 'react';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardBody: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 400,
      minWidth: 200,
      backgroundColor: theme.palette.type === 'light' ? '#f3f7fa' : '#424242',
      borderRadius: 5,
      padding: '25px 25px 18px 25px'
    },
    header: {
      fontWeight: 500,
      marginBottom: 10
    },

    hastagContainer: {
      color:
        theme.palette.type === 'dark' ? '#fff' : theme.palette.text.primary,
      margin: '22px 0',
      maxHeight: '161px',
      overflow: 'hidden',
      overflowY: 'auto'
    },
    tagContainer: {
      display: 'inline-flex',
      width: 'auto',
      cursor: 'pointer',
      border: '1px solid',
      margin: ' 5px 5px',
      borderRadius: '16px'
    },
    selectedTag: {
      border: '2px solid green'
    },
    tag: {
      color:
        theme.palette.type === 'dark' ? '#fff' : theme.palette.text.primary,
      paddingRight: 0
    }
  })
);
interface tag {
  hashtag: string;
  relevance?: any;
  id?: any;
}
export default function HashtagGeneratorModal(props: any) {
  const classes = useStyles();
  const {
    hastagModal,
    setHastagModal,
    onShowNotification,
    onFetchHashtagStart,
    isFetching,
    hashtagList
  } = props;
  const [keyword, setKeyword] = React.useState('');
  const [selectedHashtags, setSelectedHashtags] = React.useState<any[]>([]);
  const [tagList, setTagList] = React.useState<tag[] | null>([]);

  React.useEffect(() => {
    setTagList(hashtagList);
  }, [hashtagList]);

  const handleFetchHashtag = (e?: any) => {
    e?.preventDefault();
    onFetchHashtagStart({ keyword });
  };
  const onSelect = (hashtag: any) => {
    const { id } = hashtag;
    const exist = selectedHashtags.find((tag) => tag.id === id);
    if (exist) {
      setSelectedHashtags(selectedHashtags.filter((tag) => tag.id !== id));
    } else {
      setSelectedHashtags([...selectedHashtags, hashtag]);
    }
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={hastagModal}
        onClose={() => setHastagModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={hastagModal}>
          <form onSubmit={handleFetchHashtag}>
            <Box className={classes.cardBody}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.header}
                color={'textPrimary'}
              >
                Generate relevants hastag
              </Typography>
              <Grid alignItems="center" spacing={1} container>
                <Grid item>
                  <TextField
                    variant="outlined"
                    label="Keyword"
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleFetchHashtag()}
                    disabled={isFetching}
                  >
                    Generate
                  </Button>
                </Grid>
              </Grid>

              <div className={classes.hastagContainer}>
                {tagList && tagList.length > 0 &&
                  tagList.map((tag, i) => (
                    <Grid
                      key={i}
                      container
                      spacing={1}
                      alignItems="center"
                      className={clsx(classes.tagContainer, {
                        [classes.selectedTag]: selectedHashtags.find(
                          (item) => item.id === tag.id
                        )
                      })}
                    >
                      <Grid
                        item
                        className={classes.tag}
                        onClick={() => {
                          onSelect(tag);
                        }}
                      >
                        #{tag.hashtag}
                      </Grid>
                      <Grid item>
                        <IconButton
                          disableRipple={true}
                          onClick={() => {
                            const filteredList = tagList.filter(
                              (item) => item.hashtag !== tag.hashtag
                            );
                            setTagList([...filteredList]);
                          }}
                          style={{ padding: 2 }}
                        >
                          <CancelIcon
                            style={{
                              color: 'rgb(219 18 18)',
                              fontSize: '17px'
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
              </div>
              {tagList && tagList.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      tagList
                        ? tagList
                            .map((item: any) => `#${item.hashtag}`)
                            .join(' ')
                        : ''
                    );
                    onShowNotification('success', 'All Tag copied');
                  }}
                >
                  Copy All
                </Button>
              )}
              {selectedHashtags.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: '16px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      selectedHashtags
                        ? selectedHashtags
                            .map((item: any) => `#${item.hashtag}`)
                            .join(' ')
                        : ''
                    );
                    onShowNotification('success', 'Selected Tag copied');
                  }}
                >
                  Copy Selected
                </Button>
              )}
            </Box>
          </form>
        </Fade>
      </Modal>
    </div>
  );
}

import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import {all, call, put, takeLatest} from 'redux-saga/effects';
import { stripeConfig, stripe_base_Url } from '../../constant/twitterApi';
import * as SubscriptionType from './subscription.types';
import * as SubscriptionAction from './subscription.actions';
import * as AuthAction from 'src/store/auth/authAction';
import { showSnackbarNotification } from "src/store/notification/notification.actions";

const stripePromise: any = loadStripe(stripeConfig?.publicKey);
function* subscribeUserAsync(action: { type: string; payload: any }): any {
  const { userId, email, type, successURL } = action.payload.user;
  // Get Stripe.js instance
  const stripe = yield stripePromise;
  // const priceId = stripeConfig.priceId; /// default price set: only one subscription plan
  const values: any = localStorage.getItem('subscription::price'); /// get price selected by user for subscription
  const { priceId, priceType } = JSON.parse(values);
  console.log('priceType: ', priceId, priceType);
  if (stripe && priceId) {
    // create session checkout
    const { data } = yield axios.post(
      `${stripe_base_Url}/tweetsky/create-session-checkout`,
      {
        userId,
        email,
        priceId,
        priceType,
        type,
        successURL
      }
    );
    // console.log('session data: ', data);
    localStorage.removeItem('subscription::price'); // remove price from localstorage
    const { error } = yield stripe.redirectToCheckout({
      sessionId: data.sessionId
    });
    if (error) {
      console.error('ERROR: ', error);
    }
  }
}

function* updateSubscriptionAsync(action: { type: string; payload: any }): any {
  // const { userId, email, type, successURL, subscriptionId } = action.payload.user;
  const { userId, subscriptionId, history } = action.payload.user;
  try {
    const stripe = yield stripePromise;
    const values: any = localStorage.getItem('subscription::price'); /// get price selected by user for subscription
    const { priceId, priceType } = JSON.parse(values);
    // console.log('priceType: ', priceId, priceType);
    if (stripe && priceId) {
      // create session checkout
      const { data } = yield axios.post(
        `${stripe_base_Url}/tweetsky/update-subscription`,
        {
          userId,
          subscriptionId,
          priceId,
          priceType
        }
      );
      console.log('session data: ', data);
      // yield put(AuthAction.fetchCurrentUserSuccess(data.data));
      yield put(AuthAction.fetchCurrentUserStart(userId));
      yield put(SubscriptionAction.completeSubscriptionProcess());
      localStorage.removeItem('subscription::price'); // remove price from localstorage
      history.push('/new-thread');
      yield put(showSnackbarNotification('success', 'Subscription updated successfully.'));
    }
  } catch (error) {
      console.error('ERROR: ', error);
  }
}

export function* subscribeUserStartWatcher() {
  yield takeLatest(SubscriptionType.SUBSCRIBE_USER_START, subscribeUserAsync);
}

export function* updateSubscriptionStartWatcher() {
  yield takeLatest(SubscriptionType.UPDATE_SUBSCRIPTION_START, updateSubscriptionAsync);
}

export function* subscriptionSagas() {
  yield all([
    call(subscribeUserStartWatcher),
    call(updateSubscriptionStartWatcher)
  ]);
}

import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const AvatarComponent = (props: any) => {
  return (
    <Avatar
      style={{
        color: '#fff',
        backgroundColor: '#ccc',
        textTransform: 'capitalize'
      }}
    >
      {props.avatar || <AccountCircleIcon fontSize={'large'} />}
    </Avatar>
  );
};

export default AvatarComponent;

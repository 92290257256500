import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import ReactWordcloud from "react-wordcloud";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    content: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    wordCloudContainer: {
      width: "70%",
      height: "237px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    tableContainer: {
      maxWidth: "250px",
      maxHeight: " 240px",
      overflow: "auto",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
    },
    table: {},
  })
);
const HashtagCloud = ({ hastagArray }: any) => {
  const classes = useStyles();
  console.log(hastagArray);
  return (
    <Box className={classes.root}>
      <Typography variant="h5" gutterBottom color={"textPrimary"}>
        Hashtag cloud
      </Typography>
      <Box className={classes.content}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ maxWidth: "105px" }}>Hashtag</TableCell>
                <TableCell align="center" style={{ maxWidth: "105px" }}>
                  No. of uses
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hastagArray.map((row: any) => (
                <TableRow key={row.text}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ maxWidth: "105px" }}
                  >
                    {row.text}
                  </TableCell>
                  <TableCell align="center">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={classes.wordCloudContainer}>
          <ReactWordcloud
            maxWords={25}
            words={hastagArray}
            options={{
              enableTooltip: false,
              fontSizes: [15, 50],
             
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HashtagCloud;

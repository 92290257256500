export default Object.freeze({
    MONTHLY_SINGLE: 'MONTHLY_SINGLE',
    MONTHLY_BASIC: 'MONTHLY_BASIC',
    MONTHLY_ADVANCED: 'MONTHLY_ADVANCED',
    MONTHLY_TEAM: 'MONTHLY_TEAM',
    YEARLY_SINGLE: 'YEARLY_SINGLE',
    YEARLY_BASIC: 'YEARLY_BASIC',
    YEARLY_ADVANCED: 'YEARLY_ADVANCED',
    YEARLY_TEAM: 'YEARLY_TEAM',
});

import {all, call, takeLatest} from "redux-saga/effects";
import { TOGGLE_SIDEBAR } from "./siteCoordinator.types";

function toggleSidebarAsync(action: { type: string; payload: string[] }): any {
    // console.log('here is clicked value: ', action.payload);
}

export function* toggleSidebarStart() {
    yield takeLatest(TOGGLE_SIDEBAR, toggleSidebarAsync);
}

export function* siteCoordinatorSagas() {
    yield all([
        call(toggleSidebarStart)
    ]);
}
import * as SiteTypes from "./siteCoordinator.types";
import SectionTab from "../../constant/SectionTab";

const INITIAL_STATE = {
  loading: false,
  darkMode: false,
  sidebar: true,
  currentTab: SectionTab.NewThread,
  logoutModal: false,
  success: null,
  error: null,
};

const siteCoordinatorReducer = (state = INITIAL_STATE, action: any) => {
  switch (action?.type) {
    case SiteTypes.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar: action?.payload?.value
      }

    case SiteTypes.TOGGLE_DARK_MODE:
      return {
        ...state,
        darkMode: action?.payload?.value
      }

    case SiteTypes.SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action?.payload?.value
      }

    case SiteTypes.SET_LOGOUT_MODAL:
      return {
        ...state,
        logoutModal: action.payload.value
      }

    default:
      return state;
  }
};

export default siteCoordinatorReducer;

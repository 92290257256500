export function calculatePositionFromElement(element: any) {
  // Calc position data
  let htmlElement = element;
  let top = 0;
  let left = 0;
  do {
    top += htmlElement ? htmlElement.offsetTop : 0;
    left += htmlElement ? htmlElement.offsetLeft : 0;
    htmlElement = htmlElement ? htmlElement.offsetParent : null;
  } while (htmlElement);
  return {
    x: left,
    y: top
  };
}

export function scrollToPricing(smooth = true) {
  const elem = document.getElementById('pricing');
  const pos = calculatePositionFromElement(elem);
  const newTop = pos.y < 68 ? 0 : pos.y - 68;
  window.scrollTo({ top: newTop, behavior: smooth ? 'smooth' : 'auto' });
}
export function scrollToFeatures(smooth = true) {
  const elem = document.getElementById('features');
  const pos = calculatePositionFromElement(elem);
  const newTop = pos.y < 68 ? 0 : pos.y - 68;
  console.log(newTop);
  window.scrollTo({ top: newTop, behavior: smooth ? 'smooth' : 'auto' });
}

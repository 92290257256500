import * as SiteTypes from "./siteCoordinator.types";

export const toggleSidebar = (value: boolean) => ({
  type: SiteTypes.TOGGLE_SIDEBAR,
  payload: { value }
});

export const toggleDarKMode = (value: boolean) => ({
  type: SiteTypes.TOGGLE_DARK_MODE,
  payload: { value }
});

export const setCurrentTab = (value: string) => ({
  type: SiteTypes.SET_CURRENT_TAB,
  payload: { value }
});

export const setLogoutModal = (value: boolean) => ({
  type: SiteTypes.SET_LOGOUT_MODAL,
  payload: { value }
});

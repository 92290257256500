import { ITweetPost } from 'src/interfaces';
import * as TweetType from 'src/store/types/types';

export const resetState = () => ({
  type: TweetType.RESET_STATE
});

export const startPostTweet = (
  tweetArray: string[],
  tweetMedia: any[],
  history: any
) => ({
  type: TweetType.START_TWEET_POST_PROCESS,
  payload: { tweetArray, tweetMedia, history }
});

export const tweetPostError = (error: string) => ({
  type: TweetType.TWEET_POST_ERROR,
  payload: error
});

export const startPostTweetDraft = (
  tweetArray: string[],
  tweetMedia: any[],
  history: any
) => ({
  type: TweetType.TWEET_START_SAVE_AS_DRAFT,
  payload: { tweetArray, tweetMedia, history }
});

export const startGetTweetDraft = (id: string) => ({
  type: TweetType.TWEET_START_DRAFT_GET,
  payload: id
});

export const startGetTweetDraftSuccess = (posts: any) => ({
  type: TweetType.TWEET_START_DRAFT_GET_SUCESS,
  payload: posts
});

export const tweetDraftPostSuccess = ({
  post,
  postId,
  updatedAt,
  createdAt,
  media_URLs,
  id
}: ITweetPost) => ({
  type: TweetType.TWEET_SAVE_AS_DRAFT_SUCCESS,
  payload: { post, postId, updatedAt, createdAt, id, media_URLs }
});
export const tweetPostDraftError = () => ({
  type: TweetType.TWEET_DRAFT_ERROR
});
export const tweetPostSuccess = (post: ITweetPost) => ({
  type: TweetType.TWEET_POST_SUCCESS,
  payload: post
});

export const startTweetDraftDelete = (postId: string) => ({
  type: TweetType.TWEET_DRAFT_DELETE_START,
  payload: {
    postId
  }
});

export const tweetDraftDeleteSuccess = ({
  postId,
  userId
}: {
  postId: string;
  userId: string;
}) => ({
  type: TweetType.TWEET_DRAFT_DELETE_SUCCESS,
  payload: {
    postId,
    userId
  }
});

export const startTweetDraftUpdate = (
  postId: string,
  post: string[],
  tweetMedia: any[],
  history: any
) => ({
  type: TweetType.TWEET_DRAFT_UPDATE_START,
  payload: {
    postId,
    post,
    tweetMedia,
    history
  }
});

export const tweetDraftUpdateSuccess = ({
  post,
  updatedAt,
  postId
}: {
  post: string[];
  updatedAt: string;
  postId: string;
}) => ({
  type: TweetType.TWEET_DRAFT_UPDATE_SUCCESS,
  payload: { post, updatedAt, postId }
});

export const startTweetScheduleGet = () => ({
  type: TweetType.TWEET_SCHEDULE_GET_START
});
export const startTweetScheduleGetSuccess = (posts: any) => ({
  type: TweetType.TWEET_SCHEDULE_GET_SUCESS,
  payload: posts
});

export const startTweetScheduledPost = ({
  tweetArray,
  tweetMedia,
  scheduleAt,
  filters,
  history
}: {
  tweetArray: string[];
  scheduleAt: string;
  filters: string;
  history: any;
  tweetMedia: any;
}) => ({
  type: TweetType.TWEET_SCHEDULE_START,
  payload: {
    tweetArray,
    scheduleAt,
    filters,
    history,
    tweetMedia
  }
});

export const tweetScheduleError = () => ({
  type: TweetType.TWEET_SCHEDULE_ERROR
});

export const tweetScheduledPostSuccess = ({
  postId,
  post,
  scheduleAt,
  createdAt,
  updatedAt,
  id,
  sk,
  filters,
  media_URLs
}: ITweetPost) => ({
  type: TweetType.TWEET_SCHEDULE_SUCCESS,
  payload: {
    id,
    sk,
    postId,
    post,
    scheduleAt,
    createdAt,
    updatedAt,
    filters,
    media_URLs
  }
});

export const tweetSchedulePostUpdateStart = ({
  post,
  scheduleAt,
  postId,
  tweetMedia,
  history
}: {
  post: string[];
  scheduleAt: string;
  postId: string;
  tweetMedia: any[];
  history: any;
}) => ({
  type: TweetType.TWEET_SCHEDULE_UPDATE_START,
  payload: {
    post,
    scheduleAt,
    postId,
    tweetMedia,
    history
  }
});

export const tweetScheduledPostUpdateSuccess = ({
  scheduleAt,
  post,
  updatedAt,
  postId,
  filters,
  history
}: {
  post: string[];
  scheduleAt: string;
  updatedAt: string;
  postId: string;
  filters: string;
  history: string;
}) => ({
  type: TweetType.TWEET_SCHEDULE_UPDATE_SUCCESS,
  payload: {
    scheduleAt,
    post,
    updatedAt,
    postId,
    filters,
    history
  }
});

export const fetchHashtagStart = ({ keyword }: { keyword: string }) => ({
  type: TweetType.FETCH_HASHATG_START,
  payload: { keyword }
});

export const fetchHashtagSuccess = (result: any) => ({
  type: TweetType.FETCH_HASHATG_SUCCESS,
  payload: result
});

export const fetchHashtagFail = (error: any) => ({
  type: TweetType.FETCH_HASHATG_FAILURE,
  payload: error
});

export const tweetSchedulePostDeleteStart = (sk: string, history?: any) => ({
  type: TweetType.TWEET_SCHEDULE_DELETE_START,
  payload: { sk, history }
});

export const tweetSchedulePostDeleteSuccess = (postId: string) => ({
  type: TweetType.TWEET_SCHEDULE_DELETE_SUCCESS,
  payload: postId
});

export const tweetHistoryFetchStart = (userId: string) => ({
  type: TweetType.TWEET_HISTORY_GET_START,
  payload: userId
});

export const tweetHistoryGetSuccess = (posts: {
  post: string[];
  postId: string;
  id: string;
}) => ({
  type: TweetType.TWEET_HISTORY_GET_SUCESS,
  payload: posts
});

export const tweetHistoryPostDeleteStart = (sk: string, history?: any) => ({
  type: TweetType.TWEET_HISTORY_DELETE_START,
  payload: { sk, history }
});

export const tweetHistoryPostDeleteSuccess = (postId: string) => ({
  type: TweetType.TWEET_HISTORY_DELETE_SUCCESS,
  payload: postId
});

export const tweetHistoryError = () => ({
  type: TweetType.TWEET_HISTORY_ERROR
});

export const tweetRetweetStart = ({ sk }: { sk: string }) => ({
  type: TweetType.TWEET_RETWEET_START,
  payload: { sk }
});

export const tweetRetweetSuccess = (post: ITweetPost) => ({
  type: TweetType.TWEET_RETWEET_SUCCESS,
  payload: post
});

export const tweetUnretweetStart = ({ sk }: { sk: string }) => ({
  type: TweetType.TWEET_UNRETWEET_START,
  payload: { sk }
});

export const tweetUnretweetSuccess = (post: ITweetPost) => ({
  type: TweetType.TWEET_UNRETWEET_SUCCESS,
  payload: post
});

export const tweetTimelineFetchStart = ({
  userId,
  paginationNextToken
}: {
  userId: string;
  paginationNextToken: string;
}) => ({
  type: TweetType.FETCH_TWEET_TIMELINE_START,
  payload: { userId, paginationNextToken }
});

export const tweetTimelineFetchSucess = ({
  tweetList,
  paginationNextToken
}: {
  tweetList: any[];
  paginationNextToken: String;
}) => ({
  type: TweetType.FETCH_TWEET_TIMELINE_SUCCESS,
  payload: {
    tweetList,
    paginationNextToken
  }
});

export const tweetTimelineFetchFail = () => ({
  type: TweetType.FETCH_TWEET_TIMELINE_FAIL
});

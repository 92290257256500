import * as RSSTypes from './rssFeed.types';

const INITIAL_STATE = {
    isFetching: false,
    isSearching: false,
    url: null,
    results: {},
    history: [],
    errors: {}
};

const rssFeedReducer = (state = INITIAL_STATE, action: any) => {
    let updatedState: any = {};
    switch (action.type) {
        case RSSTypes.FETCH_RSS_START:
            return {
                ...state,
                isSearching: true,
                url: action.payload.url
            };

        case RSSTypes.FETCH_RSS_SUCCESS:
            updatedState = {
                ...state,
                isSearching: false,
                results: {}
            };
            action.payload.data.forEach((value: any) => {
                updatedState.results[value.rssId] = value;
            });
            updatedState.history = [...state.history, { url_link: action.payload.url }];
            return updatedState;

        case RSSTypes.FETCH_RSS_FAIL:
            return {
                ...state,
                isSearching: false,
                errors: action.payload
            };

        case RSSTypes.FETCH_RSS_HISTORY_START:
            return {
                ...state,
                isFetching: true
            };

        case RSSTypes.FETCH_RSS_HISTORY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                history: action.payload
            };

        case RSSTypes.FETCH_RSS_HISTORY_FAIL:
            return {
                ...state,
                isFetching: false,
                errors: action.payload
            };

        default:
            return state;
    }
}

export default rssFeedReducer;

import { connect } from 'react-redux';
import { RootState } from 'src/store';
import { showSnackbarNotification } from 'src/store/notification/notification.actions';
import {
  tweetHistoryPostDeleteStart,
  tweetRetweetStart,
  tweetSchedulePostDeleteStart,
  tweetUnretweetStart
} from 'src/store/tweet/tweetAction';

const mapStateToProps = (state: RootState) => ({
  authState: state.auth,
  tweetskyState: state.tweetsky,
  accountState: state.account
});

const mapDispatchToProps = (dispatch: any) => ({
  onShowNotification: (status: string, message: string) =>
    dispatch(showSnackbarNotification(status, message)),
  onDeleteSchedulePost: (sk: string, history?: any) =>
    dispatch(tweetSchedulePostDeleteStart(sk, history)),
  onDeleteHistoryPost: (sk: string, history?: any) =>
    dispatch(tweetHistoryPostDeleteStart(sk, history)),
    onRetweetThread:({sk}:{sk:string}) => dispatch(tweetRetweetStart({sk})),
    onUnretweetThread:({sk}:{sk:string}) => dispatch(tweetUnretweetStart({sk})),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;

import { makeStyles, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React from "react";
import { IoMdAnalytics } from "react-icons/io";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: any) => ({
  emptyIcon: {
    maxWidth: "280px",
    marginBottom: 5,
  },
  emptyScreen: {
    textAlign: "center",
    marginTop: 5,
  },
}));
function AnalyticsEmptyScreen(props: {
  user?: {
    username: string;
    authToken: String;
    authTokenSecret: String;
  };
}) {
  const classes = useStyles();
  const theme: any = useTheme();
  const { user } = useSelector((state: any) => state.auth);
  return (
    <div className={classes.emptyScreen}>
      {user &&
      user.subscriptionStatus &&
      user.subscriptionStatus !== "canceled" ? (
        <>
          <IoMdAnalytics
            color={theme.palette.primary.main}
            fontSize={"200px"}
          />
          <Typography color={"textPrimary"}>
            Your data is now being collected. Please wait at least a week to see
            your analytics.
          </Typography>
        </>
      ) : (
        user && (
          <>
            <IoMdAnalytics
              color={theme.palette.primary.main}
              fontSize={"200px"}
            />
            <Typography color={"textPrimary"}>
              Subscribe to see your tweet analytics.
            </Typography>
          </>
        )
      )}
    </div>
  );
}

export default AnalyticsEmptyScreen;

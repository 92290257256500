import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import * as Yup from 'yup';
import {Field, Form, Formik} from 'formik';
import {makeStyles} from '@material-ui/core/styles';
import {TextField} from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
}));

interface ModalProps {
  open: boolean,
  handleOpen: (value: boolean) => void,
  title: string,
  user: any,
  handleAction: (value: any) => void,
}

const ContentModal = (props: ModalProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    open,
    handleOpen,
    title,
    user,
    handleAction
  } = props;

  const handleSubmit = async (values: any) => {
    const obj = {
      email: values.email,
      vendorId: user.id
    }
    handleAction(obj);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleOpen}
        BackdropComponent={Backdrop}
        maxWidth="sm"
        BackdropProps={{
          timeout: 500
        }}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize={true}
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email('Invalid email address')
                .required('Please add your email address')
                .max(254, 'Really!')
            })}
            onSubmit={handleSubmit}
          >
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    id="email"
                    name="email"
                    variant="outlined"
                    required
                    fullWidth
                    label="Email"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth={matchesXS}
                className={classes.submit}
              >
                Submit
              </Button>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ContentModal;

import { connect } from 'react-redux';
import { RootState } from 'src/store';
import { showSnackbarNotification } from 'src/store/notification/notification.actions';
import {
  startTweetDraftDelete,
  tweetHistoryPostDeleteStart,
  tweetRetweetStart,
  tweetUnretweetStart
} from 'src/store/tweet/tweetAction';

const mapStateToProps = (state: RootState) => ({
  accountState: state.account,
  tweetskyState: state.tweetsky
});

const mapDispatchToProps = (dispatch: any) => ({
  onShowNotification: (status: string, message: string) =>
    dispatch(showSnackbarNotification(status, message)),
  onDeleteDraft: (postId: string) => dispatch(startTweetDraftDelete(postId)),
  onDeleteHistoryPost: (sk: string, history?: any) =>
    dispatch(tweetHistoryPostDeleteStart(sk, history)),
  onRetweetThread: ({ sk }: { sk: string }) =>
    dispatch(tweetRetweetStart({ sk })),
  onUnretweetThread: ({ sk }: { sk: string }) =>
    dispatch(tweetUnretweetStart({ sk }))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;

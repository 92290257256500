import React from "react";
import { Box, Container, Typography, List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import feature1 from "src/assets/feature1.png";
import feature2 from "src/assets/feature2.png";
import feature3 from "src/assets/feature3.png";
import feature4 from "src/assets/feature4.png";
import feature5 from "src/assets/feature5.png";
import history from "src/assets/history.jpg";
import rssfeed from "src/assets/rssfeed.jpg";
import share_image_video from "src/assets/share_image_video.jpg";
import analytics from "src/assets/analytic.png";

// import feature from 'src/assets/feature.png';

const useStyles = makeStyles((theme) => ({
  button: {
    background: "#fff",
    color: "#000",
    padding: "10px 14px",
  },
  featureSection: {
    textAlign: "center",
    marginTop: 90,
  },
  featureHeader: {
    fontSize: "2rem",
    lineHeight: "49px",
    marginBottom: "10px",
  },
  featureSubtitle: {
    fontSize: "18px",
    lineHeight: "24.52px",
    marginBottom: 50,
    color: "#585858",
  },
  featureItem: {
    display: "flex",
    marginBottom: "20px",
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },
    "& > *": {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
      flexDirection: "column",
      "&:nth-of-type(even)": {
        flexDirection: "column",
      },
      "& > *": {
        width: "100%",
      },
    },
  },
  featureImg: {
    "& > img": {
      margin: "0 auto",
      display: "block",
      width: "100%",
      [theme.breakpoints.down(1350)]: {
        maxHeight: "360px",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
    },
  },
  featureText: {
    padding: "10px 0 10px 25px",
    "& :first-child": {
      fontSize: "1.8rem",
    },
    "& :nth-child(2)": {
      fontSize: "18px",
      margin: "10px 0",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      padding: "10px 0",
    },
  },
}));

export const Feature = (props: any) => {
  const classes = useStyles();

  return (
    <>
      <Container
        id={"features"}
        maxWidth={"lg"}
        className={classes.featureSection}
      >
        <Typography className={classes.featureHeader} component={"h2"}>
          Tweetsky Features
        </Typography>
        {/* <Typography className={classes.featureSubtitle}>
          Be Productive with Twitter. Use Tweetsky to schedule your tweets.
          Tweetsky is the only tool for Marketers, Entrepreneurs and Businesses.
        </Typography> */}
        <List>
          <ListItem className={classes.featureItem}>
            <Box className={classes.featureImg}>
              <img src={share_image_video} alt="feature" />
            </Box>

            <Box className={classes.featureText}>
              <Typography component={"h3"}>
                Share Images, GIFs, and Videos
              </Typography>
              <Typography>
                Just a few seconds is all it takes to share long, beautiful
                twitter threads complete with images, GIFs, and videos. This is
                a great way to increase engagement and reach for your posts.
              </Typography>
            </Box>
          </ListItem>
          <ListItem className={classes.featureItem}>
            <Box className={classes.featureImg}>
              <img src={feature1} alt="feature" />
            </Box>
            <Box className={classes.featureText}>
              <Typography component={"h3"}>Schedule Posts</Typography>
              <Typography>
                Schedule your tweets in advance so that you can plan and post
                them days, weeks, or even months ahead of time.
              </Typography>
            </Box>
          </ListItem>
          <ListItem className={classes.featureItem}>
            <Box className={classes.featureImg}>
              <img src={history} alt="feature" />
            </Box>

            <Box className={classes.featureText}>
              <Typography component={"h3"}>Tweet History</Typography>
              <Typography>
                Find and manage all your historical tweets from one place.
                Tweetsky lets you view, delete and retweet old tweets.
              </Typography>
            </Box>
          </ListItem>
          <ListItem className={classes.featureItem}>
            <Box className={classes.featureImg}>
              <img src={rssfeed} alt="feature" />
            </Box>

            <Box className={classes.featureText}>
              <Typography component={"h3"}>
                RSS Feeds for Content Ideas
              </Typography>
              <Typography>
                Discover new content to tweet using RSS Feeds from popular feeds
                including CNN, Yahoo News, USA Today, Cointelegraph, etc. Then
                RSS to Tweet New Threads in seconds.
              </Typography>
            </Box>
          </ListItem>
          <ListItem className={classes.featureItem}>
            <Box className={classes.featureImg}>
              <img src={analytics} alt="feature" />
            </Box>

            <Box className={classes.featureText}>
              <Typography component={"h3"}>Tweet Analytics</Typography>
              <Typography>
                Analyze your tweets performance with data on your followers,
                impressions, retweets, and more.
              </Typography>
            </Box>
          </ListItem>
        </List>
      </Container>
    </>
  );
};

export default Feature;

import * as TweetTypes from '../types/types';

const init_state = {
  loading: false,
  draftLoading: false,
  scheduleLoading: false,
  historyLoading: false,
  analyticsLoading: true,
  isFetching: false,
  error: null,
  success: null,
  drafts: {},
  scheduledPost: {},
  historyData: {},
  tweetTimeline: [],
  hashtagList: [],
  paginationNextToken: null
};

export const tweetReducer = (state: any = init_state, action: any) => {
  switch (action.type) {
    case TweetTypes.RESET_STATE:
      return {
        loading: false,
        draftLoading: false,
        scheduleLoading: false,
        historyLoading: false,
        error: null,
        success: null,
        drafts: {},
        scheduledPost: {},
        historyData: {},
        hashtagList: []
      };
    case TweetTypes.START_TWEET_POST_PROCESS:
      return { ...state, loading: true, error: null, success: null };

    case TweetTypes.TWEET_POST_SUCCESS:
      let newPost = action.payload;
      let updatedHistory: any = { ...state.historyData };
      updatedHistory[newPost.postId] = newPost;
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        historyData: updatedHistory
      };

    case TweetTypes.TWEET_POST_ERROR:
      return { ...state, loading: false, error: action.payload, success: null };

    case TweetTypes.TWEET_DRAFT_ERROR:
      return { ...state, draftLoading: false, success: null };

    case TweetTypes.TWEET_START_DRAFT_GET:
      return { ...state, draftLoading: true, error: null, success: null };

    case TweetTypes.TWEET_START_DRAFT_GET_SUCESS:
      let drafts: any = {};
      action.payload.forEach(
        (element: any) => (drafts[element.postId] = element)
      );

      return { ...state, draftLoading: false, drafts };

    case TweetTypes.TWEET_START_SAVE_AS_DRAFT:
      return { ...state, draftLoading: true, error: null, success: null };

    case TweetTypes.TWEET_SAVE_AS_DRAFT_SUCCESS:
      let newDrafts: any = state.drafts;
      let { postId } = action.payload;
      newDrafts[postId] = action.payload;
      return { ...state, draftLoading: false, drafts: newDrafts };

    case TweetTypes.TWEET_DRAFT_DELETE_START:
      return { ...state, draftLoading: true };

    case TweetTypes.TWEET_DRAFT_DELETE_SUCCESS:
      let filteredDrafts: any = state.drafts;

      delete filteredDrafts[action.payload.postId];

      return { ...state, draftLoading: false, drafts: filteredDrafts };

    case TweetTypes.TWEET_DRAFT_UPDATE_START:
      return { ...state, draftLoading: true };

    case TweetTypes.TWEET_DRAFT_UPDATE_SUCCESS:
      let updatedDrafts: any = state.drafts;
      updatedDrafts[action.payload.postId].post = action.payload.post;
      updatedDrafts[action.payload.postId].updatedAt = action.payload.updatedAt;
      return { ...state, draftLoading: false, drafts: updatedDrafts };

    case TweetTypes.TWEET_SCHEDULE_GET_START:
      return { ...state, scheduleLoading: true, error: null, success: null };

    case TweetTypes.TWEET_SCHEDULE_GET_SUCESS:
      let scheduledPost: any = {};

      action.payload.forEach(
        (element: any) => (scheduledPost[element.postId] = element)
      );

      return { ...state, scheduleLoading: false, scheduledPost };

    case TweetTypes.TWEET_SCHEDULE_ERROR:
      return { ...state, scheduleLoading: false };

    case TweetTypes.TWEET_SCHEDULE_START:
      return { ...state, scheduleLoading: true };

    case TweetTypes.TWEET_SCHEDULE_SUCCESS:
      let updatedScheduledPost: any = state.scheduledPost;
      updatedScheduledPost[action.payload.postId] = action.payload;

      return {
        ...state,
        scheduleLoading: false,
        scheduledPost: updatedScheduledPost
      };

    case TweetTypes.TWEET_SCHEDULE_UPDATE_START:
      return { ...state, scheduleLoading: true };

    case TweetTypes.TWEET_SCHEDULE_UPDATE_SUCCESS:
      let newScheduledPost: any = state.scheduledPost;

      newScheduledPost[action.payload.postId].post = action.payload.post;
      newScheduledPost[action.payload.postId].updatedAt =
        action.payload.updatedAt;
      newScheduledPost[action.payload.postId].scheduleAt =
        action.payload.scheduleAt;
      newScheduledPost[action.payload.postId].filters = action.payload.filters;
      return {
        ...state,
        scheduledPost: newScheduledPost,
        scheduleLoading: false
      };

    case TweetTypes.TWEET_SCHEDULE_DELETE_START:
      return { ...state, scheduleLoading: true };

    case TweetTypes.TWEET_SCHEDULE_DELETE_SUCCESS:
      let filteredSchedulePost: any = state.scheduledPost;
      let newHistoryPost: any = state.historyData;

      delete filteredSchedulePost[action.payload];
      delete newHistoryPost[action.payload];
      return {
        ...state,
        scheduleLoading: false,
        historyLoading: false,
        scheduledPost: filteredSchedulePost,
        historyData: newHistoryPost
      };

    case TweetTypes.TWEET_HISTORY_GET_START:
      return { ...state, historyLoading: true };

    case TweetTypes.TWEET_HISTORY_GET_SUCESS:
      let historyData: any = {};
      action.payload.forEach(
        (element: any) => (historyData[element.postId] = element)
      );
      return { ...state, historyLoading: false, historyData };

    case TweetTypes.TWEET_HISTORY_DELETE_START:
      return { ...state, historyLoading: true };

    case TweetTypes.TWEET_HISTORY_DELETE_SUCCESS:
      let filteredHistoryPost: any = state.historyData;

      delete filteredHistoryPost[action.payload];

      return {
        ...state,
        historyLoading: false,
        historyData: filteredHistoryPost
      };

    case TweetTypes.TWEET_RETWEET_START:
      return {
        ...state,
        historyLoading: true
      };
    case TweetTypes.TWEET_RETWEET_SUCCESS:
      let newUpdatedHistory = state.historyData;
      let newUPdatedSchedulePOst = state.scheduledPost;

      newUPdatedSchedulePOst[action.payload.postId] = action.payload;

      newUpdatedHistory[action.payload.postId] = action.payload;

      return {
        ...state,
        historyLoading: false,
        scheduleLoading: false,
        historyData: newUpdatedHistory,
        scheduledPost: newUPdatedSchedulePOst
      };
    case TweetTypes.TWEET_UNRETWEET_START:
      return {
        ...state,
        historyLoading: true
      };
    case TweetTypes.TWEET_UNRETWEET_SUCCESS:
      let newUnretweetUpdatedHistory = state.historyData;
      let newUnretweetUpdatedSchedule = state.scheduledPost;

      if (action.payload.sk.split('#')[1].toLowerCase() === 'schedule') {
        newUnretweetUpdatedSchedule[action.payload.postId] = action.payload;
      } else {
        newUnretweetUpdatedHistory[action.payload.postId] = action.payload;
      }

      return {
        ...state,
        historyLoading: false,
        historyData: newUnretweetUpdatedHistory,
        scheduledPost: newUnretweetUpdatedSchedule
      };

    case TweetTypes.TWEET_HISTORY_ERROR:
      return { ...state, historyLoading: false };

    case TweetTypes.FETCH_TWEET_TIMELINE_START:
      return { ...state, analyticsLoading: true };

    case TweetTypes.FETCH_TWEET_TIMELINE_SUCCESS:
      let { tweetList, paginationNextToken } = action.payload;
      return {
        ...state,
        tweetTimeline: [...state.tweetTimeline, ...tweetList],
        analyticsLoading: false,
        paginationNextToken
      };

    case TweetTypes.FETCH_TWEET_TIMELINE_FAIL:
      return { ...state, analyticsLoading: false };

    case TweetTypes.FETCH_HASHATG_START:
      return { ...state, isFetching: true };
    case TweetTypes.FETCH_HASHATG_SUCCESS:
      return { ...state, isFetching: false, hashtagList: action.payload };

    case TweetTypes.FETCH_HASHATG_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    default:
      return state;
  }
};

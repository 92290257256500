import { connect } from 'react-redux';
import { RootState } from 'src/store';
import { showSnackbarNotification } from 'src/store/notification/notification.actions';
import { tweetTimelineFetchStart } from 'src/store/tweet/tweetAction';

const mapStateToProps = (state: RootState) => ({
  tweetskyState: state.tweetsky,
  accountState: state.account,
  authState: state.auth,

  tweetAnalytics: state.tweetAnalytics
});

const mapDispatchToProps = (dispatch: any) => ({
  onShowNotification: (status: string, message: string) =>
    dispatch(showSnackbarNotification(status, message)),
  onFetchMoreTweet: ({
    userId,
    paginationNextToken
  }: {
    userId: string;
    paginationNextToken: string;
  }) => dispatch(tweetTimelineFetchStart({ userId, paginationNextToken }))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;

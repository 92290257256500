import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import IconEmpty from "../../../assets/emptyIcon.svg";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: any) => ({
  emptyIcon: {
    maxWidth: "280px",
    marginBottom: 5,
  },
  emptyScreen: {
    textAlign: "center",
    marginTop: 5,
  },
}));
function EmptyScreen(props: {
  user?: {
    username: string;
    authToken: String;
    authTokenSecret: String;
  };
}) {
  const classes = useStyles();
  const { user } = useSelector((state: any) => state.auth);
  return (
    <div className={classes.emptyScreen}>
      <img className={classes.emptyIcon} src={IconEmpty} alt="empty" />
      {user ? (
        <Typography color="textSecondary">There is no data yet.</Typography>
      ) : (
        <Typography color="textSecondary">Log in to see your data.</Typography>
      )}{" "}
    </div>
  );
}

export default EmptyScreen;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core';
import Routes from './routes/Routes.component';
import { RootState } from './store';
import { loadCurrentUserStart } from './store/auth/authAction';
import Notifications from './pages/Notifications';
import {
  setCurrentTab,
  toggleDarKMode
} from './store/siteCoordinator/siteCoordinator.actions';
import ScrollToTop from './components/scrollToTop';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import { Layout } from "./components/layout";

let theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(29, 155, 240)'
    },
    secondary: {
      main: '#8338EC'
    }
  },
  typography: {
    button: {
      textTransform: 'capitalize'
    }
  }
});
const lightTheme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: 'rgb(237 237 237)',
      paper: 'rgb(255 255 255)'
    },
    primary: {
      main: 'rgb(29, 155, 240)',
      contrastText: 'rgb(255 255 255)',
      A100: 'rgba(0, 0, 0, 0.04)',
      A200: 'rgb(255 255 255)',
      A400: 'rgb(119 119 119)'
    },
    secondary: {
      main: 'rgb(220, 0, 78)',
      contrastText: 'rgb(255 255 255)'
    }
  },
  typography: {
    button: {
      textTransform: 'capitalize'
    }
  }
});
const darkTheme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: 'rgb(48 48 48)',
      paper: 'rgb(66 66 66)'
    },
    primary: {
      main: 'rgb(29, 155, 240)',
      contrastText: 'rgb(255 255 255)',
      A100: 'rgb(0 0 0 / 8%)',
      A200: 'rgb(66 66 66)',
      A400: 'rgb(168 162 162)'
    },
    secondary: {
      main: 'rgb(220, 0, 78)',
      contrastText: 'rgb(255 255 255)'
    },
    text: {
      primary: '#E0E0E2'
    }
  },

  typography: {
    button: {
      textTransform: 'capitalize'
    }
  }
});

function App() {
  const dispatch = useDispatch();
  const currentPath = window.location.pathname.split('/')[1];
  const { darkMode } = useSelector((state: RootState) => state.siteCoordinator);

  const checkDarkThemeFromLocalStorage = useCallback(() => {
    let darkModeFromLocalStorage = localStorage.getItem('darkMode');
    if (darkModeFromLocalStorage) {
      if (darkModeFromLocalStorage === 'true') {
        dispatch(toggleDarKMode(true));
      } else {
        dispatch(toggleDarKMode(false));
      }
    } else {
      localStorage.setItem('darkMode', 'true');
    }
  }, [dispatch]);

  React.useEffect(() => {
    checkDarkThemeFromLocalStorage();
    dispatch(loadCurrentUserStart());
  }, [dispatch, checkDarkThemeFromLocalStorage]);

  React.useEffect(() => {
    dispatch(setCurrentTab(currentPath));
  }, [currentPath, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <Router>
          <ScrollToTop />
          <Notifications />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Routes />
          </MuiPickersUtilsProvider>
        </Router>
      </ThemeProvider>
    </ThemeProvider>
  );
}

export default App;

import {
  Backdrop,
  Box,
  createStyles,
  Fade,
  makeStyles,
  Modal
} from '@material-ui/core';
import React from 'react';
import ReactGiphySearchbox from 'react-giphy-searchbox';
import { gifApiKey } from 'src/constant/twitterApi';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center'
    },
    gifRoot: {
      background: '#fff',
      borderRadius: '10px',
      minWidth: '450px',
      textAlign: 'center',
      padding: '10px',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        minWidth: '300px'
      }
    },
    giftWrapper: {
      minHeight: '80vh'
    },
    searchField: {
      width: '100%',
      marginBottom: '10px'
    }
  })
);

function GifContainer(props: any) {
  const classes = useStyles();

  const { setGifModal, openGifModal, handleMediaUpload } = props;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGifModal !== null}
        onClose={setGifModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        className={classes.root}
      >
        <Fade in={openGifModal !== null}>
          <Box className={classes.gifRoot}>
            <ReactGiphySearchbox
              apiKey={gifApiKey}
              onSelect={(gif: any) => {
                handleMediaUpload(openGifModal, null, null, gif);
              }}
              wrapperClassName={classes.gifRoot}
              listWrapperClassName={classes.giftWrapper}
              masonryConfig={[
                { columns: 2, imageWidth: 140, gutter: 10 },
                { mq: '700px', columns: 3, imageWidth: 200, gutter: 10 },
                { mq: '1000px', columns: 4, imageWidth: 220, gutter: 10 }
              ]}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default GifContainer;

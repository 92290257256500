import {
  Box,
  createStyles,
  FormControl,
  makeStyles,
  Select,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  default as likeBarChart,
  default as Highcharts,
  default as ImpressionChart,
  default as RetweetBarChart,
  default as tweetsCountChart,
} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { IAuth } from "src/interfaces";
import EmptyScreen from "../Draft/components/emptyScreen";
import container from "./Analytics.container";
import AnalyticsEmptyScreen from "./components/analyticsEmptyScreen";
import HashtagCloud from "./components/HastagCloud";
import TweetsList from "./components/tweetList";
import { returnHighChartDate } from "./components/utils";
import { subscriptionAccess } from "../../components/utils";

interface IProps {
  accountState: any;
  tweetskyState: any;
  authState: IAuth;
  tweetAnalytics: any;
  onFetchMoreTweet: ({
    userId,
    paginationNextToken,
  }: {
    userId: string;
    paginationNextToken: string;
  }) => void;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      marginTop: 10,
      "& > *": {
        marginBottom: "10px",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    menuItem: {},
    wordCloudContainer: {
      width: "70%",
      height: "237px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  })
);
const Analytics = (props: IProps) => {
  const classes = useStyles();
  const theme: any = useTheme();
  const {
    accountState,
    tweetskyState,
    tweetAnalytics,
    onFetchMoreTweet,
    authState,
  } = props;
  const { user } = authState;
  const { accountId } = accountState;
  const { analyticsLoading, tweetTimeline, paginationNextToken } =
    tweetskyState;
  const {
    impressionCountArray,
    likesCountArray,
    retweetCountArray,
    userProfileCountArray,
    hastagArray,
    followersCountArray,
  } = tweetAnalytics;

  const [isAnalyticsAccess, setAnalyticsAccess] =
    React.useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<number>(7);

  React.useEffect(() => {
    if (
      user &&
      user.subscriptionType &&
      user.subscriptionStatus !== "canceled"
    ) {
      (async function anyName() {
        const access = await subscriptionAccess(user.subscriptionType);
        setAnalyticsAccess(access && access.analytics);
      })();
    }
  }, [user]);

  const followerOptions = {
    title: {
      text: "Daily Followers",
      style: {
        color: theme.palette.text.primary,
      },
    },
    series: [
      {
        name: "Followers",
        data: user && followersCountArray.slice(0, selectedRange),
        color: theme.palette.text.main,
      },
    ],
    legend: {
      itemStyle: {
        color: theme.palette.text.primary,
      },
    },
    xAxis: {
      type: "datetime",
      tickInterval: 24 * 3600 * 1000, // two day gap
      gridLineWidth: 1,
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    yAxis: {
      title: {
        text: "Count",
        style: {
          color: theme.palette.text.primary,
        },
      },
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    plotOptions: {
      line: {
        lineWidth: 4,
      },
    },
    chart: {
      backgroundColor: theme.palette.background.paper,
    },

    tooltip: {
      formatter() {
        return returnHighChartDate(this);
      },
    },
  };

  const impressionOptions = {
    title: {
      text: "Impressions",
      style: {
        color: theme.palette.text.primary,
      },
    },
    plotOptions: {
      line: {
        lineWidth: 4,
      },
    },
    series: [
      {
        name: "Impressions",
        data: user && impressionCountArray.slice(0, selectedRange),
        color: theme.palette.text.main,
      },
    ],
    legend: {
      itemStyle: {
        color: theme.palette.text.primary,
      },
    },
    xAxis: {
      type: "datetime",
      tickInterval: 24 * 3600 * 1000, // two day gap
      gridLineWidth: 1,
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    yAxis: {
      title: {
        text: "Count",
        style: {
          color: theme.palette.text.primary,
        },
      },
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },

    chart: {
      backgroundColor: theme.palette.background.paper,
    },

    tooltip: {
      formatter() {
        return returnHighChartDate(this);
      },
    },
  };

  const likeOptions = {
    chart: {
      type: "column",
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      text: "Likes Count",
      style: {
        color: theme.palette.text.primary,
      },
    },
    plotOptions: {
      series: {
        type: "column",
        lineWidth: 1,
      },
    },
    series: [
      {
        name: "Likes",
        data: user && likesCountArray.slice(0, selectedRange),
        color: theme.palette.text.main,
      },
    ],
    xAxis: {
      type: "datetime",
      tickInterval: 24 * 3600 * 1000, // one day gap
      gridLineWidth: 1,
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    yAxis: {
      title: {
        text: "Count",
        style: {
          color: theme.palette.text.primary,
        },
      },
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    legend: {
      itemStyle: {
        color: theme.palette.text.primary,
      },
    },
  };

  const retweetCountChartOptions = {
    chart: {
      type: "column",
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      text: "Retweets",
      style: {
        color: theme.palette.text.primary,
      },
    },
    plotOptions: {
      series: {
        stacking: "overlap",
      },
    },
    series: [
      {
        name: "Retweets",
        data: user && retweetCountArray.slice(0, selectedRange),
        color: theme.palette.text.main,
      },
    ],
    xAxis: {
      type: "datetime",
      tickInterval: 24 * 3600 * 1000, // one day gap
      gridLineWidth: 1,
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    yAxis: {
      title: {
        text: "Count",
        style: {
          color: theme.palette.text.primary,
        },
      },
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    legend: {
      itemStyle: {
        color: theme.palette.text.primary,
      },
    },
  };

  const userProfileCountChartOptions = {
    chart: {
      type: "column",
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      text: "Profile visits",
      style: {
        color: theme.palette.text.primary,
      },
    },
    plotOptions: {
      series: {
        stacking: "overlap",
      },
    },
    series: [
      {
        name: "User profile visits",
        data: user && userProfileCountArray.slice(0, selectedRange),
        color: theme.palette.text.main,
      },
    ],
    xAxis: {
      type: "datetime",
      tickInterval: 24 * 3600 * 1000, // one day gap
      gridLineWidth: 1,
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    yAxis: {
      title: {
        text: "Count",
        style: {
          color: theme.palette.text.primary,
        },
      },
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    legend: {
      itemStyle: {
        color: theme.palette.text.primary,
      },
    },
  };
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 1);
    return () => clearTimeout();
  }, [selectedRange]);

  return (
    <Box className={classes.root}>
      {user && isAnalyticsAccess ? (
        user.subscriptionStatus &&
        user.subscriptionStatus !== "canceled" &&
        impressionCountArray &&
        impressionCountArray.length >= 7 ? (
          <>
            <FormControl variant="filled" className={classes.formControl}>
              <Select
                native
                value={selectedRange}
                onChange={(e) => {
                  e.preventDefault();
                  setSelectedRange(Number(e.target.value));
                }}
                inputProps={{
                  name: "age",
                  id: "filled-age-native-simple",
                  style: { paddingTop: "14px" },
                }}
              >
                <option value={7}>7 days</option>
                <option disabled={impressionCountArray.length < 15} value={15}>
                  15 days
                </option>
                <option disabled={impressionCountArray.length < 30} value={30}>
                  30 days
                </option>
              </Select>
            </FormControl>
            {!show && (
              <>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={followerOptions}
                />

                <HighchartsReact
                  highcharts={ImpressionChart}
                  options={impressionOptions}
                />
                <HighchartsReact
                  highcharts={likeBarChart}
                  options={likeOptions}
                />
                <HighchartsReact
                  highcharts={RetweetBarChart}
                  options={retweetCountChartOptions}
                />
                <HighchartsReact
                  highcharts={tweetsCountChart}
                  options={userProfileCountChartOptions}
                />
              </>
            )}
            {hastagArray.length > 5 && (
              <HashtagCloud hastagArray={hastagArray} />
            )}

            {/* <TweetsList
              tweetTimeline={tweetTimeline}
              accountId={accountId}
              paginationNextToken={paginationNextToken}
              onFetchMoreTweet={onFetchMoreTweet}
              analyticsLoading={analyticsLoading}
            /> */}
          </>
        ) : (
          <AnalyticsEmptyScreen />
        )
      ) : (
        <EmptyScreen />
      )}
    </Box>
  );
};

export default container(Analytics);

import React from 'react';
import { Route } from 'react-router-dom';

interface Props {
    exact?: boolean;
    component: any;
    layout: any;
    path: string
}

export const RouteWithLayout: React.FC<Props> = ({ layout: Layout, component: Component, path: Path}) => {
    return (
        <Route
            path={Path}
            render={(matchProps) => {
                return (
                    <Layout>
                        <Component {...matchProps} />
                    </Layout>
                );
            }}
        />
    );
};

export default RouteWithLayout;

import { RootState } from 'src/store';
import { connect } from 'react-redux';
import { showSnackbarNotification } from 'src/store/notification/notification.actions';
import { fetchRSSStart, fetchRSSHistoryStart } from 'src/store/rssFeed/rssFeed.actions';

const mapStateToProps = (state: RootState) => ({
    authState: state.auth,
    rssState: state.rssFeed,
    notificationState: state.notification,
});

const mapDispatchToProps = (dispatch: any) => ({
    onShowNotification: (status: string, message: string) =>
        dispatch(showSnackbarNotification(status, message)),
    onFetchRSSStart: (url: string) => dispatch(fetchRSSStart(url)),
    onFetchRSSHistory: () => dispatch(fetchRSSHistoryStart())
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;

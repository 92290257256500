import * as VendorTypes from 'src/store/vendor/vendor.types';

const INITIAL_STATE = {
  loading: false,
  results: {},
  error: {}
};

export const vendorReducer = (state = INITIAL_STATE, action: any) => {
  let updatedState: any = {};
  switch (action.type) {
    case VendorTypes.VENDOR_INVITATION_START:
      return {
        ...state,
        loading: true
      };

    case VendorTypes.VENDOR_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case VendorTypes.VENDOR_INVITATION_FAIL:
      return {
        ...state,
        loading: false
      };

    case VendorTypes.FETCH_USER_VENDOR_START:
      return {
        ...state,
        loading: true
      };

    case VendorTypes.FETCH_USER_VENDOR_SUCCESS:
      updatedState = {
        ...state,
        loading: false,
        results: {}
      };
      action.payload.forEach((value: any) => {
        updatedState.results[value.id] = value;
      });
      return updatedState;

    case VendorTypes.FETCH_USER_VENDOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case VendorTypes.REMOVE_VENDOR_SUCCESS:
      updatedState = {
        ...state,
        loading: false,
      }
      delete updatedState.results[action.payload.id];
      return updatedState;

    default:
      return state;
  }
}

import React from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";

interface AccountRemoveModalProps {
  open: boolean;
  title: string;
  description: any;
  handleClose: (value: boolean) => void;
  removeHandler: (value: any) => void;
}

const RemoveModal: React.FC<AccountRemoveModalProps> = ({
  open,
  title,
  description,
  handleClose,
  removeHandler,
}) => {
  const handleRemove = (e: any) => {
    removeHandler(e);
    handleClose(true);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        maxWidth="sm"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

        <DialogActions
          style={{ padding: "0px 24px 24px", justifyContent: "flex-start" }}
        >
          <Button
            type="button"
            color="secondary"
            variant="contained"
            onClick={handleRemove}
          >
            Remove
          </Button>
          <Button
            type="button"
            color="default"
            variant="contained"
            onClick={() => handleClose(true)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveModal;

import React from 'react';
import { Auth } from 'aws-amplify';
import { Button, Card, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import container from './ForgotPassword.container';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 600,
    padding: '35px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 0px 30px 0px'
    }
  },
  card: {
    padding: '10px 20px 50px',

    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      maxWidth: '100%'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    flexDirection: 'column',
    alignItems: 'center'
  },
  titleOne: {
    padding: 3,
    fontSize: 24,
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14
    }
  },
  text: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    textDecoration: 'none',
    [theme.breakpoints.only('xs')]: {
      fontSize: 14
    }
  }
}));

const ForgotPassword = (props: any) => {
  const { history, onShowNotification } = props;
  const classes = useStyles();
  const [forgotPasswordLoading, setforgotPasswordLoading] =
    React.useState(false);
  const handleForgotPassword = async (values: any) => {
    try {
      setforgotPasswordLoading(true);
      const data = await Auth.forgotPassword(values.email);
      console.log('forgot password', data);
      history.push('/forgot-password-confirmation');
      setforgotPasswordLoading(false);
    } catch (error) {
      onShowNotification('error', 'Something went wrong. Please try again.');
      setforgotPasswordLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="sm">
        <Grid style={{ margin: '2% 0' }}>
          <Card className={classes.card} elevation={3}>
            <Alert severity="info">
              If your email address is registered in our system, you will
              receive a password recovery code at your email address within a
              few minutes.
            </Alert>
            <div className={classes.paper}>
              <Typography component="span" className={classes.titleOne}>
                Forgot Password
              </Typography>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  email: ''
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email('Invalid email address')
                    .required('Please add your email address')
                    .max(254, 'Really!')
                })}
                onSubmit={handleForgotPassword}
              >
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        id="email"
                        name="email"
                        variant="outlined"
                        required
                        fullWidth
                        label="Email"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={forgotPasswordLoading}
                  >
                    {forgotPasswordLoading ? 'Loading...' : 'Send me code'}
                  </Button>
                </Form>
              </Formik>
            </div>
          </Card>
        </Grid>
      </Container>
    </div>
  );
};

export default container(ForgotPassword);

const config = {
    cognito: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_ignkMy3Ez',
        APP_CLIENT_ID: '1e8t917e478lpk1e702m0bbe3n',
        IDENTITY_POOL_ID: 'us-east-1:958ed7e2-cb13-41d1-938a-050ada447973'
    }
};

export default config;

import PriceType from '../constant/PriceType';

export const formatDate = (date: string) => {
    let result = new Date(date);
    if (result.toString() === 'Invalid Date') {
        result = new Date();
    }
    return result.toUTCString().substring(0, 22);
};

export const isUserAVendor = (role: string) => {
  return (role === 'vendor');
}

export const subscriptionAccess: any = (subscriptionType: string) => {
  let access = {};
  switch (subscriptionType) {
    case PriceType.MONTHLY_SINGLE:
      access = {
        name: 'Single (Monthly)',
        account: 1,
        removeAccount: false,
        hashtag: false,
        searchRSS: false,
        analytics: false,
        team: false
      };
      break;

    case PriceType.MONTHLY_BASIC:
      access = {
        name: 'Basic (Monthly)',
        account: 3,
        removeAccount: false,
        hashtag: false,
        searchRSS: false,
        analytics: false,
        team: false
      };
      break;

    case PriceType.MONTHLY_ADVANCED:
      access = {
        name: 'Advanced (Monthly)',
        account: 7,
        removeAccount: true,
        hashtag: true,
        searchRSS: true,
        analytics: true,
        team: false
      };
      break;

    case PriceType.MONTHLY_TEAM:
      access = {
        name: 'Team (Monthly)',
        account: 20,
        removeAccount: true,
        hashtag: true,
        searchRSS: true,
        analytics: true,
        team: true
      };
      break;

    case PriceType.YEARLY_SINGLE:
      access = {
        name: 'Single (Yearly)',
        account: 1,
        removeAccount: false,
        hashtag: false,
        searchRSS: false,
        analytics: false,
        team: false
      };
      break;

    case PriceType.YEARLY_BASIC:
      access = {
        name: 'Basic (Yearly)',
        account: 3,
        removeAccount: false,
        hashtag: false,
        searchRSS: false,
        analytics: false,
        team: false
      };
      break;

    case PriceType.YEARLY_ADVANCED:
      access = {
        name: 'Advanced (Yearly)',
        account: 7,
        removeAccount: true,
        hashtag: true,
        searchRSS: true,
        analytics: true,
        team: false
      };
      break;

    case PriceType.YEARLY_TEAM:
      access = {
        name: 'Team (Yearly)',
        account: 20,
        removeAccount: true,
        hashtag: true,
        searchRSS: true,
        analytics: true,
        team: true
      };
      break;

    default:
      console.log(`case not handled ${subscriptionType}`);
  }
  return access;
}

import { IAnalytics } from "src/interfaces";
import {
  FETCH_TWEET_ANALYTICS_FAIL,
  FETCH_TWEET_ANALYTICS_START,
  FETCH_TWEET_ANALYTICS_SUCCESS,
} from "../types/types";

export const fetchTweetAnalyticsStart = () => ({
  type: FETCH_TWEET_ANALYTICS_START,
});

export const fetchTweetAnalyticsSuccess = (data: IAnalytics[]) => ({
  type: FETCH_TWEET_ANALYTICS_SUCCESS,
  payload: data,
});

export const fetchTweetAnalyticsFail = () => ({
  type: FETCH_TWEET_ANALYTICS_FAIL,
});

import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { auth_base_url } from 'src/constant/twitterApi';
import * as VendorTypes from 'src/store/vendor/vendor.types';
import * as VendorAction from 'src/store/vendor/vendor.actions';
import { showSnackbarNotification } from 'src/store/notification/notification.actions';

function* vendorInvitationAsync(action: { type: string; payload: any }): any {
  try {
    const { data } = yield axios.post(`${auth_base_url}/vendor/invitation`,{
      email: action.payload.values.email,
      vendorId: action.payload.values.vendorId
    });
    console.log('data: ', data);
    if (data) {
      yield put(showSnackbarNotification('success', 'Invitation email sent successfully.'));
      yield put(VendorAction.vendorInvitationSuccess(data));
    }
  } catch (error) {
      console.error('ERROR: ', error);
      yield put(VendorAction.vendorInvitationFail(error));
  }
}

function* fetchUserVendorAsync (action: { type: string; payload: any }): any {
  try {
    const { userId } = action.payload;
    const { data } = yield axios.get(`${auth_base_url}/fetch/vendors/${userId}`);
    if (data) {
      yield put(VendorAction.fetchUserVendorSuccess(data.data));
    }
  } catch (error) {
    console.error('ERROR: ', error);
    yield put(VendorAction.fetchUserVendorFail(error));
  }
}

function* removeVendorAsync (action: { type: string; payload: any }): any {
  try {
    const { vendorId } = action.payload;
    const { data } = yield axios.get(`${auth_base_url}/remove/vendor/${vendorId}`);
    console.log('data: ', data);
    if (data.data) {
      yield put(VendorAction.removeVendorSuccess(data.data));
    }
  } catch (error: any) {
    console.error('ERROR: ', error);
    yield put(showSnackbarNotification('error', error.message || 'Vendor remove failed.'));
  }
}

export function* vendorInvitationStartWatcher() {
  yield takeLatest(VendorTypes.VENDOR_INVITATION_START, vendorInvitationAsync);
}

export function* fetchUserVendorStartWatcher() {
  yield takeLatest(VendorTypes.FETCH_USER_VENDOR_START, fetchUserVendorAsync);
}

export function* removeVendorStartWatcher() {
  yield takeLatest(VendorTypes.REMOVE_VENDOR_START, removeVendorAsync);
}

export function* vendorSaga() {
  yield all([
    call(vendorInvitationStartWatcher),
    call(fetchUserVendorStartWatcher),
    call(removeVendorStartWatcher)
  ]);
}

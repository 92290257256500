import React from "react";
import { useDispatch } from 'react-redux';
import { Button, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { completeSubscriptionProcess } from 'src/store/subscription/subscription.actions';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    maxWidth: 500,
    margin: 'auto',
    textAlign: 'center'
  },
  button: {
    marginTop: 50,
    marginBottom: 20
  }
}));

export const SubscriptionCanceled = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  React.useEffect(() => {
    dispatch(completeSubscriptionProcess());
  }, [dispatch]);

  return (
    <Paper className={classes.paper}>
      <Typography component="h3" variant="h3">
        Payment Canceled
      </Typography>
      <Typography variant="body1">
        Your subscription has been canceled. Please try again.
      </Typography>
      <Button
        variant="outlined"
        className={classes.button}
        component={Link}
        to="/"
      >
        Homepage
      </Button>
    </Paper>
  );
}

export default SubscriptionCanceled;

import * as RSSTypes from './rssFeed.types';

export const fetchRSSStart = (url: string) => ({
    type: RSSTypes.FETCH_RSS_START,
    payload: { url }
});

export const fetchRSSSuccess = (url: string, data: any) => ({
    type: RSSTypes.FETCH_RSS_SUCCESS,
    payload: { url, data }
});

export const fetchRSSFail = (error: any) => ({
    type: RSSTypes.FETCH_RSS_FAIL,
    payload: error
});

export const fetchRSSHistoryStart = () => ({
    type: RSSTypes.FETCH_RSS_HISTORY_START,
});

export const fetchRSSHistorySuccess = (data: any) => ({
    type: RSSTypes.FETCH_RSS_HISTORY_SUCCESS,
    payload: data
});

export const fetchRSSHistoryFail = (error: any) => ({
    type: RSSTypes.FETCH_RSS_HISTORY_FAIL,
    payload: error
});

import axios from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { analytics_Url } from "src/constant/twitterApi";
import { showSnackbarNotification } from "../notification/notification.actions";
import { FETCH_TWEET_ANALYTICS_START } from "../types/types";
import {
  fetchTweetAnalyticsFail,
  fetchTweetAnalyticsSuccess,
} from "./tweetAnalyticAction";

function* fetchTweetAnalytics(action: { type: string; payload: any }) {
  try {
    // let { user } = yield select((state) => state.auth);
    let { accountId } = yield select((state) => state.account);

    const { data } = yield axios.get(
      `${analytics_Url}get/analytic/${accountId}`
    );
    if (data.status === 200) {
      yield put(fetchTweetAnalyticsSuccess(data.data));
    } else {
      yield put(fetchTweetAnalyticsFail());
    }
  } catch (error) {
    console.log(error);
    yield put(showSnackbarNotification("error", "Something went wrong."));

    yield put(fetchTweetAnalyticsFail());
  }
}

export function* watchGetTweetAnalytics() {
  yield takeLatest(FETCH_TWEET_ANALYTICS_START, fetchTweetAnalytics);
}

export function* tweetAnalyticsSaga() {
  yield all([call(watchGetTweetAnalytics)]);
}

import axios from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { Auth } from "aws-amplify";
import { auth_base_url, stripe_base_Url } from "src/constant/twitterApi";
import { showSnackbarNotification } from "../notification/notification.actions";
import * as AuthType from "src/store/auth/auth.types";
import * as AuthAction from "src/store/auth/authAction";
import * as AccountAction from "src/store/account/account.actions";
import * as VendorAction from "src/store/vendor/vendor.actions";
import * as TweetAction from "src/store/tweet/tweetAction";

function* registerNewUserAsync(action: { type: string; payload: any }): any {
  try {
    const { values } = action.payload;
    let item = {};
    if (values.vendorId) {
      item = {
        email: values.email,
        name: `${values.firstname} ${values.lastname}`,
        firstname: values.firstname,
        lastname: values.lastname,
        password: values.password,
        vendorId: values.vendorId,
      };
    } else {
      item = {
        email: values.email,
        name: `${values.firstname} ${values.lastname}`,
        firstname: values.firstname,
        lastname: values.lastname,
        password: values.password,
      };
    }
    localStorage.setItem("auth::register", JSON.stringify(item));
    const { user } = yield Auth.signUp({
      username: values.email,
      password: values.password,
      attributes: {
        email: values.email,
        name: `${values.firstname} ${values.lastname}`,
        given_name: values.firstname,
        family_name: values.lastname,
        // phone_number,
      },
    });
    console.log("user: ", user);
  } catch (error: any) {
    console.log("error signing up:", error);
    yield put(AuthAction.registerNewUserFail());
    localStorage.removeItem("auth::register"); // remove register data from localstorage if failed
    if (error.code === "UsernameExistsException") {
      yield put(showSnackbarNotification("error", error.message));
    } else {
      yield put(showSnackbarNotification("error", error));
    }
  }
}

function* signupStartAsync(action: { type: string; payload: any }): any {
  try {
    const { values } = action.payload;
    const data = yield axios.post(`${auth_base_url}/signup`, values);
    if (data) {
      yield put(AuthAction.signupSuccess(data));
      // history.push({ pathname: '/signup-verification' }); /// this case handle through subscription
    }
  } catch (error: any) {
    yield put(AuthAction.signupFail(error));
    console.log("error signing up:", error);
  }
}

function* signupVerifyStartAsync(action: { type: string; payload: any }): any {
  try {
    const { email, code, history } = action.payload;
    const resp = yield Auth.confirmSignUp(email, code);
    if (resp) {
      const { data } = yield axios.post(`${auth_base_url}/verify`, email);
      console.log("data: ", data);
      localStorage.removeItem("auth::register");
      localStorage.removeItem("auth::registerUserId");
      localStorage.removeItem("subscription::price");
      history.push({ pathname: "/login" });
      yield put(
        showSnackbarNotification("success", "Email verification completed.")
      );
    }
  } catch (error: any) {
    console.log("error confirming sign up", error);
  }
}

function* loginStartAsync(action: { type: string; payload: any }): any {
  try {
    const {
      history,
      values: { email, password },
    } = action.payload;
    const { attributes } = yield Auth.signIn(email, password);
    const uid = attributes.sub;
    if (uid) {
      yield put(AuthAction.loadCurrentUserSuccess(attributes));
      yield put(AuthAction.fetchCurrentUserStart(uid)); // fetch user details
      yield put(AccountAction.fetchUserAccountStart(uid)); // fetch user accounts
      yield put(VendorAction.fetchUserVendorStart(uid)); // fetch user vendors
      yield put(
        showSnackbarNotification("success", "User logged in successfully.")
      );
      yield history.push({ pathname: "/new-thread" });
    } else {
      yield put(showSnackbarNotification("error", "User login failed."));
    }
  } catch (error: any) {
    // console.log('Error signing in', error);
    yield put(AuthAction.loadCurrentUserFail(error));
    yield put(showSnackbarNotification("error", error.message));
  }
}

function* loadCurrentUserAsync() {
  try {
    yield Auth.currentSession();
    const { attributes } = yield Auth.currentAuthenticatedUser();
    const uid = attributes.sub;
    if (uid) {
      yield put(AuthAction.loadCurrentUserSuccess(attributes));
      yield put(AuthAction.fetchCurrentUserStart(uid)); // fetch user details
      yield put(AccountAction.fetchUserAccountStart(uid)); // fetch user accounts
      yield put(VendorAction.fetchUserVendorStart(uid)); // fetch user vendors
    } else {
      yield put(
        showSnackbarNotification("error", "Failed to fetch user details.")
      );
    }
  } catch (error) {
    console.log("ERROR: ", error);
    yield put(AuthAction.loadCurrentUserFail(error));
  }
}

function* logoutStartAsync(action: { type: string; payload: any }): any {
  try {
    const { history } = action.payload;
    yield Auth.signOut();
    yield put(
      showSnackbarNotification("success", "User logged out successfully.")
    );
    yield put(AuthAction.logoutSuccess()); // clear auth state on logout
    yield put(AccountAction.clearAccountState()); // clear account state on logout
    yield put(TweetAction.resetState()); // clear tweetsky state on logout
    history.push("/");
  } catch (error) {
    console.log("Error signing out: ", error);
  }
}

function* fetchUserAsync(action: { type: string; payload: any }): any {
  try {
    const { userId } = action.payload;
    const { data } = yield axios.get(`${auth_base_url}/fetch/user/${userId}`);
    if (data.statusCode === 200) {
      console.log(data, "dasfalskdjflaksdfjlasd");
      yield put(AuthAction.fetchCurrentUserSuccess(data.user));
      // yield put(AccountAction.setCurrentAccount(data.user.active_account));
    }
  } catch (error) {
    yield put(AuthAction.fetchCurrentUserFail(error));
    console.log("Error on fetch user: ", error);
  }
}

function* setUserActiveAccountAsync(action: {
  type: string;
  payload: any;
}): any {
  try {
    const { user } = yield select((state) => state.auth);
    const { accountId } = action.payload;
    const { data } = yield axios.post(`${auth_base_url}/set/activeAccount`, {
      userId: user.id,
      accountId,
    });
    // console.log('data: ', data);
    if (data) {
      yield put(
        showSnackbarNotification("success", "Active account set successfully.")
      );
      yield put(AuthAction.fetchCurrentUserStart(user.id));
    }
  } catch (error) {
    console.log("Error on set user active account.");
  }
}

function* checkSubVendorAUserAsync(action: {
  type: string;
  payload: any;
}): any {
  try {
    const { invitationId, history } = action.payload;
    const { data } = yield axios.post(
      `${auth_base_url}/verify/vendorInvitation`,
      {
        invitationId,
      }
    );
    if (data.user) {
      history.push("/login");
      yield put(
        showSnackbarNotification("success", "Invitation accepted successfully.")
      );
    } else if (data.invitation) {
      yield put(AuthAction.loadInvitationSuccess(data.invitation));
    }
  } catch (error) {
    console.log("Error on check sub vendor a user.");
    console.error("ERROR: ", error);
  }
}

// function* fetchUserSubscriptionAsync(action: any): any {
//   const { userId } = action.payload;
//   const { data } = yield axios.get(
//     `${stripe_base_Url}/fetch/subscription/${userId}`
//   );
//
//   if (data) {
//     yield put(AuthAction.fetchUserSubscriptionSuccess(data));
//   }
// }

function* cancelUserSubscriptionAsync(action: any): any {
  const { userId } = action.payload;
  const { data } = yield axios.get(
    `${stripe_base_Url}/cancel/subscription/${userId}`
  );

  if (data) {
    yield put(AuthAction.cancelUserSubscriptionSuccess(data));
  }
}

export function* registerNewUserWatcher() {
  yield takeLatest(AuthType.REGISTER_NEW_USER, registerNewUserAsync);
}

export function* signupStartWatcher() {
  yield takeLatest(AuthType.SIGNUP_START, signupStartAsync);
}

export function* signupVerifyStartWatcher() {
  yield takeLatest(AuthType.SIGNUP_VERIFY_START, signupVerifyStartAsync);
}

export function* loginStartWatcher() {
  yield takeLatest(AuthType.LOGIN_START, loginStartAsync);
}

export function* loadCurrentUserStartWatcher() {
  yield takeLatest(AuthType.LOAD_CURRENT_USER_START, loadCurrentUserAsync);
}

export function* logoutStartWatcher() {
  yield takeLatest(AuthType.LOGOUT_START, logoutStartAsync);
}

export function* fetchUserWatcher() {
  yield takeLatest(AuthType.FETCH_CURRENT_USER_START, fetchUserAsync);
}

export function* setActiveAccountWatcher() {
  yield takeLatest(AuthType.SET_USER_ACTIVE_ACCOUNT, setUserActiveAccountAsync);
}

export function* checkSubVendorAUserWatcher() {
  yield takeLatest(AuthType.CHECK_SUB_VENDOR_A_USER, checkSubVendorAUserAsync);
}

// export function* fetchUserSubscriptionWatcher() {
//   yield takeLatest(AuthType.FETCH_USER_SUBSCRIPTION_START, fetchUserSubscriptionAsync);
// }

export function* cancelUserSubscriptionWatcher() {
  yield takeLatest(
    AuthType.CANCEL_USER_SUBSCRIPTION_START,
    cancelUserSubscriptionAsync
  );
}

export function* authSagas() {
  yield all([
    call(registerNewUserWatcher),
    call(signupStartWatcher),
    call(signupVerifyStartWatcher),
    call(loginStartWatcher),
    call(loadCurrentUserStartWatcher),
    call(logoutStartWatcher),
    call(fetchUserWatcher),
    call(setActiveAccountWatcher),
    call(checkSubVendorAUserWatcher),
    // call(fetchUserSubscriptionWatcher),
    call(cancelUserSubscriptionWatcher),
  ]);
}

import axios from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as AccountTypes from 'src/store/account/account.types';
import * as AccountActions from 'src/store/account/account.actions';
import { auth_base_url, base_Url } from '../../constant/twitterApi';
import { showSnackbarNotification } from 'src/store/notification/notification.actions';
import {fetchUserAccountStart, setCurrentAccount} from "src/store/account/account.actions";

function* handleGetOauthToken(): any {
  try {
    const { data } = yield axios.post(`${base_Url}create/authLink`, {
      callbackUrl: `${window.location.origin}/new-thread`
    });

    if (data.authLink) {
      window.location.href = data.authLink;
    } else {
      yield put(showSnackbarNotification('error', 'Something went wrong'));
      yield put(AccountActions.endLoginProcess());
    }
  } catch (error) {
    yield put(showSnackbarNotification('error', 'Something went wrong'));
    yield put(AccountActions.endLoginProcess());
  }
}

function* addNewAccountAsync(action: any): any {
  try {
    let { user } = yield select((state) => state.auth);
    const { oauthToken, oauthVerifier } = action.payload;
    if (user) {
      const response = yield axios.post(`${base_Url}set/user`, {
        userId: user.id,
        oauthToken,
        oauthVerifier
      });
      console.log('response: ', response);
      if (response.data.data) {
        if (response.data.data.authToken) {
          yield put(setCurrentAccount(response.data.data.twitterId));
          yield put(fetchUserAccountStart(response.data.data.id)); /// fetch user account list with new added one
          // set user active twitter account id in localStorage
          // localStorage.setItem(
          //   'auth::account',
          //   JSON.stringify({
          //     userId: data.id
          //   })
          // );
        } else {
          yield put(showSnackbarNotification('error', 'Failed to add new account.'));
        }
        yield put(showSnackbarNotification('success', 'New account added successfully.'));
      }
    }
    //if success responseText ==>n start...
  } catch (error: any) {
    yield put(showSnackbarNotification('error', error.message));
  }
}

// function* loadUserAccountAsync() {
//   const parsedData = localStorage.getItem('auth::account');
//   if (parsedData) {
//     const userId = JSON.parse(parsedData)?.userId;
//     const { data } = yield axios.get(`${base_Url}get/user/${userId}`);
//     // console.log('data', data);
//     if (data) {
//       yield put(AccountActions.setNewAccount(data));
//     }
//   }
// }

// function signOutAsync(action: any) {
//   action.payload.push('/?newThread=true');
//   localStorage.removeItem('auth::account');
// }

function* fetchUserAccountAsync(action: { type: string; payload: any }): any {
  try {
    const { userId } = action.payload;
    const { data } = yield axios.get(`${auth_base_url}/fetch/accounts/${userId}`);
    if (data) {
      // console.log('data: ', data.data);
      yield put(AccountActions.fetchUserAccountSuccess(data.data));
    }
  } catch (error: any) {
    yield put(AccountActions.fetchUserAccountFail(error));
    yield put(showSnackbarNotification('error', error.message));
  }
}

function* removeAccountAsync(action: { type: string, payload: any }): any {
  try {
    const { user } = yield select((state) => state.auth);
    const { accountId, value } = action.payload;
    // console.log('accountId, value: ', accountId, value);
    const { data } = yield axios.post(
      `${auth_base_url}/remove/account`,
      {
        userId: user.id,
        accountId,
        value
      }
    );
    // console.log('remove account: ', data);
    if (data) {
      yield put(
        showSnackbarNotification('success', 'Account removed successfully.')
      );
      yield put(AccountActions.removeTweetAccountComplete());
      yield put(AccountActions.fetchUserAccountStart(user.id));
    }
  } catch (error: any) {
    yield put(AccountActions.removeTweetAccountComplete());
    console.log('error on remove account: ', error);
  }
}

/** REQUEST WATCHERS */
export function* getOauthRequestTokenWatcher() {
  yield takeLatest(AccountTypes.OBTAIN_OAUTH_REQUEST_TOKEN, handleGetOauthToken);
}

export function* addNewAccountStartWatcher() {
  yield takeLatest(AccountTypes.ADD_NEW_ACCOUNT, addNewAccountAsync);
}

// export function* loadUserAccountWatcher() {
//   yield takeLatest(AccountTypes.LOAD_USER_ACCOUNT_START, loadUserAccountAsync);
// }

// export function* signOutWatcher() {
//   yield takeLatest(AccountTypes.REMOVE_ACCOUNT, signOutAsync);
// }

export function* fetchUserAccountStartWatcher() {
  yield takeLatest(AccountTypes.FETCH_USER_ACCOUNT_START, fetchUserAccountAsync);
}

export function* removeAccountStartWatcher() {
  yield takeLatest(AccountTypes.REMOVE_ACCOUNT_START, removeAccountAsync);
}

export function* accountSaga() {
  yield all([
    call(getOauthRequestTokenWatcher),
    call(addNewAccountStartWatcher),
    // call(loadUserAccountWatcher),
    // call(signOutWatcher),
    call(fetchUserAccountStartWatcher),
    call(removeAccountStartWatcher),
  ]);
}

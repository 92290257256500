import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
} from "@material-ui/core";
import { IUser } from "src/interfaces/index";
import RemoveModal from "../RemoveModal";
import { useDispatch } from "react-redux";
import { setCurrentAccount } from "src/store/account/account.actions";

interface SocialAccountProps {
  classes: any;
  matchesXS: boolean;
  loading: boolean;
  user: IUser;
  accountList: any;
  openConfirm: boolean;
  handleCloseConfirm: () => void;
  removeAccountHandler: (value: any) => void;
  active_account: any;
  activeAccountHandler: (value: any) => void;
  removeAccountConfirm: (value: any) => void;
  addAccount: () => void;
}

const customStyles = makeStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}));

export const CustomSwitch = (props: any) => {
  const classes = customStyles(props);
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

export const removeModalDescription = (props: any) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.checked}
          onChange={props.handleChange}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      }
      label={"Remove all data related to this account."}
    />
  );
};

const SocialAccount = (props: SocialAccountProps) => {
  const {
    classes,
    matchesXS,
    loading,
    user,
    accountList,
    openConfirm,
    handleCloseConfirm,
    removeAccountHandler,
    active_account,
    activeAccountHandler,
    removeAccountConfirm,
    addAccount,
  } = props;

  const [checked, setChecked] = React.useState(false);
  const dispatch = useDispatch();
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const removeHandler = () => {
    removeAccountHandler(checked);
    setChecked(false);
  };

  const setCurrentAccountHandler = (value: any) => {
    // console.log('account: ', account);
    activeAccountHandler(value);
    dispatch(setCurrentAccount(value));
  };

  return (
    <>
      <CardHeader
        title="Twitter Accounts"
        classes={{
          title: classes.heading,
        }}
      />
      <CardContent>
        {user && accountList.length > 0 ? (
          accountList.map((value: any, index: number) => (
            <Grid
              container
              spacing={2}
              key={`account-${index}`}
              className={classes.row}
            >
              <Grid item xs={12} sm={4}>
                {value.username}
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={value.twitterId === active_account}
                      onChange={() => setCurrentAccountHandler(value.twitterId)}
                    />
                  }
                  label=""
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  type="button"
                  fullWidth={matchesXS}
                  variant="contained"
                  color="secondary"
                  onClick={() => removeAccountConfirm(value)}
                >
                  Remove Account
                </Button>
              </Grid>
            </Grid>
          ))
        ) : (
          <Button
            type="button"
            fullWidth={matchesXS}
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={addAccount}
          >
            Add Account
          </Button>
        )}
        <RemoveModal
          open={openConfirm}
          title={`Are you sure, you want to remove this account?`}
          description={removeModalDescription({ checked, handleChange })}
          handleClose={handleCloseConfirm}
          removeHandler={removeHandler}
        />
      </CardContent>
    </>
  );
};

export default SocialAccount;

import React from "react";
import ReactDOM from "react-dom";
import Amplify from 'aws-amplify';
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import config from 'src/constant/config';

Amplify.configure({
    Auth: {
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

import React from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from "@material-ui/core/styles";
import {Button, Card, Container, Grid, Typography} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {TextField} from "formik-material-ui";
import container from "./SignUpVerification.container";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 600,
        padding: '35px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: '50px 0px 30px 0px',
        }
    },
    card: {
        padding: '10px 20px 50px',
        [theme.breakpoints.down('xs')]: {
            borderRadius: 0,
            maxWidth: '100%'
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        flexDirection: 'column',
        alignItems: 'center'
    },
    titleOne: {
        padding: 3,
        fontSize: 24,
        display: 'flex',
        justifyContent: 'center'
    },
    titleTwo: {
        padding: 3,
        fontSize: 14,
        display: 'flex',
        justifyContent: 'center'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        marginTop: 5,
        fontSize: 12,
        color: theme.palette.primary.main,
        [theme.breakpoints.only('xs')]: {
            fontSize: 10
        }
    },
}));

const SignupVerification = (props: any) => {
    const { history, onSignupVerifyStart } = props;
    const classes = useStyles();
    const [email, setEmail] = React.useState(null);
    const verificationLoader = false;
    // const [verificationLoader, setVerificationLoader] = React.useState(false);
    React.useEffect(() => {
      const parsedData = JSON.parse(localStorage.getItem('auth::register') as string);
      if (parsedData) {
        // console.log('parsedData: ', parsedData);
        setEmail(parsedData.email);
      }
    }, []);

    const emailVerification = async (values: any) => {
        if (values.email && values.code) {
            onSignupVerifyStart(values.email, values.code, history);
        }
    };

    const resendVerificationCode = async () => {
        try {
            const parsedData = JSON.parse(localStorage.getItem('auth::register') as string);
            if (parsedData) {
                await Auth.resendSignUp(parsedData.email);
                console.log('code resent successfully');
            }
        } catch (err) {
            console.log('error resending code: ', err);
        }
    };

    return (
        <div className={classes.root}>
            <Container component="main" maxWidth="sm">
                <Grid style={{ margin: '2% 0' }}>
                    <Card className={classes.card}>
                        <div className={classes.paper}>
                            <Typography component="span" className={classes.titleOne}>
                                Signup Verification
                            </Typography>
                            <Typography component="span" className={classes.titleTwo}>
                                Your account is yet to be verified. Please check your email for the verification link or resend the mail from the link below. 
                            </Typography>
                            {/* <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    email: email,
                                    code: ''
                                }}
                                validationSchema={Yup.object({
                                    code: Yup.number()
                                        .required('Please add your code.'),
                                })}
                                onSubmit={emailVerification}
                            >
                                <Form className={classes.form}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <Field
                                                component={TextField}
                                                id="email"
                                                name="email"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                disabled={true}
                                                label="Email"
                                                value={email}
                                                // autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={TextField}
                                                id="code"
                                                name="code"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                label="Verification Code"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={verificationLoader}
                                    >
                                        {verificationLoader ? 'Loading...' : 'Verify code'}
                                    </Button>
                                </Form>
                            </Formik> */}

                            <Grid container>
                                <Grid item xs>
                                    <Typography onClick={resendVerificationCode} className={classes.link}>
                                        Resend Verification Mail.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
            </Container>
        </div>
    );
}

export default container(SignupVerification);

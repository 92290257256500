import React from 'react';
import { Box, Grid } from '@material-ui/core';
import DraftCard from './components/draftCard';
import EmptyScreen from './components/emptyScreen';
import SkeletonPost from 'src/components/skeleton-post';
import container from './Draft.container';
import DisplayCard from 'src/components/displayCard/displayCard';

const Draft = (props: any) => {
  const {
    tweetskyState: { drafts: draftCollection, draftLoading },
    onDeleteDraft,
    authState: { user },
    accountState: { results, accountId }
  } = props;
  const [username, setUsername] = React.useState<string>('');
  const [avatar, setAvatar] = React.useState<string>('');

  React.useEffect(() => {
    if (accountId && results) {
      const activeAccount = results[accountId];
      setUsername(`${activeAccount?.username}`);
      setAvatar(activeAccount?.username?.charAt(0)?.toUpperCase());
    }
  }, [accountId, results]);

  return (
    <>
      <Box style={{ margin: '16px auto' }}>
        <Grid container spacing={1} justifyContent="center">
          {draftLoading ? (
            <SkeletonPost />
          ) : Object.values(draftCollection) &&
            Object.values(draftCollection).length > 0 ? (
            Object.values(draftCollection)
              .sort(function (a: any, b: any) {
                return Number(b.updatedAt) - Number(a.updatedAt);
              })
              .map((drafts: any, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <DisplayCard
                    avatar={avatar}
                    username={username}
                    historyData={drafts}
                    onDeleteDraft={onDeleteDraft}
                    area={'draft'}
                  />
                </Grid>
              ))
          ) : (
            <EmptyScreen user={user} />
          )}
        </Grid>
      </Box>
    </>
  );
};

export default container(Draft);

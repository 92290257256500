export const validateFile = (file: any) => {
  const validTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "video/mp4",
  ];
  if (validTypes.indexOf(file.type) === -1) {
    return false;
  }

  return true;
};
export const validateSize = (file: any) => {
  if (file.size > 500000000) {
    return false;
  }
  return true;
};

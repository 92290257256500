import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import useQuery from 'src/utils/useQuery';
import ThreadViewCard from './components/ThreadViewCard';
import ThreadSkeleton from 'src/components/skeleton-thread';
import container from './ThreadView.container';

export interface IThreadVIewProps {
  authState: any;
  tweetskyState: any;
  accountState: any;
  onDeleteSchedulePost: (sk: string, history: any) => void;
  onDeleteHistoryPost: (sk: string, history: any) => void;
  onRetweetThread: ({ sk }: { sk: string }) => void;
  onUnretweetThread: ({ sk }: { sk: string }) => void;
}
function ThreadView(props: IThreadVIewProps) {
  const params: Params = useParams();
  const query = useQuery();
  const history = useHistory();
  const view = query.get('view');
  const { postId } = params;
  const {
    authState: { user },
    tweetskyState: {
      scheduledPost,
      scheduleLoading,
      historyData,
      historyLoading
    },
    accountState: { accountId, results },
    onDeleteSchedulePost,
    onDeleteHistoryPost,
    onRetweetThread,
    onUnretweetThread
  } = props;
  // const scheduleLoading = true;

  let isSubscriber = !!(
    user &&
    user.subscriptionId &&
    user.subscription &&
    user.subscription.status !== 'canceled'
  );

  const [selectedPost, setSelectedPost] = useState(null);
  const [username, setUsername] = React.useState<string>('');
  const [avatar, setAvatar] = React.useState<string>('');

  React.useEffect(() => {
    if (accountId && results) {
      const activeAccount = results[accountId];
      setUsername(`${activeAccount?.username}`);
      setAvatar(activeAccount?.username?.charAt(0)?.toUpperCase());
    }
  }, [accountId, results]);

  useEffect(() => {
    if (postId) {
      if (view === 'schedule') {
        const selectedSchedule = scheduledPost && scheduledPost[postId];
        if (selectedSchedule) {
          setSelectedPost({...selectedSchedule});
        } else {
          setSelectedPost(null);
          history.push('/new-thread');
        }
      } else {
        const selected = historyData && historyData[postId];
        if (selected) {
          setSelectedPost({...selected});
        } else {
          setSelectedPost(null);
          history.push('/new-thread');
        }
      }
    }
  }, [scheduledPost, historyData, historyLoading, postId, view]);

  return (
    <Box>
      {scheduleLoading || !selectedPost || historyLoading ? (
        <ThreadSkeleton />
      ) : (
        <ThreadViewCard
          username={username}
          avatar={avatar}
          isSubscriber={isSubscriber}
          thread={selectedPost}
          onDeleteSchedulePost={onDeleteSchedulePost}
          onDeleteHistoryPost={onDeleteHistoryPost}
          onRetweetThread={onRetweetThread}
          onUnretweetThread={onUnretweetThread}
          historyLoading={historyLoading}
        />
      )}
    </Box>
  );
}
export default container(ThreadView);

import { RootState } from 'src/store';
import { connect } from 'react-redux';
import { signupVerifyStart } from 'src/store/auth/authAction';

const mapStateToProps = (state: RootState) => ({
    authState: state.auth,
    notificationState: state.notification,
});

const mapDispatchToProps = (dispatch: any) => ({
    onSignupVerifyStart: (email: any, code: any, history: any) => dispatch(signupVerifyStart(email, code, history))
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;

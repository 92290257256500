import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  AppBar,
  Button,
  Container,
  createStyles,
  Hidden,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import ClearIcon from "@material-ui/icons/Clear";
import MenuIcon from "@material-ui/icons/Menu";
import { RootState } from "src/store";
import logoImg from "../../../assets/tweetskyLogo.png";
import { scrollToFeatures, scrollToPricing } from "src/components/scroll";

interface Props {
  layout: string;
  darkMode: boolean;
  sidebar: boolean;
  // setDark: React.Dispatch<React.SetStateAction<boolean>>,
  onToggleDarkMode: (value: boolean) => void;
  onToggleSidebar: (value: boolean) => void;
  handleLoginLogout: () => void;
}

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      boxShadow: "0px 1px 3px rgb(3 0 71 / 9%)",
      padding: " 0",
      [theme.breakpoints.up("md")]: {
        // width: `calc(100% - ${miniDrawerWidth}px)`,
        width: "100%",
      },
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    minimalPaper: {
      background: "#fff",
    },
    drawerPaper: {
      width: drawerWidth,
      background: "#fff",
    },
    appBarShift: {
      boxShadow: "0px 1px 3px rgb(3 0 71 / 9%)",
      padding: " 0",
      [theme.breakpoints.up("md")]: {
        marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        width: "100%",
      },
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    logo: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
    logoImg: {
      maxWidth: "190px",
    },
    sun: {
      color: "#e8db37",
    },
    toggler: {
      marginRight: 8,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0),
    },
    navContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    mobileNavList: {
      display: "flex",
      flexDirection: "column",
      "& > *": {
        color: "#000",
        justifyContent: "center",
      },
    },
    navList: {
      display: "flex",
      flexDirection: "row",

      "& > *": {
        justifyContent: "center",
      },
    },
    navListItem: {
      cursor: "pointer",
    },
    button1: {
      minWidth: "135px",
    },
    button: {
      minWidth: "100px",
      [theme.breakpoints.down(450)]: {
        minWidth: "75px",
      },
    },
    removeDecoration: {
      textDecoration: "none",
      color: "#000000",
    },
  })
);

export const Topbar: React.FC<Props> = ({
  layout,
  darkMode,
  onToggleDarkMode,
  sidebar,
  onToggleSidebar,
  handleLoginLogout,
  children,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAuthenticated, loading } = useSelector(
    (state: RootState) => state.auth
  );
  const classes = useStyles(sidebar);

  const handleDrawerOpen = () => {
    onToggleSidebar(true);
  };

  const handleDrawerClose = () => {
    onToggleSidebar(false);
  };

  const mainTopbar = () => {
    return (
      <Toolbar style={{ paddingLeft: 0 }}>
        <div className={classes.toolbar}>
          {!sidebar ? (
            <IconButton onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerClose}>
              <ClearIcon />
            </IconButton>
          )}
        </div>
        <div className={classes.logo}>
          <img className={classes.logoImg} alt="logo" src={logoImg} />
        </div>
        {darkMode ? (
          <IconButton
            className={classes.toggler}
            onClick={() => {
              localStorage.setItem("darkMode", "false");
              onToggleDarkMode(false);
            }}
          >
            <Brightness4Icon color="primary" />
          </IconButton>
        ) : (
          <IconButton
            className={classes.toggler}
            onClick={() => {
              localStorage.setItem("darkMode", "true");
              onToggleDarkMode(true);
            }}
          >
            <Brightness7Icon className={classes.sun} />
          </IconButton>
        )}
        {!matchesSM && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleLoginLogout()}
            disabled={loading}
          >
            {loading ? "loading" : isAuthenticated ? "Logout" : "Login"}
          </Button>
        )}
      </Toolbar>
    );
  };

  const minimalLayout = () => {
    return (
      <Toolbar style={{ paddingLeft: 0 }}>
        <Container maxWidth={"lg"} className={classes.navContent}>
          <div className={classes.logo}>
            <img className={classes.logoImg} alt="logo" src={logoImg} />
          </div>
          <Hidden xsDown implementation="css">
            <List className={classes.navList}>
              <ListItem
                className={classes.navListItem}
                onClick={() => scrollToFeatures()}
              >
                <Typography>Features</Typography>
              </ListItem>
              <ListItem
                className={classes.navListItem}
                onClick={() => scrollToPricing()}
              >
                <Typography>Pricing</Typography>
              </ListItem>
              <ListItem className={classes.navListItem}>
                <Typography>
                  <a
                    className={classes.removeDecoration}
                    target="blank"
                    href="https://blog.tweetsky.com"
                  >
                    Blog
                  </a>
                </Typography>
              </ListItem>
              <ListItem>
                <Button
                  className={classes.button1}
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to={isAuthenticated ? "/new-thread" : "/signup"}
                >
                  Free Trial
                </Button>
              </ListItem>
            </List>
          </Hidden>
          <List className={classes.navList}>
            <ListItem>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                component={Link}
                to={isAuthenticated ? "/new-thread" : "/login"}
              >
                Log In
              </Button>
            </ListItem>
          </List>
        </Container>
      </Toolbar>
    );
  };

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(
          sidebar ? classes.appBarShift : classes.appBar,
          layout === "minimal" && classes.minimalPaper
        )}
        color="inherit"
      >
        {layout === "main" ? mainTopbar() : minimalLayout()}
      </AppBar>
    </>
  );
};

export default Topbar;

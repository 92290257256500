import React from 'react';
import {
  Card,
  CardContent,
  createStyles,
  Hidden,
  makeStyles
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    card: {
      position: 'relative',
      boxShadow: '0px 1px 3px rgb(3 0 71 / 9%)',
      width: '100%',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    content: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    table: {
      padding: '0px 16px 16px 16px'
    }
  })
);

export default function ScheduleSkeleton() {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.card}>
        <Hidden xsDown>
          <CardContent className={classes.content}>
            <Skeleton animation="wave" height={40} width={120}/>
            <Skeleton animation="wave" height={40} width={120}/>
            <Skeleton animation="wave" height={40} width={120}/>
          </CardContent>
        </Hidden>
        <Hidden smUp>
          <CardContent className={classes.content}>
            <Skeleton animation="wave" height={40} width={120}/>
            <Skeleton animation="wave" height={40} width={120}/>
          </CardContent>
        </Hidden>
        <CardContent className={classes.content} style={{ marginTop: -30 }}>
          <Skeleton animation="wave" height={40} width="100%"/>
        </CardContent>
        <CardContent className={classes.table}>
          <Skeleton animation="wave" height={200} width="100%" style={{ transform: 'none' }} />
        </CardContent>
      </Card>
    </>
  );
}
import {
  createStyles,
  makeStyles,
  TextField
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardBody: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 400,
      minWidth: 200,
      backgroundColor: theme.palette.type === "light" ? "#f3f7fa" : "#424242",
      borderRadius: 5,
      padding: "25px 25px 18px 25px",
    },
    header: {
      fontWeight: 500,
      marginBottom: 10,
    },

    button: {
      margin: "18px 0",
    },
  })
);

export default function QuoteTweetModal(props: {
  onShowNotification: (status: string, message: string) => void;
  quoteId: string | null;
  setQuoteId: (quoteId: string) => void;
  displayQuoteModal: boolean;
  changeQuoteModal: () => void;
}) {

  const classes = useStyles();
  const {
    onShowNotification,
    // quoteId,
    setQuoteId,
    displayQuoteModal,
    changeQuoteModal,
  } = props;

  const [tweetStatusUrl, setTweetStatusUrl] = React.useState("");

  const submitQuoteId = () => {
    if (tweetStatusUrl) {
    
      if (
        tweetStatusUrl.split("/")[5] &&
        !isNaN(Number(tweetStatusUrl.split("/")[5]))
      ) {
        setQuoteId(tweetStatusUrl.split("/")[5]);
      }
    } else {
      onShowNotification("error", "Please enter a tweet status url");
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={displayQuoteModal}
        onClose={changeQuoteModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={displayQuoteModal}>
          <Box className={classes.cardBody}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.header}
              color={"textPrimary"}
            >
              Add quote retweet
            </Typography>
            <TextField
              value={tweetStatusUrl}
              onChange={(e) => setTweetStatusUrl(e.target.value)}
              id="outlined-basic"
              label="Tweet URL"
              variant="outlined"
              placeholder={
                "https://twitter.com/Twitter/status/1461023836221153286"
              }
              style={{ width: "100%" }}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={submitQuoteId}
            >
              {"Add quote retweet"}
            </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import React from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  List,
  ListItem,
  Grid,
  CardContent,
  Card,
  FormControlLabel,
  FormLabel,
  Divider,
  IconButton,
  Hidden,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { stripeConfig } from "../../../constant/twitterApi";
import PriceType from "src/constant/PriceType";
import { GiCheckMark } from "react-icons/gi";
import Switch from "@material-ui/core/Switch";
// import hero from 'src/assets/hero.png';
// import heroBg from 'src/assets/heroBg.png';
// import feature from 'src/assets/feature.png';
// import topFrame from 'src/assets/topFrame.png';
// import bottomFrame from 'src/assets/bottomFrame.png';

const useStyles = makeStyles((theme) => ({
  pricingWrapper: {
    padding: "70px 0",
    "& > *": {
      textAlign: "center",
    },
  },
  featureHeader: {
    fontSize: "2rem",
    lineHeight: "49px",
    marginBottom: "10px",
  },
  featureSubtitle: {
    fontSize: "18px",
    lineHeight: "24.52px",
    marginBottom: 30,
    color: "#585858",
  },
  savings: {
    fontWeight: 500,
  },
  pricingListContainer: {
    marginTop: theme.spacing(1),
  },
  pricingCard: {
    boxShadow: " 0px 4px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 7,
  },
  pricingCardContent: {
    background: "#fff",
    padding: "0 0 30px 0",
  },
  pricingCardHeader: {
    background: theme.palette.primary.main,
    padding: "10px 0",
  },
  pricingHeader: {
    fontSize: "2rem",
    lineHeight: "49px",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 1)",
  },
  pricingSubtitle: {
    fontSize: "24px",
    lineHeight: "30px",
    color: "rgba(255, 255, 255, 1)",
    "& > span": {
      fontSize: "18px",
      color: "rgba(255, 255, 255, 0.7)",
    },
  },
  annualPricing: {
    fontSize: "18px",
    lineHeight: "30px",
    color: "rgba(255, 255, 255, 1)",
    "& > span": {
      fontSize: "16px",
      color: "rgba(255, 255, 255, 0.7)",
    },
  },
  check: {
    padding: 0,
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  divider: {
    margin: "0px 16px",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
  priceList: {
    display: "flex",
    gap: "10px",
  },
  priceButton: {
    marginTop: "30px",
  },
  featureList: {
    paddingLeft: 8,
  },
}));

// interface Styles extends Partial<Record<SwitchClassKey, string>> {
//   focusVisible?: string;
// }

const IOSSwitchStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#2F80ED",

          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
      color: "#fff",
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid #2F80ED`,
      backgroundColor: "#2F80ED",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
);

// function a11yProps(index: any, theme: any) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//     style: { color: theme.palette.text.primary }
//   };
// }

const IOSSwitch = ({ ...props }) => {
  const classes = IOSSwitchStyles();
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

export const Pricing = (props: any) => {
  const classes = useStyles();
  const { history, user, onSubscribeUser, onUpdateSubscription } = props;
  const [payment, setPayment] = React.useState({
    perYear: true,
  });
  const [subscriptionId, setSubscriptionId] = React.useState<string | null>(
    null
  );
  const [currentSubscription, setCurrentSubscription] = React.useState<
    string | null
  >(null);
  React.useEffect(() => {
    if (
      user &&
      user.subscriptionId &&
      user.subscription &&
      user.subscription.status !== "canceled"
    ) {
      setSubscriptionId(user.subscriptionId);
      setCurrentSubscription(user.subscriptionType);
    }
  }, [user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayment({ ...payment, [event.target.name]: event.target.checked });
  };

  const priceList = {
    monthly: [
      {
        title: "Single",
        price: "$6",
        priceId: stripeConfig.singleMonthlyPriceId,
        priceType: PriceType.MONTHLY_SINGLE,
        features: [
          "1 Twitter profile",
          "Unlimited threads and lengths",
          "Large media, gifs, split tweets, numbering",
          "Schedule months in advance",
          "Save drafts",
          "History",
          "Dark Mode",
        ],
        buttonText: "Start Free 7-Days Trial",
        buttonVariant: "contained",
      },
      {
        title: "Basic",
        price: "$9",
        priceId: stripeConfig.basicMonthlyPriceId,
        priceType: PriceType.MONTHLY_BASIC,
        features: [
          "3 Twitter profiles",
          "Unlimited threads and lengths",
          "Large media, gifs, split tweets, numbering",
          "Schedule months in advance",
          "Save drafts",
          "History",
          "Dark Mode",
        ],
        buttonText: "Start Free 7-Days Trial",
        buttonVariant: "contained",
      },
      {
        title: "Advanced",
        price: "$19",
        priceId: stripeConfig.advancedMonthlyPriceId,
        priceType: PriceType.MONTHLY_ADVANCED,
        features: [
          "7 Twitter profiles",
          "Unlimited threads and lengths",
          "Large media, gifs, split tweets, numbering",
          "Schedule months in advance",
          "Save drafts",
          "History",
          "Dark Mode",
        ],
        additionalFeatures: [
          "Hashtag generation",
          "RSS feeds to tweet",
          "Analytics up to 30 days with Hashtag cloud",
          "Early access to new features / roadmap",
        ],
        buttonText: "Start Free 7-Days Trial",
        buttonVariant: "contained",
      },
      {
        title: "Team",
        price: "$49",
        priceId: stripeConfig.teamMonthlyPriceId,
        priceType: PriceType.MONTHLY_TEAM,
        features: [
          "20 Twitter profiles",
          "Unlimited threads and lengths",
          "Large media, gifs, split tweets, numbering",
          "Schedule months in advance",
          "Save drafts",
          "History",
          "Dark Mode",
        ],
        additionalFeatures: [
          "Hashtag generation",
          "RSS feeds to tweet",
          "Analytics up to 30 days with Hashtag cloud",
          "Team members (Coming Soon)",
          "Early access to new features / roadmap",
        ],
        buttonText: "Start Free 7-Days Trial",
        buttonVariant: "contained",
      },
    ],
    yearly: [
      {
        title: "Single",
        price: "$60",
        monthPrice: "$5",
        priceId: stripeConfig.singleYearlyPriceId,
        priceType: PriceType.YEARLY_SINGLE,
        features: [
          "1 Twitter profile",
          "Unlimited threads and lengths",
          "Large media, gifs, split tweets, numbering",
          "Schedule months in advance",
          "Save drafts",
          "History",
          "Dark Mode",
        ],
        buttonText: "Start Free 7-Days Trial",
        buttonVariant: "contained",
      },
      {
        title: "Basic",
        price: "$96",
        monthPrice: "$8",
        priceId: stripeConfig.basicYearlyPriceId,
        priceType: PriceType.YEARLY_BASIC,
        features: [
          "3 Twitter profiles",
          "Unlimited threads and lengths",
          "Large media, gifs, split tweets, numbering",
          "Schedule months in advance",
          "Save drafts",
          "History",
          "Dark Mode",
        ],
        buttonText: "Start Free 7-Days Trial",
        buttonVariant: "contained",
      },
      {
        title: "Advanced",
        price: "$192",
        monthPrice: "$16",
        priceId: stripeConfig.advancedYearlyPriceId,
        priceType: PriceType.YEARLY_ADVANCED,
        features: [
          "7 Twitter profiles",
          "Unlimited threads and lengths",
          "Large media, gifs, split tweets, numbering",
          "Schedule months in advance",
          "Save drafts",
          "History",
          "Dark Mode",
        ],
        additionalFeatures: [
          "Hashtag generation",
          "RSS feeds to tweet",
          "Analytics up to 30 days with Hashtag cloud",
          "Early access to new features / roadmap",
        ],
        buttonText: "Start Free 7-Days Trial",
        buttonVariant: "contained",
      },
      {
        title: "Team",
        price: "$468",
        monthPrice: "$39",
        priceId: stripeConfig.teamYearlyPriceId,
        priceType: PriceType.YEARLY_TEAM,
        features: [
          "20 Twitter features",
          "Unlimited threads and lengths",
          "Large media, gifs, split tweets, numbering",
          "Schedule months in advance",
          "Save drafts",
          "History",
          "Dark Mode",
        ],
        additionalFeatures: [
          "Hashtag generation",
          "RSS feeds to tweet",
          "Analytics up to 30 days with Hashtag cloud",
          "Team members (Coming Soon)",
          "Early access to new features / roadmap",
        ],
        buttonText: "Start Free 7-Days Trial",
        buttonVariant: "contained",
      },
    ],
  };

  const handleSubscription = (values: any) => {
    localStorage.setItem("subscription::price", JSON.stringify(values));
    if (!user && history.location.pathname === "/") {
      /** case of signup after select price */
      history.push("/signup");
    } else if (!user && history.location.pathname === "/pricing") {
      /** case of signup without select price and then select price */
      const userId = localStorage.getItem("auth::registerUserId");
      const registerData = JSON.parse(
        localStorage.getItem("auth::register") as string
      );
      /** stripe checkout redirect on
       * 1. /signup-verification while signup
       * 2. /success while subscribe
       * localStorage: subscription::price
       * */
      if (userId && registerData) {
        onSubscribeUser({
          userId,
          email: registerData.email || "",
          type: "signup",
          successURL: "/signup-verification",
        });
      }
    } else if (user && history.location.pathname === "/pricing") {
      if (user && subscriptionId && currentSubscription) {
        /** case of select price for update subscription with user logged in */
        onUpdateSubscription({
          userId: user.id,
          email: user.email || "",
          type: "subscribe",
          successURL: "/success",
          subscriptionId: subscriptionId,
          history,
        });
      } else {
        /** case of select price for subscription with user logged in */
        /** stripe checkout redirect on
         * 1. /signup-verification while signup
         * 2. /success while subscribe
         * localStorage: subscription::price
         * */
        onSubscribeUser({
          userId: user.id,
          email: user.email || "",
          type: "subscribe",
          successURL: "/success",
        });
      }
    } else {
      localStorage.removeItem("subscription::price");
      console.log("Subscription not handled.");
    }
  };

  return (
    <>
      <Box id={"pricing"} className={classes.pricingWrapper}>
        <Container maxWidth={"lg"}>
          <Typography className={classes.featureHeader} component={"h2"}>
            Choose the plan that is right for you
          </Typography>
          {/*<Typography className={classes.featureSubtitle}>*/}
          {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,*/}
          {/*  purus sit amet luctus venenatis, lectus magna fringilla urna,*/}
          {/*  porttitor rhoncus dolor.*/}
          {/*</Typography>*/}

          <FormLabel style={{ color: "#000", paddingRight: 12 }}>
            Monthly
          </FormLabel>
          <FormControlLabel
            control={
              <IOSSwitch
                checked={payment.perYear}
                onChange={handleChange}
                name="perYear"
              />
            }
            label="Yearly"
          />
          <Hidden xsDown>
            <Typography component="span" className={classes.savings}>
              {payment.perYear && <>(save up to $120/year)</>}
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Typography component="div" className={classes.savings}>
              {payment.perYear && <>(save up to $120/year)</>}
            </Typography>
          </Hidden>

          {/*<Tabs*/}
          {/*    value={value}*/}
          {/*    onChange={handleChange}*/}
          {/*    aria-label="simple tabs example"*/}
          {/*    centered*/}
          {/*>*/}
          {/*  <Tab label="Monthly" {...a11yProps(0, theme)} />*/}
          {/*  <Tab label="Yearly" {...a11yProps(1, theme)} />*/}
          {/*</Tabs>*/}
          <Grid
            container
            justifyContent="center"
            spacing={4}
            className={classes.pricingListContainer}
          >
            {!payment.perYear
              ? priceList.monthly.map((values, index) => (
                  <Grid
                    key={`price-monthly-${index}`}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                  >
                    <Card className={classes.pricingCard}>
                      <CardContent className={classes.pricingCardContent}>
                        <Box className={classes.pricingCardHeader}>
                          <Typography
                            className={classes.pricingHeader}
                            variant="h5"
                            component="h2"
                          >
                            {values.title}
                          </Typography>
                          <Typography className={classes.pricingSubtitle}>
                            {values.price}
                            <span>/month</span>
                          </Typography>
                        </Box>
                        <List className={classes.featureList}>
                          {values.features.map((features, indexY) => (
                            <ListItem
                              key={`feature-list-${indexY}`}
                              className={classes.priceList}
                            >
                              <IconButton className={classes.check}>
                                <GiCheckMark />
                              </IconButton>
                              <Typography
                                style={{ color: "#585858" }}
                                variant="body2"
                                component="p"
                              >
                                {features}
                              </Typography>
                            </ListItem>
                          ))}
                          {values.additionalFeatures &&
                            values.additionalFeatures.map(
                              (features, indexY) => (
                                <>
                                  {indexY === 0 && (
                                    <Divider
                                      light
                                      className={classes.divider}
                                    />
                                  )}
                                  <ListItem
                                    key={`feature-list-${indexY}`}
                                    className={classes.priceList}
                                  >
                                    <IconButton className={classes.check}>
                                      <GiCheckMark />
                                    </IconButton>
                                    <Typography
                                      style={{ color: "#585858" }}
                                      variant="body2"
                                      component="p"
                                    >
                                      {features}
                                    </Typography>
                                  </ListItem>
                                </>
                              )
                            )}
                          <ListItem
                            key={`feature-list-support`}
                            className={classes.priceList}
                          >
                            <IconButton className={classes.check}>
                              <GiCheckMark />
                            </IconButton>
                            <Typography
                              style={{ color: "#585858" }}
                              variant="body2"
                              component="p"
                            >
                              Support
                            </Typography>
                          </ListItem>
                        </List>
                        <Button
                          className={classes.priceButton}
                          variant="contained"
                          color="primary"
                          onClick={() => handleSubscription(values)}
                          disabled={values.priceType === currentSubscription}
                        >
                          {subscriptionId
                            ? "UPDATE SUBSCRIPTION"
                            : "START FREE 7-DAY TRIAL"}
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              : priceList.yearly.map((values, index) => (
                  <Grid
                    key={`price-yearly-${index}`}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                  >
                    <Card className={classes.pricingCard}>
                      <CardContent className={classes.pricingCardContent}>
                        <Box className={classes.pricingCardHeader}>
                          <Typography
                            className={classes.pricingHeader}
                            variant="h5"
                            component="h2"
                          >
                            {values.title}
                          </Typography>
                          <Typography className={classes.pricingSubtitle}>
                            {values.monthPrice}
                            <span>/month</span>
                          </Typography>
                          <Typography className={classes.annualPricing}>
                            {values.price}
                            <span> billed annually</span>
                          </Typography>
                        </Box>
                        <List className={classes.featureList}>
                          {values.features.map((features, indexY) => (
                            <ListItem
                              key={`feature-list-${indexY}`}
                              className={classes.priceList}
                            >
                              <IconButton className={classes.check}>
                                <GiCheckMark />
                              </IconButton>
                              <Typography
                                style={{ color: "#585858" }}
                                variant="body2"
                                component="p"
                              >
                                {features}
                              </Typography>
                            </ListItem>
                          ))}
                          {values.additionalFeatures &&
                            values.additionalFeatures.map(
                              (features, indexY) => (
                                <>
                                  {indexY === 0 && (
                                    <Divider
                                      light
                                      className={classes.divider}
                                    />
                                  )}
                                  <ListItem
                                    key={`feature-list-${indexY}`}
                                    className={classes.priceList}
                                  >
                                    <IconButton className={classes.check}>
                                      <GiCheckMark />
                                    </IconButton>
                                    <Typography
                                      style={{ color: "#585858" }}
                                      variant="body2"
                                      component="p"
                                    >
                                      {features}
                                    </Typography>
                                  </ListItem>
                                </>
                              )
                            )}
                          <ListItem
                            key={`feature-list-support`}
                            className={classes.priceList}
                          >
                            <IconButton className={classes.check}>
                              <GiCheckMark />
                            </IconButton>
                            <Typography
                              style={{ color: "#585858" }}
                              variant="body2"
                              component="p"
                            >
                              Support
                            </Typography>
                          </ListItem>
                        </List>
                        <Button
                          className={classes.priceButton}
                          variant="contained"
                          color="primary"
                          onClick={() => handleSubscription(values)}
                          disabled={values.priceType === currentSubscription}
                        >
                          {subscriptionId
                            ? "UPDATE SUBSCRIPTION"
                            : "START FREE 7-DAY TRIAL"}
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Pricing;

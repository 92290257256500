import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

function transform(node: any, index: any) {
    // console.log('node: ', node);


    // Transform <ul> into <ol>
    // A node can be modified and passed to the convertNodeToElement function which will continue to render it and it's children
    // if (node.type === "tag" && node.name === "ul") {
    //   node.name = "ol";
    //   return convertNodeToElement(node, index, transform);
    // }

    // return an <i> element for every <b>
    // a key must be included for all elements
    // if (node.type === "tag" && node.name === "b") {
    //   return <i key={index}>{processNodes(node.children, transform)}</i>;
    // }

    try {
        if (node.type === "tag" && node.name === "div") {
            if (node.children) {
                let componentText = '';
                node.children.forEach((childrenNode: any) => {
                    if (childrenNode.type === "tag" && childrenNode.name === "img") {
                        // const {src} = childrenNode.attribs;
                        componentText += '';
                    } else if (childrenNode.type === "tag" && childrenNode.name === 'a') {
                        componentText += '';
                        // if (childrenNode.children[0].data) {
                        //     componentText += `\n[${childrenNode.children[0].data}: ${childrenNode.attribs.href}]`;
                        // }
                    } else if (childrenNode.type === "tag" && childrenNode.name === 'br') {
                        componentText += ``;
                    } else if (childrenNode.type === "tag" && (childrenNode.name === 'em' || childrenNode.name === 'b' || childrenNode.name === 'i')) {
                        componentText += `${childrenNode.children[0].data}`;
                    } else {
                        const {data} = childrenNode;
                        componentText += data;
                    }
                });
                node.type = "text";
                node.data = `${componentText}`;
                return convertNodeToElement(node, index, transform);
            } else {
                const { data } = node;
                node.type = "text";
                node.data = `${data}`;
                return convertNodeToElement(node, index, transform);
            }
        }
    } catch (error) {
        // handling any type of error on individual tag parser
        console.log('ERROR: ', error);
        node.type = 'text';
        node.data = ``;
        return convertNodeToElement(node, index, transform);
    }

    // return null to block certain elements
    // don't allow <span> elements
    try {
        if (node.type === "tag" && node.name === "span") {
            if (node.children) {
                let componentText = '';
                node.children.forEach((childrenNode: any) => {
                    if (childrenNode.type === "tag" && childrenNode.name === "img") {
                        // const {src} = childrenNode.attribs;
                        componentText += '';
                    } else if (childrenNode.type === "tag" && childrenNode.name === 'a') {
                        componentText += '';
                        // if (childrenNode.children[0].data) {
                        //     componentText += `\n[${childrenNode.children[0].data}: ${childrenNode.attribs.href}]`;
                        // }
                    } else if (childrenNode.type === "tag" && childrenNode.name === 'br') {
                        componentText += ``;
                    } else if (childrenNode.type === "tag" && (childrenNode.name === 'em' || childrenNode.name === 'b' || childrenNode.name === 'i')) {
                        componentText += `${childrenNode.children[0].data}`;
                    } else {
                        const {data} = childrenNode;
                        componentText += data;
                    }
                });
                node.type = "text";
                node.data = `${componentText}`;
                return convertNodeToElement(node, index, transform);
            } else {
                const { data } = node;
                node.type = "text";
                node.data = `${data}`;
                return convertNodeToElement(node, index, transform);
            }
        }
    } catch (error) {
        // handling any type of error on individual tag parser
        console.log('ERROR: ', error);
        node.type = 'text';
        node.data = ``;
        return convertNodeToElement(node, index, transform);
    }

    try {
        if (node.type === "tag" && node.name === "p") {
            if (node.children) {
                let componentText = '';
                node.children.forEach((childrenNode: any) => {
                    if (childrenNode.type === "tag" && childrenNode.name === "img") {
                        // const {src} = childrenNode.attribs;
                        componentText += '';
                    } else if (childrenNode.type === "tag" && childrenNode.name === 'a') {
                        componentText += '';
                        // if (childrenNode.children[0].data) {
                        //     componentText += `\n[${childrenNode.children[0].data}: ${childrenNode.attribs.href}]`;
                        // }
                    } else if (childrenNode.type === "tag" && childrenNode.name === 'br') {
                        componentText += ``;
                    } else if (childrenNode.type === "tag" && (childrenNode.name === 'em' || childrenNode.name === 'b' || childrenNode.name === 'i')) {
                        componentText += `${childrenNode.children[0].data}`;
                    } else {
                        const {data} = childrenNode;
                        componentText += data;
                    }
                });
                node.type = "text";
                node.data = `${componentText}`;
                return convertNodeToElement(node, index, transform);
            } else {
                const { data } = node;
                node.type = "text";
                node.data = `${data}`
                return convertNodeToElement(node, index, transform);
            }
            // console.log('nn node: ', node);
        }
    } catch (error) {
        // handling any type of error on individual tag parser
        console.log('ERROR: ', error);
        node.type = 'text';
        node.data = ``;
        return convertNodeToElement(node, index, transform);
    }

    try {
        if (node.type === "tag" && node.name === "img") {
            // const {src} = node.attribs;
            node.type = 'text';
            node.data = ``;
            return convertNodeToElement(node, index, transform);
        }
    } catch (error) {
        // handling any type of error on individual tag parser
        console.log('ERROR: ', error);
        node.type = 'text';
        node.data = ``;
        return convertNodeToElement(node, index, transform);
    }

    try {
        // all links must open in a new window
        if (node.type === "tag" && node.name === "a") {
            node.type = 'text';
            node.data = ``;
            // if (node.children[0].data) {
            //     node.data = `[${node.children[0].data}: ${node.attribs.href}]`;
            // }
            return convertNodeToElement(node, index, transform);
        }
    } catch (error) {
        // handling any type of error on individual tag parser
        console.log('ERROR: ', error);
        node.type = 'text';
        node.data = ``;
        return convertNodeToElement(node, index, transform);
    }

    try {
        if (node.type === "tag" && node.name === 'br') {
            node.type = "text";
            node.data = ``;
            return convertNodeToElement(node, index, transform);
        }
        if (node.type === "tag" && (node.name === 'b' || node.name === 'i' || node.name === 'em')) {
            node.type = "text";
            node.data = `${node.children[0].data}`;
            return convertNodeToElement(node, index, transform);
        }
    } catch (error) {
        // handling any type of error on individual tag parser
        console.log('ERROR: ', error);
        node.type = 'text';
        node.data = ``;
        return convertNodeToElement(node, index, transform);
    }

    try {
        if (node.type === "tag" && node.name === "button") {
            // return (
            //     <Button variant="contained" color="primary" key={index}>
            //       {processNodes(node.children, transform)}
            //     </Button>
            // );
            return null;
        }
    } catch (error) {
        // handling any type of error on individual tag parser
        console.log('ERROR: ', error);
        node.type = 'text';
        node.data = ``;
        return convertNodeToElement(node, index, transform);
    }
}

export const RSSBodyParser = (props: any) => {
    const html = props.description || '';
    const link = props.link;
    // console.log('html: ', html);
    try {
        const options = {
            decodeEntities: true,
            transform
        };
        const renderText = ReactHtmlParser(html, options);
        let finalText = '';
        renderText.forEach((value: string) => {
            finalText += value;
        });
        // if (finalText.length > 200) {
        //     finalText = finalText.slice(0, 200) + '...';
        // }
        if (link) {
            finalText += `\n ${link}`;
        }
        // console.log('finalText: ', finalText);
        return finalText;
    } catch (error) {
        // handling error on html parser
        console.log('ERROR: ', error);
        return '';
    }
}

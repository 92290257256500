import {
  Card,
  CardContent,
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import format from "date-fns/format";
import React from "react";
import { useHistory } from "react-router";
import { AiOutlineTwitter } from "react-icons/ai";
import ScheduleIcon from "@material-ui/icons/Schedule";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    draft: {
      position: "relative",
      boxShadow: "none",
      width: "99%",
      background: (props: { smallCardView?: boolean }) =>
        props.smallCardView
          ? "transparent"
          : theme.palette.type === "light"
          ? "#fff"
          : "#535050",

      height: (props: { smallCardView?: boolean }) =>
        !props.smallCardView ? "108px" : "38px",
      cursor: "pointer",
      marginTop: "5px",
      marginRight: "2px",
      marginLeft: "2px",
      marginBottom: "5px",
      overflow: "hidden",
      [theme.breakpoints.down("md")]: {
        width: "98%",
      },
    },
    cardContent: {},
    topSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    draftNotifier: {
      display: "flex",
      alignItems: "center",
      gap: "2px",
      "&svg": {
        fontSize: "15px",
      },
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        justifyContent: "center",
        textAlign: "center",
      },
    },
    draftNotifierText: {
      fontSize: "12px",
      fontWeight: "bold",
      marginLeft: 5,
      [theme.breakpoints.down("md")]: {
        fontSize: "9px",
      },
    },
    avatar: {
      width: "14px",
      height: "14px",
      fontSize: "10px",
    },
    draftText: {
      position: "relative",
      padding: "9px 10px",
      wordBreak: "normal",
      fontSize: "12px",
    },
    draftUsername: {
      padding: "0",
      paddingBottom: 0,
      fontSize: "10px",
    },
    line: {
      width: "2px",
      background: "hsl(205, 20%, 78%)",
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "6px",
    },
    draftActionContainer: {
      position: "absolute",
      bottom: 0,
      marginTop: "20px",
      height: "26px",
      background:
        theme.palette.type === "light"
          ? "linear-gradient(0deg, white,white, transparent)"
          : "linear-gradient(0deg, rgb(66 66 66),rgb(66 66 66), transparent)",
      left: 0,
      right: 0,
      display: "flex",
      alignItems: "end",
      padding: "5px ",
      "& svg": {
        color:
          theme.palette.type === "light"
            ? "#606060"
            : "rgba(255, 255, 255, 0.7)",
      },
      "&button": {
        padding: "8px",
      },
      "&&& svg": {
        fontSize: "18px",
      },
    },
  })
);
interface IScheduleCard {
  schedulePost: any;

  smallCardView?: boolean;
  onDeleteSchedulePost?: (postId: string) => void;
  hideIconOnDayView?: boolean;
}
function ScheduleCard(props: IScheduleCard) {
  const {
    schedulePost,

    onDeleteSchedulePost,
    hideIconOnDayView,
    smallCardView,
  } = props;
  const classes = useStyles({ smallCardView });
  const history = useHistory();

  return (
    <>
      <Card className={classes.draft}>
        <CardContent
          className={classes.cardContent}
          style={{
            padding: smallCardView ? "4px 5px 1px 1px" : "10px 14px 1px 14px",
          }}
          onClick={(e) => {
            history.push(`thread/${schedulePost.postId}?view=schedule`);
          }}
        >
          <div className={classes.topSection}>
            <div className={classes.draftNotifier}>
              <AiOutlineTwitter fontSize={20} fill={"#1DA1F2"} />
              {/* {schedulePost &&
              schedulePost.filters.split("#")[2].toLowerCase() === "true" ? (
                <Tooltip title={"Tweet is posted."}>
                  <DoneIcon style={{ color: "#0cd30c" }} />
                </Tooltip>
              ) : schedulePost.errors ? (
                <Tooltip
                  title={
                    schedulePost.errors
                      ? schedulePost.errors
                      : "Something went wrong."
                  }
                >
                  <ErrorOutlineIcon style={{ color: "#c90a0a" }} />
                </Tooltip>
              ) : (
                <Tooltip
                  title={`Scheduled for ${format(
                    new Date(schedulePost.scheduleAt),
                    "dd MMMM, p"
                  )}`}
                >
                  <QueryBuilderIcon />
                </Tooltip>
              )} */}

              <Typography
                className={classes.draftNotifierText}
                component="p"
                color="textSecondary"
              >
                {format(new Date(schedulePost.scheduleAt), "h:mm")}
              </Typography>
            </div>
            {schedulePost &&
            schedulePost.filters.split("#")[2].toLowerCase() === "true" ? (
              <Tooltip
                title={`Scheduled for ${format(
                  new Date(schedulePost.scheduleAt),
                  "dd MMMM, p"
                )}`}
              >
                <DoneIcon style={{ color: "#0cd30c" }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  schedulePost.errors
                    ? schedulePost.errors
                    : "Scheduled to be posted."
                }
              >
                <ScheduleIcon style={{ color: "#f3dd5a" }} />
              </Tooltip>
            )}
          </div>
          {!smallCardView &&
            schedulePost.post.map((tweet: string, index: any) => (
              <>
                <CardContent className={classes.draftText}>
                  {tweet ? tweet : "Write something to see the preview"}
                </CardContent>
              </>
            ))}
        </CardContent>

        {!smallCardView && !hideIconOnDayView && (
          <div className={classes.draftActionContainer}>
            <IconButton
              onClick={() => {
                if (onDeleteSchedulePost) {
                  if (
                    window.confirm(
                      "Are you sure you want to delete the scheduled tweet?"
                    )
                  ) {
                    onDeleteSchedulePost(schedulePost.postId);
                  }
                }
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
            {/* display edit only if it is not posted */}
            {schedulePost &&
              schedulePost.filters.split("#")[2].toLowerCase() === "false" && (
                <IconButton
                  onClick={() => {
                    history.push(`schedule/${schedulePost.postId}`);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
          </div>
        )}
      </Card>
    </>
  );
}

export default ScheduleCard;

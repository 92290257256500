import * as AuthTypes from 'src/store/auth/auth.types';

/** COGNITO SIGNUP, LOGIN, LOGOUT */
export const registerNewUser = (values: any) => ({
  type: AuthTypes.REGISTER_NEW_USER,
  payload: { values }
});

export const registerNewUserFail = () => ({
  type: AuthTypes.REGISTER_NEW_USER_FAIL
});

export const signupStart = (values: any, history: any) => ({
  type: AuthTypes.SIGNUP_START,
  payload: { values, history }
});

export const signupSuccess = (data: any) => ({
  type: AuthTypes.SIGNUP_SUCCESS,
  payload: data
});

export const signupFail = (error: any) => ({
  type: AuthTypes.SIGNUP_FAIL,
  payload: error
});

export const signupVerifyStart = (email: any, code: any, history: any) => ({
  type: AuthTypes.SIGNUP_VERIFY_START,
  payload: { email, code, history }
});

export const loginStart = (values: any, history: any) => ({
  type: AuthTypes.LOGIN_START,
  payload: { values, history }
});

export const loadCurrentUserStart = () => ({
  type: AuthTypes.LOAD_CURRENT_USER_START
});

export const loadCurrentUserSuccess = (data: any) => ({
  type: AuthTypes.LOAD_CURRENT_USER_SUCCESS,
  payload: data
});

export const loadCurrentUserFail = (error: any) => ({
  type: AuthTypes.LOAD_CURRENT_USER_FAIL,
  payload: error
});

export const fetchCurrentUserStart = (userId: string) => ({
  type: AuthTypes.FETCH_CURRENT_USER_START,
  payload: { userId }
});

export const fetchCurrentUserSuccess = (data: any) => ({
  type: AuthTypes.FETCH_CURRENT_USER_SUCCESS,
  payload: data
});

export const fetchCurrentUserFail = (error: any) => ({
  type: AuthTypes.FETCH_CURRENT_USER_FAIL,
  payload: error
});

export const logoutStart = (history: any) => ({
  type: AuthTypes.LOGOUT_START,
  payload: { history }
});

export const logoutSuccess = () => ({
  type: AuthTypes.LOGOUT_SUCCESS
});

export const setActiveAccount = (accountId: string) => ({
  type: AuthTypes.SET_USER_ACTIVE_ACCOUNT,
  payload: { accountId }
});

export const checkSubVendorAUser = (invitationId: string, history: any) => ({
  type: AuthTypes.CHECK_SUB_VENDOR_A_USER,
  payload: { invitationId, history }
});

export const loadInvitationSuccess = (invitation: any) => ({
  type: AuthTypes.LOAD_INVITATION_SUCCESS,
  payload: invitation
});

/** STRIPE SUBSCRIPTION */
// export const fetchUserSubscription = (userId: string) => ({
//   type: AuthTypes.FETCH_USER_SUBSCRIPTION_START,
//   payload: { userId }
// });
//
// export const fetchUserSubscriptionSuccess = (data: any) => ({
//   type: AuthTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
//   payload: { data }
// });

export const cancelUserSubscription = (userId: string) => ({
  type: AuthTypes.CANCEL_USER_SUBSCRIPTION_START,
  payload: { userId }
});

export const cancelUserSubscriptionSuccess = (data: any) => ({
  type: AuthTypes.CANCEL_USER_SUBSCRIPTION_SUCCESS,
  payload: { data }
});

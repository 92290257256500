import * as VendorTypes from 'src/store/vendor/vendor.types';

export const vendorInvitationStart = (values: any) => ({
  type: VendorTypes.VENDOR_INVITATION_START,
  payload: { values }
});

export const vendorInvitationSuccess = (data: any) => ({
  type: VendorTypes.VENDOR_INVITATION_SUCCESS,
  payload: data
});

export const vendorInvitationFail = (error: any) => ({
  type: VendorTypes.VENDOR_INVITATION_FAIL,
  payload: error
});

export const fetchUserVendorStart = (userId: string) => ({
  type: VendorTypes.FETCH_USER_VENDOR_START,
  payload: { userId }
});

export const fetchUserVendorSuccess = (data: any) => ({
  type: VendorTypes.FETCH_USER_VENDOR_SUCCESS,
  payload: data
});

export const fetchUserVendorFail = (error: any) => ({
  type: VendorTypes.FETCH_USER_VENDOR_FAIL,
  payload: error
});

export const removeVendorStart = (vendorId: string) => ({
  type: VendorTypes.REMOVE_VENDOR_START,
  payload: { vendorId }
});

export const removeVendorSuccess = (data: any) => ({
  type: VendorTypes.REMOVE_VENDOR_SUCCESS,
  payload: data
});

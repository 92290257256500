import React from 'react';
import {
  Button,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import ScheduleSkeleton from 'src/components/skeleton-profile';
import { subscriptionAccess, isUserAVendor } from 'src/components/utils';
import { Agency, SocialAccount } from 'src/pages/Profile/components';
import container from '../Profile/Profile.container';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 600,
    padding: '35px 0px',
    // background: '#fff',
    [theme.breakpoints.down('sm')]: {
        padding: '50px 0px 30px 0px',
    }
  },
  cardRoot: {
    marginTop: 16
  },
  heading: {
    fontWeight: 500,
    fontSize: '1.25rem'
  },
  cardAction: {
    display: 'flow-root',
    padding: '16px 0px 0px 0px'
  },
  update: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1)
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1)
    }
  },
  paper: {
    padding: theme.spacing(3),
    maxWidth: 500,
    margin: 'auto',
    textAlign: 'center'
  },
  button: {
    marginTop: 50,
    marginBottom: 20
  },
  row: {
    margin: 'auto',
    '&:hover': {
      backgroundColor: 'rgb(3 0 71 / 5%)'
    }
  }
}));

export const Profile = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    authState: {
      loading,
      subscriptionLoading,
      user
    },
    accountState: {
      results
    },
    vendorState: {
      results: vendorData
    },
    history,
    onRemoveTweetAccount,
    onSetActiveAccount,
    onVendorInvitation,
    onRemoveVendor,
    // onSubscribeUser,
    onCancelUserSubscription,
    onObtainOauthRequestTokenAction,
    onShowNotification
  } = props;
  const [id, setId] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [userName, setUserName]:any = React.useState(null);
  const [subscriptionId, setSubscriptionId] = React.useState<string|null>(null);
  const [subscription, setSubscription]: any = React.useState(null);
  const [active_account, setActiveAccount] = React.useState(null);
  const [access, setAccess] = React.useState<any>(null);
  const [isRemoveAccess, setRemoveAccess] = React.useState<boolean>(false);
  const [removalAccount, setRemovalAccount] = React.useState<any|null>(null);
  const [canAddAccount, setCanAddAccount] = React.useState<boolean>(false);
  const [isTeam, setIsTeam] = React.useState<any|null>(null);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [vendorConfirm, setVendorConfirm] = React.useState(false);
  const [isVendor, setIsVendor] = React.useState(false);
  const [vendorList, setVendorList] = React.useState([]);
  const accountList = Object.values(results);

  React.useEffect(() => {
    if (vendorData) {
      setVendorList(Object.values(vendorData));
    }
  }, [vendorData]);

  React.useEffect(() => {
    if (user) {
      setId(user.id);
      setEmail(user.email);
      setUserName(`${user.firstname} ${user.lastname}`);
      setSubscriptionId(user.subscriptionId);
      setSubscription(user.subscription);
      setActiveAccount(user.active_account);
      if (user.subscriptionType && user.subscriptionStatus !== 'canceled') {
        (async function anyName() {
          const allow = await subscriptionAccess(user.subscriptionType);
          setAccess(allow);
          setRemoveAccess(allow && allow.removeAccount);
          setCanAddAccount(allow && (accountList.length < allow.account) && user.subscriptionStatus !== 'canceled');
          setIsTeam(allow && allow.team);
        })();
      }
      if (user && user.role) {
        setIsVendor(isUserAVendor(user.role));
      }
    }
  }, [user]);
  const isSubscriber = subscriptionId && subscription && subscription.status !== 'canceled';

  const handleCloseConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const handleCloseVendorConfirm = () => {
    setVendorConfirm(!vendorConfirm);
  };

  const removeAccountConfirm = (account: any) => {
    if (isRemoveAccess) {
      setRemovalAccount(account);
      setOpenConfirm(true);
    } else {
      onShowNotification('error', `Your subscription don't have this access. Upgrade to higher tier subscription plan.`);
    }
  };

  const removeAccountHandler = (value: any) => {
    // console.log('value: ', value);
    // console.log('removalAccount: ', removalAccount);
    if (removalAccount) {
      onRemoveTweetAccount(removalAccount.twitterId, value);
    }
  };

  const activeAccountHandler = (accountId: string) => {
    if (accountId) {
      onSetActiveAccount(accountId);
    }
  };

  const addAccount = () => {
    if (user && user.id) {
      if (canAddAccount) {
        onObtainOauthRequestTokenAction();
      } else {
        onShowNotification('error', 'This feature is only for subscribed user. Subscribe now.');
      }
    } else {
      onShowNotification('error', 'User not logged in.');
    }
  };

  const handleVendorInvitation = (values: any) => {
    if (values.email) {
      if (vendorList.filter((value: any) => value.email === values.email)) {
        onShowNotification('error', 'Email already listed as vendor.');
      } else {
        onVendorInvitation(values);
      }
    }
  };

  const subscribeUser = async () => {
    if (user && user.id) {
      /** stripe checkout redirect on
       * 1. /signup-verification while signup
       * 2. /success while subscribe
       * localStorage: subscription::price
       * */
      // onSubscribeUser({
      //   userId: id,
      //   email: email || '',
      //   type: 'subscribe',
      //   successURL: '/success'
      // });
      history.push('/pricing');
    } else {
      onShowNotification('error', 'User not logged in.');
    }
  };

  const handleSubscriptionCancel = () => {
    if (subscriptionId && id) {
      onCancelUserSubscription(id);
    }
  };

  const handleSubscriptionUpdate = () => {
    if (user && user.id && subscriptionId) {
      // localStorage.setItem('subscription::id', subscriptionId);
      history.push('/pricing');
    } else {
      onShowNotification('error', 'User not logged in.');
    }
  };

  const formatDateTimeMilliseconds = (stringDate: number) => {
    const mydate = new Date(stringDate * 1000);
    return `${mydate.toUTCString().substring(0, 16)}`;
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <ScheduleSkeleton />
      ) : (
        <Container style={{ maxWidth: 1400 }}>
          <Grid style={{ margin: '2% 0' }}>
            <Card className={classes.cardRoot}>
              <CardHeader
                title="Account"
                classes={{
                  title: classes.heading
                }}
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    Username: {userName}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    Email: {email}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider light />
              {!isVendor && (
                <SocialAccount
                  classes={classes}
                  matchesXS={matchesXS}
                  loading={loading}
                  user={user}
                  accountList={accountList}
                  openConfirm={openConfirm}
                  handleCloseConfirm={handleCloseConfirm}
                  removeAccountHandler={removeAccountHandler}
                  active_account={active_account}
                  activeAccountHandler={activeAccountHandler}
                  removeAccountConfirm={removeAccountConfirm}
                  addAccount={addAccount}
                />
              )}
              {isTeam && (
                <>
                  <Divider light />
                  <CardHeader
                    title="Agency Setting"
                    classes={{
                      title: classes.heading
                    }}
                  />
                  <Agency
                    classes={classes}
                    loading={loading}
                    user={user}
                    matchesXS={matchesXS}
                    openConfirm={vendorConfirm}
                    handleCloseConfirm={handleCloseVendorConfirm}
                    handleInvitation={handleVendorInvitation}
                    isVendor={isVendor}
                    vendorList={vendorList}
                    onRemoveVendorHandler={onRemoveVendor}
                  />
                </>
              )}
              <Divider light />
              <CardHeader
                title="Subscription"
                classes={{
                  title: classes.heading
                }}
              />
              <CardContent>
                {isSubscriber ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        Subscription Type: {(user?.subscriptionType && access?.name) ?? null}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        Status: {subscription.status}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        Quantity: {subscription.quantity}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        Method: {subscription.collection_method}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        Start: {formatDateTimeMilliseconds(subscription.current_period_start)}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        End: {formatDateTimeMilliseconds(subscription.current_period_end)}
                      </Grid>
                    </Grid>
                    <CardActions disableSpacing className={classes.cardAction}>
                      <Button
                        type="button"
                        fullWidth={matchesXS}
                        variant="contained"
                        color="primary"
                        disabled={subscriptionLoading}
                        onClick={handleSubscriptionCancel}
                      >
                        {subscriptionLoading ? 'Loading...' : 'Cancel Subscription'}
                      </Button>
                      <Button
                        type="button"
                        fullWidth={matchesXS}
                        variant="contained"
                        color="primary"
                        className={classes.update}
                        disabled={subscriptionLoading}
                        onClick={handleSubscriptionUpdate}
                      >
                        {subscriptionLoading ? 'Loading...' : 'Update Subscription'}
                      </Button>
                    </CardActions>
                  </>
                ) : (
                  <Button
                    type="button"
                    fullWidth={matchesXS}
                    variant="contained"
                    color="primary"
                    disabled={subscriptionLoading}
                    onClick={subscribeUser}
                  >
                    {subscriptionLoading ? 'Loading...' : 'Subscribe'}
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Container>
      )}
    </div>
  );
}

export default container(Profile);

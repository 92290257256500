import React from "react";
import {
  Button,
  createStyles,
  List,
  ListItem,
  makeStyles,
  Popover,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import AvatarComponent from "src/components/AvatarComponent";
import TitleComponent from "src/components/TitleComponent";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    draftText: {
      position: "relative",
      padding: "0",
      fontSize: "14px",
      display: " -webkit-box",
      overflow: "hidden",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      wordBreak: "break-all",
    },
    draftNotifier: {
      fontSize: "11px",
      paddingBottom: "16px",
      display: "flex",
      alignItems: "center",
      gap: "4px",
      paddingLeft: 8,
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: "#ccc",
      color: "#fff",
    },
    username: {
      fontSize: "13px",
      fontWeight: 500,
    },
    content: {
      padding: "0px 18px 6px 18px",
      cursor: "pointer",
    },
  })
);

interface IDisplayCard {
  avatar: string;
  username: string;
  historyData: any;
  onDeleteHistoryPost?: (sk: string, history: any) => void;
  onRetweetThread?: ({ sk }: { sk: string }) => void;
  onUnretweetThread?: ({ sk }: { sk: string }) => void;
  onDeleteDraft?: (postId: string) => void;
  onDeleteSchedulePost?: (postId: string) => void;
  area?: string;
}
function DisplayCard(props: IDisplayCard) {
  const {
    avatar,
    username,
    historyData,
    onDeleteHistoryPost,
    onRetweetThread,
    onUnretweetThread,
    onDeleteSchedulePost,
    onDeleteDraft,
    area,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const formatEpoch = (epoch: number) => {
    return new Date(epoch);
  };

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            username ? (
              <img
                src={`https://unavatar.io/twitter/${username}`}
                alt={username}
                style={{
                  borderRadius: "50%",
                  padding: "4px",
                  height: "36px",
                  width: "36px",
                }}
              />
            ) : (
              <AvatarComponent avatar={avatar} />
            )
          }
          title={<TitleComponent username={username} />}
          action={
            <IconButton
              id="pop-over"
              aria-label="settings"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          }
        />
        <Popover
          id={'"pop-over"'}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List disablePadding>
            <ListItem disableGutters>
              <Button
                startIcon={<DeleteOutlineIcon style={{ fontSize: "15px" }} />}
                onClick={(e) => {
                  console.log("delete", area);
                  if (area) {
                    if (area === "draft") {
                      if (
                        window &&
                        window.confirm(
                          "Are you sure you want to delete the draft?"
                        ) &&
                        onDeleteDraft
                      ) {
                        onDeleteDraft(historyData.postId);
                      }
                    } else if (area === "schedule") {
                      if (
                        window &&
                        window.confirm(
                          "Are you sure you want to delete the schedule?"
                        ) &&
                        onDeleteSchedulePost
                      ) {
                        onDeleteSchedulePost(historyData.sk);
                      }
                    }
                  } else {
                    if (
                      window &&
                      window.confirm(
                        "Are you sure you want to delete the tweet?"
                      ) &&
                      onDeleteHistoryPost
                    ) {
                      onDeleteHistoryPost(historyData.sk, history);
                    }
                  }
                }}
              >
                Delete Thread
              </Button>
            </ListItem>
            {area && area !== "draft" && area !== "schedule" && (
              <ListItem disableGutters>
                <Button
                  startIcon={<EditIcon style={{ fontSize: "15px" }} />}
                  onClick={() => {
                    if (historyData.isRetweeted && onUnretweetThread) {
                      onUnretweetThread({ sk: historyData.sk });
                    } else {
                      onRetweetThread &&
                        onRetweetThread({ sk: historyData.sk });
                    }
                  }}
                >
                  {!historyData.isRetweeted
                    ? "Retweet Thread"
                    : "Unretweet Thread"}
                </Button>
              </ListItem>
            )}
          </List>
        </Popover>
        <CardContent
          className={classes.content}
          onClick={() => {
            area === "draft"
              ? history.push(`draft/${historyData.postId}`)
              : history.push(`thread/${historyData.postId}?view=history`);
          }}
        >
          <Typography className={classes.draftText}>
            {historyData.post
              ? historyData.post[0]
              : "Write something to see the preview"}
          </Typography>
        </CardContent>
        <CardActions
          disableSpacing
          onClick={() =>
            history.push(`thread/${historyData.postId}?view=history`)
          }
        >
          <Typography
            className={classes.draftNotifier}
            component="p"
            color="textSecondary"
          >
            <DoneIcon style={{ color: "#0cd30c", fontSize: "17px" }} />
            <span>
              {format(
                formatEpoch(historyData.scheduleAt ?? historyData.createdAt),
                "MMMM dd, yyyy, p"
              )}
            </span>
          </Typography>
        </CardActions>
      </Card>
    </>
  );
}

export default DisplayCard;

import React from 'react';
import {
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';

const SidebarMoreItem = (props: any) => {
  const { openList, handleClick } = props;
  return openList ? (
    <ExpandLess
      style={{ color: 'rgb(169, 169, 169)', paddingRight: 16 }}
      onClick={handleClick}
    />
  ) : (
    <ExpandMore
      style={{ color: 'rgb(169, 169, 169)', paddingRight: 16 }}
      onClick={handleClick}
    />
  );
}

export default SidebarMoreItem;
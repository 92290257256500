import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Hub } from "aws-amplify";
import { Button, Card, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import useQuery from "../../utils/useQuery";
import container from "./SignUp.container";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 600,
    padding: "35px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0px 30px 0px",
    },
  },
  card: {
    padding: "10px 20px 50px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
      maxWidth: "100%",
    },
  },
  subtitle: {
    fontSize: "14px",
    margin: "10px auto",
    textAlign: "center",
  },
  paper: {
    marginTop: theme.spacing(8),
    flexDirection: "column",
    alignItems: "center",
  },
  titleOne: {
    padding: 3,
    fontSize: 24,
    display: "flex",
    justifyContent: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    color: theme.palette.primary.main,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
  },
  text: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
    textDecoration: "none",
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
  },
}));

const Signup = (props: any) => {
  const {
    authState: { signUpLoader, invitation },
    history,
    onRegisterNewUser,
    onSignupStart,
    onSubscribeUser,
    onCheckSubVendorAUser,
  } = props;
  const classes = useStyles();
  const query = useQuery();
  const invitationId = query.get("invitationId");
  const [vendorId, setVendorId] = React.useState<string | null>(null);
  const [vendorEmail, setVendorEmail] = React.useState<string | null>(null);

  const authStateChange = useCallback(() => {
    Hub.listen("auth", async ({ payload }) => {
      // console.log('payload: ', payload);
      if (payload.event === "signUp") {
        const parsedData = JSON.parse(
          localStorage.getItem("auth::register") as string
        );
        // console.log('parsedData: ', parsedData);
        if (parsedData.vendorId) {
          onSignupStart(
            {
              id: payload.data.userSub,
              name: parsedData.name,
              firstname: parsedData.firstname,
              lastname: parsedData.lastname,
              email: parsedData.email,
              vendorId: parsedData.vendorId,
            },
            history
          );
          history.push("/signup-verification");
        } else {
          onSignupStart(
            {
              id: payload.data.userSub,
              name: parsedData.name,
              firstname: parsedData.firstname,
              lastname: parsedData.lastname,
              email: parsedData.email,
            },
            history
          );
          const selectedSubscriptionPrice = JSON.parse(
            localStorage.getItem("subscription::price") as string
          ); /// get price selected by user for subscription
          /** check user select price if not then redirect to price select page */
          if (selectedSubscriptionPrice) {
            /** stripe checkout redirect on
             * 1. /signup-verification while signup
             * 2. /success while subscribe
             * localStorage: subscription::price
             * */
            onSubscribeUser({
              userId: payload.data.userSub,
              email: parsedData.email || "",
              type: "signup",
              successURL: "/signup-verification",
            });
          } else {
            localStorage.setItem("auth::registerUserId", payload.data.userSub);
            history.push("/pricing");
          }
        }
      }
    });
  }, []);

  React.useEffect(() => {
    authStateChange();
  }, []);

  React.useEffect(() => {
    if (invitationId) {
      query.delete("invitationId");
      history.replace({ search: query.toString() });
      onCheckSubVendorAUser(invitationId, history);
    }
  }, [invitationId]);

  React.useEffect(() => {
    if (invitation) {
      setVendorId(invitation.vendorId);
      setVendorEmail(invitation.email);
    }
  }, [invitation]);

  const registerNewUser = async (values: any) => {
    if (vendorId && vendorEmail) {
      values.vendorId = vendorId;
    }
    onRegisterNewUser(values);
  };

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="sm">
        <Grid style={{ margin: "2% 0" }}>
          <Card className={classes.card}>
            <div className={classes.paper}>
              <Typography component="span" className={classes.titleOne}>
                Signup
              </Typography>
              <Typography className={classes.subtitle}>
                Tweetsky: The best twitter thread maker. Start your Free Trial
              </Typography>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  email: vendorEmail || "",
                  firstname: "",
                  lastname: "",
                  password: "",
                  password2: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email address")
                    .required("Please add your email address")
                    .max(254, "Really!"),
                  firstname: Yup.lazy(() => {
                    return Yup.string()
                      .required("Please add your firstname")
                      .matches(/^[a-zA-Z ]+$/, "Only letters")
                      .max(32, "Should be 32 characters or less");
                  }),
                  lastname: Yup.lazy(() => {
                    return Yup.string()
                      .required("Please add your lastname")
                      .matches(/^[a-zA-Z ]+$/, "Only letters")
                      .max(32, "Should be 32 characters or less");
                  }),
                  password: Yup.lazy(() => {
                    return Yup.string()
                      .required("No password provided")
                      .min(8, "Too Short")
                      .max(32, "Too long");
                  }),
                  password2: Yup.lazy(() => {
                    return Yup.string()
                      .required("No password provided")
                      .oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match"
                      );
                  }),
                })}
                onSubmit={registerNewUser}
              >
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        id="firstname"
                        name="firstname"
                        variant="outlined"
                        required
                        fullWidth
                        label="First Name"
                        // autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        id="lastname"
                        name="lastname"
                        variant="outlined"
                        required
                        fullWidth
                        label="Last Name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        id="email"
                        name="email"
                        variant="outlined"
                        required
                        fullWidth
                        label="Email"
                        disabled={vendorEmail}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        id="password"
                        name="password"
                        variant="outlined"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        id="password2"
                        name="password2"
                        variant="outlined"
                        required
                        fullWidth
                        label="Confirm Password"
                        type="password"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={signUpLoader}
                  >
                    {signUpLoader ? "Loading..." : " Sign Up"}
                  </Button>
                </Form>
              </Formik>
              <Grid container>
                <Grid item xs>
                  <Link to="/login" className={classes.text}>
                    Already have account?{" "}
                    <span className={classes.link}>Sign In</span>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Container>
    </div>
  );
};

export default container(Signup);

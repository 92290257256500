import React from 'react';
import Pricing from 'src/pages/LandingPage/components/Pricing';
import container from "./Pricing.container";

const PricingPage = (props: any) => {
  const {
    authState: { user },
    history,
    onSubscribeUser,
    onUpdateSubscription
  } = props;

  return (
    <>
      <Pricing history={history} user={user} onSubscribeUser={onSubscribeUser} onUpdateSubscription={onUpdateSubscription} />
    </>
  );
};

export default container(PricingPage);

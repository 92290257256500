import * as AuthType from 'src/store/auth/auth.types';
import * as SubscriptionType from 'src/store/subscription/subscription.types';

const init_state = {
  isAuthenticated: null,
  signUpLoader: false,
  loading: false,
  subscriptionLoading: false,
  user: null,
  invitation: null,
  error: null
};

export const authReducer = (state = init_state, action: any) => {
  switch (action.type) {
    case AuthType.REGISTER_NEW_USER:
      return {
        ...state,
        signUpLoader: true
      };

    case AuthType.REGISTER_NEW_USER_FAIL:
      return {
        ...state,
        signUpLoader: false
      };

    case AuthType.SIGNUP_SUCCESS:
    case AuthType.SIGNUP_FAIL:
      return {
        ...state,
        signUpLoader: false
      };

    case AuthType.LOGIN_START:
      return {
        ...state,
        loading: true,
        user: null,
        error: null
      };

    case AuthType.LOAD_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true
      };

    case AuthType.LOAD_CURRENT_USER_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.payload
      };

    case AuthType.LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        loading: false,
        isAuthenticated: false,
        signUpLoader: false,
        subscriptionLoading: false
      };

    case AuthType.CHECK_SUB_VENDOR_A_USER:
      return {
        ...state,
        loading: true
      };

    case AuthType.LOAD_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        invitation: action.payload
      };

    case AuthType.FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload
      };

    case AuthType.FETCH_CURRENT_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SubscriptionType.SUBSCRIBE_USER_START:
      return {
        ...state,
        subscriptionLoading: true
      };

    case SubscriptionType.UPDATE_SUBSCRIPTION_START:
      return {
        ...state,
        subscriptionLoading: true
      };

    case SubscriptionType.SUBSCRIBE_USER_COMPLETE:
      return {
        ...state,
        subscriptionLoading: false
      };

    // case AuthType.FETCH_USER_SUBSCRIPTION_START:
    //   return {
    //     ...state,
    //     subscriptionLoading: true
    //   };

    // case AuthType.FETCH_USER_SUBSCRIPTION_SUCCESS:
    //   let updatedState: any = {
    //     ...state,
    //     subscriptionLoading: false
    //   };
    //   updatedState.user = { ...updatedState.user, ...action.payload.data };
    //   return updatedState;

    case AuthType.CANCEL_USER_SUBSCRIPTION_START:
      return {
        ...state,
        subscriptionLoading: true
      };

    case AuthType.CANCEL_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        user: action.payload.data
      };

    default:
      return state;
  }
};
